import React from 'react';

import { Header, Link, Panel, Table, Text } from 'styleguide';

const StyleguideFonts = () => (
  <Panel>
    <Panel.Heading>
      <Header size="h2">Fonts</Header>
    </Panel.Heading>
    <Panel.Body className="grid-wrapper">
      <div className="grid-span-3" />
      <Table className="grid-span-9">
        <thead>
          <tr>
            <th>Font</th>
            <th>Size</th>
            <th>Weight</th>
            <th>Letter Spacing</th>
            <th>Line Height</th>
          </tr>
        </thead>
      </Table>

      <Text className="flex grid-span-3">P1</Text>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Roboto</td>
            <td>14px</td>
            <td>400</td>
            <td>-</td>
            <td>20px</td>
          </tr>
        </tbody>
      </Table>

      <Text small className="flex grid-span-3">
        P2 (Small)
      </Text>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Roboto Condensed</td>
            <td>12px</td>
            <td>400</td>
            <td>-</td>
            <td>16px</td>
          </tr>
        </tbody>
      </Table>

      <Text large className="flex grid-span-3">
        P3 (Large)
      </Text>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Roboto</td>
            <td>16px</td>
            <td>400</td>
            <td>-</td>
            <td>24px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h1" className="flex grid-span-3">
        h1
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Work Sans</td>
            <td>24px</td>
            <td>600</td>
            <td>1.8px</td>
            <td>24px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h2" className="flex grid-span-3">
        h2
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Work Sans</td>
            <td>18px</td>
            <td>600</td>
            <td>1.4px</td>
            <td>26px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h3" className="flex grid-span-3">
        h3
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Roboto</td>
            <td>13px</td>
            <td>400</td>
            <td>-</td>
            <td>26px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h4" className="flex grid-span-3">
        h4
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Work Sans</td>
            <td>10px</td>
            <td>600</td>
            <td>1.3px</td>
            <td>20px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h5" className="flex grid-span-3">
        h5
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Roboto Condensed</td>
            <td>28px</td>
            <td>300</td>
            <td>3.6px</td>
            <td>20px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h6" className="flex grid-span-3">
        h6
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Work Sans</td>
            <td>12px</td>
            <td>600</td>
            <td>1.4px</td>
            <td>20px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h7" className="flex grid-span-3 h7">
        h7
      </Header>
      <Table className="grid-span-9">
        <tbody>
          <tr>
            <td>Work Sans</td>
            <td>14px</td>
            <td>600</td>
            <td>1.5px</td>
            <td>20px</td>
          </tr>
        </tbody>
      </Table>

      <Header size="h2" className="grid-span-12">
        Variants
      </Header>

      <div className="flex grid-span-3">
        <Link>P1 Inline Link</Link>
      </div>
      <pre className="grid-span-9">color: @btnBlue</pre>

      <Text bold className="flex grid-span-3">
        P1 Medium
      </Text>
      <pre className="grid-span-9">font-weight: 600</pre>

      <Text italic className="flex grid-span-3">
        P1 Italic
      </Text>
      <pre className="grid-span-9">font-style: italic</pre>

      <Text color="hint" className="flex grid-span-3">
        P1 Hint
      </Text>
      <pre className="grid-span-9">color: @gray</pre>

      <Text color="warning" className="flex grid-span-3">
        P1 Warning
      </Text>
      <pre className="grid-span-9">color: @gold</pre>

      <Text color="error" className="flex grid-span-3 error-red">
        P1 Error
      </Text>
      <pre className="grid-span-9">color: @red</pre>

      <Header size="h2" color="warning" className="flex grid-span-3">
        h2 warning
      </Header>
      <pre className="grid-span-9">color: @gold</pre>

      <Header size="h3" color="invalid" className="flex grid-span-3">
        h3 invalid
      </Header>
      <pre className="grid-span-9">color: @gray</pre>

      <Header size="h2" className="grid-span-12">
        Tags
      </Header>

      <div className="flex grid-span-3">
        <Text isTag color="error">
          P1 Error
        </Text>
      </div>
      <pre className="grid-span-9">
        color: @white
        <br />
        background-color: @red
        <br />
        padding: 2px 10px
      </pre>

      <div className="flex grid-span-3">
        <Header size="h2" color="error" isTag>
          h2 error
        </Header>
      </div>
      <pre className="grid-span-9">
        color: @white
        <br />
        background-color: @red
        <br />
        padding: 0 10px
      </pre>

      <div className="flex grid-span-3">
        <Header size="h4" isTag>
          h4 tag
        </Header>
      </div>
      <pre className="grid-span-9">
        color: @textBlack
        <br />
        background-color: @white
        <br />
        padding: 0 10px
      </pre>

      <div className="flex grid-span-3">
        <Header size="h4" color="error" isTag>
          h4 urgent
        </Header>
      </div>
      <pre className="grid-span-9">
        color: @white
        <br />
        background-color: @red
        <br />
        padding: 0 10px
      </pre>

      <Header size="h2" className="grid-span-12">
        Lists
      </Header>
    </Panel.Body>
  </Panel>
);

export default StyleguideFonts;
