// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styleguide table {
  margin-bottom: 0;
}
.styleguide .grid-wrapper pre {
  white-space: pre-wrap;
  margin: 0;
}
.styleguide .grid-wrapper p.flex,
.styleguide .grid-wrapper h1.flex,
.styleguide .grid-wrapper h2.flex,
.styleguide .grid-wrapper h3.flex,
.styleguide .grid-wrapper h4.flex,
.styleguide .grid-wrapper h5.flex,
.styleguide .grid-wrapper h6.flex {
  margin: 0;
}
.styleguide .grid-wrapper .flex > p,
.styleguide .grid-wrapper .flex > h1,
.styleguide .grid-wrapper .flex > h2,
.styleguide .grid-wrapper .flex > h3,
.styleguide .grid-wrapper .flex > h4,
.styleguide .grid-wrapper .flex > h5,
.styleguide .grid-wrapper .flex > h6 {
  margin: 0;
}
.styleguide .color {
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/pages/Styleguide/main.less"],"names":[],"mappings":"AAEA;EAEI,gBAAA;AAFJ;AAAA;EAOM,qBAAA;EACA,SAAA;AAJN;AAcM;;;;;;;EACE,SAAA;AANR;AAbA;;;;;;;EA+BQ,SAAA;AATR;AAtBA;EAqCI,YAAA;AAZJ","sourcesContent":["@import (reference) '~styleguide/src/styles/variables.less';\n\n.styleguide {\n  table {\n    margin-bottom: 0;\n  }\n\n  .grid-wrapper {\n    pre {\n      white-space: pre-wrap;\n      margin: 0;\n    }\n\n    p,\n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6 {\n      &.flex {\n        margin: 0;\n      }\n    }\n\n    .flex {\n      > p,\n      > h1,\n      > h2,\n      > h3,\n      > h4,\n      > h5,\n      > h6 {\n        margin: 0;\n      }\n    }\n  }\n\n  .color {\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
