import moment, { Moment } from 'moment';

import { ProgramExtensionStatusReason, RPMAdherenceLevel } from '../enums';
import { Measurement } from './Measurement';
import { make, makeArray } from './Model';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class MemberDashboardRPM {
  programExtensionStatus: ProgramExtensionStatus;

  programExtensionStatusReason?: ProgramExtensionStatusReason;

  programExtensionStatusReasonOther = '';

  currentCycleStart?: Moment;

  currentCycleEnd?: Moment;

  totalReadings = 0;

  opportunityLevel: RPMAdherenceLevel;

  remainingReadings = 0;

  lastReading?: Measurement;

  readingsFromLastDay?: Measurement[];

  devices = [] as string[];

  rpmMinutes = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.programExtensionStatus = make(
      obj.programExtensionStatus,
      ProgramExtensionStatus,
    );
    this.programExtensionStatusReason = obj.programExtensionStatusReason
      ? ProgramExtensionStatusReason.byKey[obj.programExtensionStatusReason]
      : undefined;
    this.currentCycleStart = obj.currentCycleStart
      ? moment.unix(obj.currentCycleStart)
      : undefined;
    this.currentCycleEnd = obj.currentCycleEnd
      ? moment.unix(obj.currentCycleEnd)
      : undefined;
    this.opportunityLevel = RPMAdherenceLevel.byKey[obj.opportunityLevel];
    this.lastReading = make(obj.lastReading, Measurement);
    this.readingsFromLastDay = makeArray(obj.readingsFromLastDay, Measurement);
  }
}

export default MemberDashboardRPM;
