import moment, { Moment } from 'moment';

import { Employee } from './Employee';
import { EventInterventionType } from './EventInterventionType';
import { make } from './Model';

export class EventIntervention {
  id = 0;

  issueId = 0;

  type: EventInterventionType;

  createdAt: Moment;

  createdBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdAt = moment.unix(obj.createdAt);
    this.type = make(obj.type, EventInterventionType);
    this.createdAt = moment.unix(obj.createdAt);
    this.createdBy = make(obj.createdBy, Employee);
  }
}

export default EventIntervention;
