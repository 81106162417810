// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#health-reports-section .date-filter-container {
  margin: 20px 0px 30px 0px;
}
#health-reports-section .date-filter-container .date-filter-text {
  margin: 0px 20px;
}
#health-reports-section .no-spacing {
  margin: 0px;
  padding: 0px;
}
#health-reports-section .report-details {
  padding-top: 0px;
}
#health-reports-section .add-report-loading {
  margin: 10px 0px 30px 0px;
}
#health-reports-section .add-report-container {
  margin-bottom: 30px;
}
#health-reports-section .add-report-container .add-report-body {
  margin: 20px;
}
#health-reports-section .add-report-container .add-report-form {
  padding: 30px;
}
#health-reports-section .add-report-container .add-report-form .form-group.field.field-object {
  max-width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MemberProfile/HealthReports/main.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AAFA;EAKM,gBAAA;AAAN;AALA;EAUI,WAAA;EACA,YAAA;AAFJ;AATA;EAeI,gBAAA;AAHJ;AAZA;EAmBI,yBAAA;AAJJ;AAfA;EAuBI,mBAAA;AALJ;AAlBA;EA0BM,YAAA;AALN;AArBA;EA8BM,aAAA;AANN;AAxBA;EAiCQ,cAAA;AANR","sourcesContent":["#health-reports-section {\n  .date-filter-container {\n    margin: 20px 0px 30px 0px;\n\n    .date-filter-text {\n      margin: 0px 20px;\n    }\n  }\n\n  .no-spacing {\n    margin: 0px;\n    padding: 0px;\n  }\n\n  .report-details {\n    padding-top: 0px;\n  }\n\n  .add-report-loading {\n    margin: 10px 0px 30px 0px;\n  }\n\n  .add-report-container {\n    margin-bottom: 30px;\n\n    .add-report-body {\n      margin: 20px;\n    }\n\n    .add-report-form {\n      padding: 30px;\n\n      .form-group.field.field-object {\n        max-width: 50%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
