import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';

import {
  ClinicalFigure,
  Panel,
  Slider,
  Spinner,
  Tooltip,
  Warning,
} from 'styleguide';

const StyleguideMisc = () => {
  const [figureSelections, setFigureSelections] = useState([]);
  const [slider, setSlider] = useState<number>();
  const tabs = ['One', 'Two', 'Three', 'Four', 'Five'];
  const { showStyleguide } = useFlags();

  // FIXME: StyleGuide page does some kind of layout change after load.
  // Force resize to allow subcomponents (like slider) to do their
  // calculations according to the updated layout
  setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);

  return (
    <Panel>
      <Panel.Heading>
        <h2>Misc</h2>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <div className="h7 grid-span-12">Loading Spinner</div>

        <div className="grid-span-12">
          <Spinner width={50} />
        </div>

        <div className="h7 grid-span-12">Tooltips</div>

        <div className="grid-span-2">
          <Tooltip label="I'm a tooltip">
            <span>Hover me!</span>
          </Tooltip>
        </div>
        <div className="grid-span-2">
          <Tooltip position="left" label="I'm a tooltip">
            <span>Left Tooltip</span>
          </Tooltip>
        </div>
        <div className="grid-span-2">
          <Tooltip position="right" label="I'm a tooltip">
            <span>Right Tooltip</span>
          </Tooltip>
        </div>
        <div className="grid-span-2">
          <Tooltip arrow label="I'm a tooltip with an arrow">
            <span>Arrow Tooltip</span>
          </Tooltip>
        </div>
        <div className="grid-span-2">
          <Tooltip arrow position="right" label="I'm a tooltip with an arrow">
            <span>Arrow Right Tooltip</span>
          </Tooltip>
        </div>

        <div className="grid-span-2">
          <Tooltip arrow position="top" label="I'm a tooltip with an arrow">
            <span>Arrow Top Tooltip</span>
          </Tooltip>
        </div>

        <div className="h7 grid-span-12">Warnings</div>

        <div className="grid-span-12">
          <Warning title="Warning Box" type="box">
            Lorem ipsum dolor sit amet quiso que eros
          </Warning>
        </div>

        <div className="grid-span-12">
          <Warning title="Warning Banner" type="banner">
            Lorem ipsum dolor sit amet
          </Warning>
        </div>

        <div className="h7 grid-span-12">Slider</div>

        <div className="grid-span-12">
          <Slider
            id="slider"
            label="Slider"
            min={0}
            max={10}
            onChange={setSlider}
            value={slider}
          />
        </div>

        <div className="h7 grid-span-12">Feature Flags</div>
        <p className="grid-span-12">
          Feature flags allow us to control the visibility of a configured
          feature.
        </p>
        <p className="grid-span-12">
          Enable "SHOW_STYLEGUIDE" to display additional text.
        </p>
        {showStyleguide && (
          <p className="italic grid-span-12">This text is feature flagged.</p>
        )}

        <div className="h7 grid-span-12">Clinical Figure</div>
        <div className="grid-span-12">
          <ClinicalFigure
            onChange={setFigureSelections}
            initialSelection={figureSelections}
            multiple
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideMisc;
