import { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';

import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Employee,
  EmployeeGroup,
  Incident,
  IncidentStatus,
  IncidentType,
  Organization,
  PaginationType,
} from '@vestahealthcare/common/models';
import { LS_ADMIN_INCIDENTS } from '@vestahealthcare/common/utils/constants';

import {
  DateTimePicker,
  IconButton,
  Panel,
  Select,
  Tabs,
  TextInput,
} from 'styleguide-v2';
import {
  FilterGroup,
  FilterItem,
  FilterSelectable,
} from 'styleguide-v2/src/components/Panel';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  GetIncidentParams,
  downloadIncidentsDashboard,
  fetchIncidents,
  updateIncident,
} from 'dash/src/services/IncidentServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import {
  getBaseEnumFromFilter,
  getFilterCount,
  getFilterItem,
  getFilterValue,
  getSafeValues,
  getServerFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';

import { IncidentsTable } from './IncidentsTable';

interface SortOption extends Selectable {
  sort: string;
}

const SORT_OPTIONS: SortOption[] = [
  {
    label: translate('incidents.sort.newest'),
    value: 'created-newest',
    sort: 'reportedAt desc',
  },
  {
    label: translate('incidents.sort.dashId'),
    value: 'member-id-asc',
    sort: 'memberId asc',
  },
];

const DEFAULT_PAGE_SIZE = 50;

const INCIDENTS_PAGE_TAB_OPEN = 'tab-open';
const INCIDENTS_PAGE_TAB_ALL = 'tab-all';

const INCIDENT_TABS = [
  {
    label: translate('incidents.tabs.all'),
    value: INCIDENTS_PAGE_TAB_ALL,
  },
  {
    label: translate('incidents.tabs.open'),
    value: INCIDENTS_PAGE_TAB_OPEN,
  },
];

type IncidentTabCount = {
  all: number;
  open: number;
};

interface SelectableOrganization extends Selectable {
  item?: Organization;
}

type KeyGetIncidentParams = keyof GetIncidentParams;

const INCIDENT_FILTERS: KeyGetIncidentParams[] = [
  'assigneeId',
  'from',
  'status',
  'to',
  'type',
];
const MEMBER_FILTERS: KeyGetIncidentParams[] = [
  'brandId',
  'communityResourceId',
  'excludedReferralSourceId',
  'memberId',
  'memberName',
  'referralSourceId',
];

const useStyles = makeStyles({
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sort: {
    minWidth: '22.5rem',
  },
});

export const IncidentsDashboard = () => {
  const history = useHistory();
  const styles = useStyles();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetIncidentParams]?: FilterItem;
    }
  >();
  const [pendingFilters, setPendingFilters] = useState<
    {
      [x in KeyGetIncidentParams]?: FilterItem;
    }
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [sort, setSort] = useState<SortOption>(SORT_OPTIONS[0]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pagination, setPagination] = useState<PaginationType>();
  const [tab, setTab] = useState<string>(INCIDENTS_PAGE_TAB_OPEN);
  const [tabCount, setTabCount] = useState<IncidentTabCount>();

  const [referralSources, setReferralSources] = useState<Selectable[]>([]);
  const [excludedReferralSources, setExcludedReferralSources] = useState<
    Selectable[]
  >([]);
  const [incidentTypes, setIncidentTypes] = useState<IncidentType[]>([]);
  const [incidentStatuses, setIncidentStatuses] = useState<IncidentStatus[]>(
    [],
  );
  const [assignees, setAssignees] = useState<SelectableInfo<Employee>[]>([]);
  const [assigneeGroups, setAssigneeGroups] = useState<
    SelectableInfo<EmployeeGroup>[]
  >([]);
  const [NPs, setNPs] = useState<Selectable[]>([]);
  const [communityResources, setCommunityResources] = useState<Selectable[]>(
    [],
  );
  const [brands, setBrands] = useState<Selectable[]>([]);

  const [name, setName] = useState<string>();
  const debouncedName = useDebounce(name, 300);

  const [incidents, setIncidents] = useState<Incident[]>([]);

  const INCIDENT_OPEN_STATUSES = useMemo(
    () =>
      incidentStatuses
        ?.filter(({ isClosed }) => !isClosed)
        ?.map(({ id }) => id),
    [incidentStatuses],
  );

  const getInitialData = async () => {
    setLoadingData(true);
    const [o, it, is, e, g, crs, b] = await Promise.all([
      CacheServices.getOrganizations(),
      CacheServices.getIncidentTypes(),
      CacheServices.getIncidentStatuses(),
      CacheServices.getEmployees(),
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getHomeHealthCRs(),
      CacheServices.getOrganizationBrands(),
    ]);
    const referralsOptions = o.map((item) => ({
      label: item.name,
      value: item.id,
      item,
    }));
    setReferralSources(referralsOptions);
    setExcludedReferralSources(referralsOptions);
    setIncidentTypes(it);
    setIncidentStatuses(is);
    setAssignees(
      Employee.toSelectable(
        e.filter(({ isInIncidentsManagement }) => isInIncidentsManagement),
      ),
    );
    setAssigneeGroups(
      EmployeeGroup.toSelectable(
        g.filter(({ id }) => id === EmployeeGroup.CLINICAL_ACCOUNTS),
      ),
    );
    setNPs(
      e
        .filter(({ isNP }) => isNP)
        .map(({ id, fullName }) => ({ value: id, label: fullName })),
    );
    setCommunityResources(
      crs.items.map(({ id, name }) => ({ value: id, label: name })),
    );
    setBrands(b.map(({ id, name }) => ({ value: id, label: name })));
    setLoadingData(false);
  };

  const prepareNewFilters = (
    filters: {
      [x in KeyGetIncidentParams]?: FilterItem;
    },
  ): GetIncidentParams => {
    const { assigneeId, ...restFilters } = filters;
    const parsedFilters: {
      [x in KeyGetIncidentParams]?: FilterItem;
    } = restFilters;
    if (assigneeId) {
      const assigneeIds = (assigneeId?.value as FilterSelectable[])?.filter(
        ({ info }) => info instanceof Employee,
      );
      const assigneeGroupIds = (assigneeId?.value as FilterSelectable[])?.filter(
        ({ info }) => info instanceof EmployeeGroup,
      );
      if (assigneeIds?.length) {
        parsedFilters.assigneeId = {
          ...assigneeId,
          value: assigneeIds,
        };
      }
      if (assigneeGroupIds?.length) {
        parsedFilters.assigneeGroupId = {
          ...assigneeId,
          value: assigneeGroupIds,
        };
      }
    }
    const currentFilters = getServerFilters(parsedFilters) as GetIncidentParams;
    return currentFilters;
  };

  const getIncidents = async () => {
    if (!newFilters) return;

    setLoading(true);
    try {
      if (!newFilters) return;
      const currentFilters: GetIncidentParams = prepareNewFilters(newFilters);
      if (
        tab === INCIDENTS_PAGE_TAB_OPEN &&
        !(newFilters.status?.value as FilterSelectable[])?.length
      ) {
        currentFilters.status = INCIDENT_OPEN_STATUSES;
      } else {
        currentFilters.status = currentFilters?.status;
      }
      const { items, pagination } = await fetchIncidents({
        ...currentFilters,
        limit: pageSize,
        offset: page * pageSize,
        sort: sort.sort,
      });

      if (page === 0) {
        setIncidents(items);
      } else {
        setIncidents([...incidents, ...items]);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getTabsCount = async () => {
    let openStatuses: string[] | undefined;

    if (!newFilters) return;
    const currentFilters: GetIncidentParams = prepareNewFilters(newFilters);
    if (!currentFilters.status?.length) {
      openStatuses = INCIDENT_OPEN_STATUSES;
    }

    const [
      {
        pagination: { total: all },
      },
      {
        pagination: { total: open },
      },
    ] = await Promise.all([
      fetchIncidents({ ...currentFilters, count: true }),
      fetchIncidents({
        ...currentFilters,
        status: openStatuses || currentFilters.status,
        count: true,
      }),
    ]);
    setTabCount({
      all,
      open,
    });
  };

  const getTabs = () => {
    if (tabCount) {
      return [
        {
          value: INCIDENTS_PAGE_TAB_ALL,
          label: `${INCIDENT_TABS[0].label} ( ${tabCount.all} )`,
        },
        {
          value: INCIDENTS_PAGE_TAB_OPEN,
          label: `${INCIDENT_TABS[1].label} ( ${tabCount.open} )`,
        },
      ];
    }
    return INCIDENT_TABS;
  };

  const download = async () => {
    if (!newFilters) return;

    try {
      setLoadingDownload(true);
      const currentFilters: GetIncidentParams = prepareNewFilters(newFilters);
      let { status } = currentFilters;
      if (tab === INCIDENTS_PAGE_TAB_OPEN && !currentFilters.status?.length) {
        status = INCIDENT_OPEN_STATUSES;
      }
      await downloadIncidentsDashboard({ ...currentFilters, status });
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingDownload(false);
  };

  useEffect(() => {
    if (newFilters) {
      setPendingFilters(newFilters);
    }
  }, [newFilters, open]);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (newFilters) {
      getTabsCount();
    }
  }, [newFilters]);

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(LS_ADMIN_INCIDENTS, newFilters);
    }
  }, [newFilters]);

  useEffect(() => {
    if (
      assignees?.length &&
      assigneeGroups?.length &&
      brands?.length &&
      communityResources?.length &&
      excludedReferralSources?.length &&
      incidentTypes?.length &&
      incidentStatuses?.length
    ) {
      const storedFilters = loadNewFiltersFromStorage(LS_ADMIN_INCIDENTS, {
        assigneeId: {
          data: [...assigneeGroups, ...assignees],
          label: translate('incidents.filters.assignee'),
          multiple: true,
        },
        brandId: {
          data: brands,
          label: translate('incidents.filters.brand'),
          multiple: true,
        },
        communityResourceId: {
          data: communityResources,
          label: translate('incidents.filters.homeHealth'),
          multiple: true,
        },
        excludedReferralSourceId: {
          data: excludedReferralSources,
          label: translate('incidents.filters.excludedReferralSources'),
          multiple: true,
        },
        from: { label: translate('incidents.filters.dateFrom') },
        memberId: { label: translate('incidents.filters.memberId') },
        memberName: { label: translate('incidents.filters.memberName') },
        npOwnerId: {
          data: NPs,
          label: translate('incidents.filters.npOwner'),
          multiple: true,
        },
        referralSourceId: {
          data: referralSources,
          label: translate('incidents.filters.referralSources'),
          multiple: true,
        },
        status: {
          data: incidentStatuses,
          label: translate('incidents.filters.status'),
          multiple: true,
        },
        to: { label: translate('incidents.filters.dateTo') },
        type: {
          data: incidentTypes,
          label: translate('incidents.filters.type'),
          multiple: true,
        },
      } as { [x in KeyGetIncidentParams]: any });
      setNewFilters(storedFilters);
    }
  }, [
    assignees,
    assigneeGroups,
    brands,
    communityResources,
    excludedReferralSources,
    incidentStatuses,
    incidentTypes,
  ]);

  useEffect(() => {
    getIncidents();
  }, [newFilters, page, pageSize, tab, sort]);

  useEffect(() => {
    if (
      debouncedName !==
      (newFilters?.memberName?.value as FilterSelectable)?.value
    ) {
      setPage(0);
      setNewFilters({
        ...newFilters,
        memberName: getFilterItem(
          translate('incidents.filters.memberName'),
          debouncedName,
        ),
      });
    }
  }, [debouncedName]);

  useEffect(() => {
    if ((pendingFilters?.status?.value as FilterSelectable[])?.length) {
      setPendingFilters({
        ...pendingFilters,
        status: {
          label: pendingFilters?.status?.label || '',
          hidden: pendingFilters?.status?.hidden || false,
          value: (pendingFilters?.status
            ?.value as FilterSelectable[]).filter(({ value }) =>
            INCIDENT_OPEN_STATUSES.includes(value as string),
          ),
        },
      });
    }
  }, [tab]);

  return (
    <Panel>
      <Panel.Heading title={translate('incidents.title')} filtersV2>
        <Panel.Actions>
          <IconButton
            data-cy="download-action"
            loading={loadingDownload}
            onClick={download}
            tooltip={translate('global.download')}
          >
            <DownloadIcon />
          </IconButton>
        </Panel.Actions>
        <Panel.FilterBar
          onClearFilters={() => {
            setPage(0);
            setNewFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setPage(0);
            if ((key as KeyGetIncidentParams) === 'memberName') {
              setName(undefined);
            }
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpen(!isOpen)}
          chips={newFilters}
          inputs={
            <>
              <TextInput
                data-cy="incidents-filter-bar-name"
                placeholder={translate('incidents.filters.memberName')}
                onChange={setName}
                size="xs"
                value={name}
              />
              <Select
                allowAddNewValue
                data-cy="incidents-filter-bar-id"
                items={[]}
                inputType="number"
                multiple
                noOptionsText={translate('members.typeToSearch')}
                onChange={(values: Selectable[]) =>
                  setNewFilters({
                    ...newFilters,
                    memberId: getFilterItem(
                      translate('incidents.filters.memberId'),
                      values.map(({ label }) => ({
                        value: label,
                        label,
                      })),
                    ),
                  })
                }
                placeholder={translate('incidents.filters.memberId')}
                size="xs"
                value={newFilters?.memberId?.value}
              />
              <Select
                data-cy="incidents-filter-bar-status"
                getItemLabel={({ description }: IncidentStatus) => description}
                getItemDisabled={({ id }: IncidentStatus) =>
                  tab === INCIDENTS_PAGE_TAB_OPEN &&
                  !INCIDENT_OPEN_STATUSES.includes(id)
                }
                items={incidentStatuses}
                limitTags={1}
                loading={loadingData}
                multiple
                onChange={(status: BaseEnum[]) => {
                  setPage(0);
                  setNewFilters({
                    ...newFilters,
                    status: getFilterItem(
                      translate('incidents.filters.status'),
                      status,
                    ),
                  });
                }}
                placeholder={translate('incidents.filters.status')}
                placeholderV2
                value={getBaseEnumFromFilter(newFilters?.status?.value)}
                size="xs"
              />
              <Select
                data-cy="incidents-filter-bar-assignee"
                grouped={(item: SelectableInfo<EmployeeGroup | Employee>) => {
                  if (item.info instanceof EmployeeGroup) {
                    return translate('global.groups');
                  }
                  if (item.info instanceof Employee) {
                    return translate('global.employees');
                  }
                  return '';
                }}
                items={[...assigneeGroups, ...assignees]}
                isOptionEqualToValue={(
                  a: SelectableInfo<EmployeeGroup | Employee>,
                  b: SelectableInfo<EmployeeGroup | Employee>,
                ) => {
                  if (a.info instanceof Employee && b.info instanceof Employee)
                    return a.value === b.value;
                  if (
                    a.info instanceof EmployeeGroup &&
                    b.info instanceof EmployeeGroup
                  )
                    return a.value === b.value;
                  return false;
                }}
                limitTags={1}
                loading={loadingData}
                multiple
                onChange={(assignees: Selectable[]) =>
                  setNewFilters({
                    ...newFilters,
                    assigneeId: getFilterItem(
                      translate('incidents.filters.assignee'),
                      assignees,
                    ),
                  })
                }
                placeholder={translate('incidents.filters.assignee')}
                placeholderV2
                value={newFilters?.assigneeId?.value}
                size="xs"
              />
              <Select
                data-cy="incidents-filter-bar-np-owner"
                items={NPs}
                loading={loadingData}
                multiple
                onChange={(values: Selectable[]) => {
                  setNewFilters({
                    ...newFilters,
                    npOwnerId: getFilterItem(
                      translate('incidents.filters.npOwner'),
                      values,
                    ),
                  });
                }}
                placeholder={translate('incidents.filters.npOwner')}
                placeholderV2
                value={newFilters?.npOwnerId?.value}
                size="xs"
              />
              <Select
                data-cy="incidents-filter-bar-brand"
                items={brands}
                limitTags={1}
                loading={loadingData}
                multiple
                onChange={(values: Selectable[]) => {
                  setNewFilters({
                    ...newFilters,
                    brandId: getFilterItem(
                      translate('incidents.filters.brand'),
                      values,
                    ),
                  });
                }}
                placeholder={translate('incidents.filters.brand')}
                placeholderV2
                value={newFilters?.brandId?.value}
                size="xs"
              />
            </>
          }
        />
        <Panel.Tabs className={styles.tabsContainer}>
          <Tabs items={getTabs()} onChange={setTab} value={tab} />
          <Select
            className={styles.sort}
            disableClearable
            flow="horizontal"
            items={SORT_OPTIONS}
            label={translate('incidents.sort.sortBy')}
            onChange={(sort: SortOption) => {
              setPage(0);
              setSort(sort);
            }}
            size="xs"
            value={sort}
          />
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <Panel.FilterModal
          open={isOpen}
          onClear={() => {
            setPage(0);
            setName(undefined);
            setNewFilters({});
          }}
          onClose={() => setOpen(false)}
          onSubmit={() => {
            if (pendingFilters) {
              setPage(0);
              setNewFilters(pendingFilters);
            }
          }}
        >
          <FilterGroup
            title="Member Details"
            count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
          >
            <TextInput
              data-cy="incidents-filters-name"
              label={translate('incidents.filters.memberName')}
              onChange={(name?: string) =>
                setPendingFilters({
                  ...pendingFilters,
                  memberName: getFilterItem(
                    translate('incidents.filters.memberName'),
                    name,
                  ),
                })
              }
              size="small"
              value={getFilterValue(pendingFilters.memberName?.value) as string}
            />
            <Select
              allowAddNewValue
              data-cy="incidents-filter-id"
              items={[]}
              inputType="number"
              multiple
              noOptionsText={translate('members.typeToSearch')}
              onChange={(values: Selectable[]) =>
                setPendingFilters({
                  ...pendingFilters,
                  memberId: getFilterItem(
                    translate('incidents.filters.memberId'),
                    values.map(({ label }) => ({
                      value: label,
                      label,
                    })),
                  ),
                })
              }
              label={translate('incidents.filters.memberId')}
              size="small"
              value={pendingFilters.memberId?.value}
            />
            <Select
              data-cy="incidents-filter-np-owner"
              items={NPs}
              label={translate('incidents.filters.npOwner')}
              loading={loadingData}
              multiple
              onChange={(values: Selectable[]) => {
                setPendingFilters({
                  ...pendingFilters,
                  npOwnerId: getFilterItem(
                    translate('incidents.filters.npOwner'),
                    values,
                  ),
                });
              }}
              value={pendingFilters.npOwnerId?.value}
              size="small"
            />
            <Select
              data-cy="incidents-filter-referral-sources"
              getItemDisabled={(child) =>
                !!(getSafeValues(
                  referralSources,
                  getFilterValue(
                    pendingFilters?.referralSourceId?.value,
                  ) as number[],
                ) as SelectableOrganization[])?.reduce(
                  (acc, item) => acc || !!item.item?.hasDescendant(child.value),
                  false,
                )
              }
              items={referralSources}
              label={translate('incidents.filters.referralSources')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(values: Selectable[]) => {
                setPendingFilters({
                  ...pendingFilters,
                  referralSourceId: getFilterItem(
                    translate('incidents.filters.referralSources'),
                    values,
                  ),
                });
              }}
              size="small"
              value={pendingFilters.referralSourceId?.value || []}
            />
            <Select
              data-cy="incidents-filter-excluded-referral-sources"
              getItemDisabled={(child) =>
                !!(getSafeValues(
                  excludedReferralSources,
                  getFilterValue(
                    pendingFilters?.excludedReferralSourceId?.value,
                  ) as number[],
                ) as SelectableOrganization[])?.reduce(
                  (acc, item) => acc || !!item.item?.hasDescendant(child.value),
                  false,
                )
              }
              items={excludedReferralSources}
              label={translate('incidents.filters.excludedReferralSources')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(values: Selectable[]) => {
                setPendingFilters({
                  ...pendingFilters,
                  excludedReferralSourceId: getFilterItem(
                    translate('incidents.filters.excludedReferralSources'),
                    values,
                  ),
                });
              }}
              size="small"
              value={pendingFilters.excludedReferralSourceId?.value || []}
            />

            <Select
              data-cy="incidents-filter-community-resource"
              items={communityResources}
              label={translate('incidents.filters.homeHealth')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(values: Selectable[]) => {
                setPendingFilters({
                  ...pendingFilters,
                  communityResourceId: getFilterItem(
                    translate('incidents.filters.homeHealth'),
                    values,
                  ),
                });
              }}
              value={pendingFilters.communityResourceId?.value}
              size="small"
            />

            <Select
              data-cy="incidents-filter-brand"
              items={brands}
              label={translate('incidents.filters.brand')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(values: Selectable[]) => {
                setPendingFilters({
                  ...pendingFilters,
                  brandId: getFilterItem(
                    translate('incidents.filters.brand'),
                    values,
                  ),
                });
              }}
              value={pendingFilters.brandId?.value}
              size="small"
            />
          </FilterGroup>

          <FilterGroup
            title="Incident Details"
            count={getFilterCount(pendingFilters, INCIDENT_FILTERS)}
          >
            <Select
              className="grid-span-3"
              data-cy="incidents-filter-type"
              getItemLabel={({ description }: IncidentType) => description}
              items={incidentTypes}
              label={translate('incidents.filters.type')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(type: IncidentType[]) =>
                setPendingFilters({
                  ...pendingFilters,
                  type: getFilterItem(
                    translate('incidents.filters.type'),
                    type,
                  ),
                })
              }
              value={getBaseEnumFromFilter(pendingFilters.type?.value)}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="incidents-filter-status"
              getItemLabel={({ description }: IncidentStatus) => description}
              getItemDisabled={({ id }: IncidentStatus) =>
                tab === INCIDENTS_PAGE_TAB_OPEN &&
                !INCIDENT_OPEN_STATUSES.includes(id)
              }
              items={incidentStatuses}
              label={translate('incidents.filters.status')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(status: BaseEnum[]) =>
                setPendingFilters({
                  ...pendingFilters,
                  status: getFilterItem(
                    translate('incidents.filters.status'),
                    status,
                  ),
                })
              }
              value={getBaseEnumFromFilter(pendingFilters.status?.value)}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="incidents-filter-assignee"
              grouped={(item: SelectableInfo<EmployeeGroup | Employee>) => {
                if (item.info instanceof EmployeeGroup) {
                  return translate('global.groups');
                }
                if (item.info instanceof Employee) {
                  return translate('global.employees');
                }
                return '';
              }}
              items={[...assigneeGroups, ...assignees]}
              isOptionEqualToValue={(
                a: SelectableInfo<EmployeeGroup | Employee>,
                b: SelectableInfo<EmployeeGroup | Employee>,
              ) => {
                if (a.info instanceof Employee && b.info instanceof Employee)
                  return a.value === b.value;
                if (
                  a.info instanceof EmployeeGroup &&
                  b.info instanceof EmployeeGroup
                )
                  return a.value === b.value;
                return false;
              }}
              label={translate('incidents.filters.assignee')}
              limitTags={1}
              loading={loadingData}
              multiple
              onChange={(assignees: Selectable[]) =>
                setPendingFilters({
                  ...pendingFilters,
                  assigneeId: getFilterItem(
                    translate('incidents.filters.assignee'),
                    assignees,
                  ),
                })
              }
              value={pendingFilters.assigneeId?.value}
              size="small"
            />

            <div className="grid-span-3 flex gap">
              <DateTimePicker
                data-cy="incidents-filter-date-from"
                dateFormat="M/D/YY"
                label={translate('incidents.filters.dateFrom')}
                maxDate={
                  (getFilterValue(
                    pendingFilters.to?.value,
                  ) as Moment)?.toDate() || Date.now()
                }
                onChange={(date?: Date | null) => {
                  setPendingFilters({
                    ...pendingFilters,
                    from: getFilterItem(
                      translate('incidents.filters.dateFrom'),
                      date,
                    ),
                  });
                }}
                size="small"
                value={(getFilterValue(
                  pendingFilters.from?.value,
                ) as Moment)?.toDate()}
              />
              <DateTimePicker
                data-cy="incidents-filter-date-from"
                dateFormat="M/D/YY"
                label={translate('incidents.filters.dateTo')}
                minDate={(getFilterValue(
                  pendingFilters.from?.value,
                ) as Moment)?.toDate()}
                maxDate={new Date()}
                onChange={(date?: Date | null) => {
                  setPendingFilters({
                    ...pendingFilters,
                    to: getFilterItem(
                      translate('incidents.filters.dateTo'),
                      date,
                    ),
                  });
                }}
                size="small"
                value={(getFilterValue(
                  pendingFilters.to?.value,
                ) as Moment)?.toDate()}
              />
            </div>
          </FilterGroup>
        </Panel.FilterModal>
        <IncidentsTable
          incidents={incidents}
          onChangePage={setPage}
          onChangeRowsPerPage={(size) => {
            setPage(0);
            setPageSize(size);
          }}
          onClickRow={({ id }) => history.push(`/admin/incidents/${id}`)}
          onEditAssignee={async (id, assignee) => {
            try {
              setLoading(true);
              const incident = await updateIncident(id, {
                assigneeId: assignee instanceof Employee ? assignee : null,
                assigneeGroupId:
                  assignee instanceof EmployeeGroup ? assignee : null,
              });
              const index = incidents.findIndex(({ id }) => id === incident.id);
              if (index !== -1) {
                incidents[index] = incident;
                setIncidents([...incidents]);
              }
            } catch (e) {
              showGlobalError(e as string);
            } finally {
              setLoading(false);
            }
          }}
          pagination={pagination}
        />
      </Panel.Body>
    </Panel>
  );
};

export default IncidentsDashboard;
