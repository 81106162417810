import moment, { Moment } from 'moment';

import { AppointmentStatus } from '../enums';
import { DATE_FORMAT } from '../utils/constants';

export class MemberDashboardAppointment {
  date?: Moment;

  type = '';

  employee = '';

  status?: AppointmentStatus;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.date = obj.date ? moment(obj.date, DATE_FORMAT) : undefined;
    this.status =
      (obj.status && AppointmentStatus.byKey[obj.status]) || undefined;
  }
}

export default MemberDashboardAppointment;
