import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberDashboardPharmacy } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import { Card, Data, Fonts } from 'styleguide-v2';

type Props = {
  className?: string;
  data?: MemberDashboardPharmacy;
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  dataContainer: {
    display: 'flex',
    flewWrap: 'wrap',
    gap: '0.5rem',

    '& > *': {
      flex: `1 1 calc(100% - 1rem)`,
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  margin: {
    marginBottom: '0.5rem',
  },
});

export const PharmacyCard = ({ className, data }: Props) => {
  const styles = useStyles();

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.pharmacyVisitSummary')}
          </span>
        </div>
        <br />
        {data?.lastAppointmentDate ? (
          <>
            <div className={styles.dataContainer}>
              <Data
                label={translate('memberProfile.dashboard.lastAppointment')}
                value={
                  data.lastAppointmentDate?.format(DATE_FORMAT_SHORT) || 'N/A'
                }
                subvalue={
                  data.lastAppointmentEmployee
                    ? `With ${data.lastAppointmentEmployee}`
                    : ''
                }
              />
              <Data
                label={translate(
                  'memberProfile.dashboard.understandingOfMedication',
                )}
                value={data.understandingOfMedications?.toString() || EMPTY}
                subvalue={
                  data.understandingOfMedications
                    ? translate(
                        `enums.memberDashboardPharmacyUnderstanding.sublabel.${data.understandingOfMedications.value}`,
                      )
                    : ''
                }
              />
            </div>
            <br />
            <Data
              label={translate(
                'memberProfile.dashboard.pharmacistRecommendations',
              )}
              value={data.recommendations?.toString() || EMPTY}
            />

            <br />
            <Data
              className="expanded"
              label={translate('memberProfile.dashboard.barriersToAdherence')}
            />
            {!data.access && !data.additional && !data.insurance && (
              <span className="italic size-s grey">
                {translate('memberProfile.dashboard.barriersNoBarriers')}
              </span>
            )}
            {data.access && (
              <Data
                className={styles.margin}
                label=""
                value={translate('memberProfile.dashboard.access')}
                subvalue={data.access}
              />
            )}
            {data.additional && (
              <Data
                className={styles.margin}
                label=""
                value={translate('memberProfile.dashboard.additionalBarriers')}
                subvalue={data.additional}
              />
            )}
            {data.insurance && (
              <Data
                className={styles.margin}
                label=""
                value={translate('memberProfile.dashboard.insuranceCoverage')}
                subvalue={data.insurance}
              />
            )}
          </>
        ) : (
          <span className="gray italic">
            {translate('memberProfile.dashboard.pharmacyVisitEmpty')}
          </span>
        )}
      </div>
    </Card>
  );
};
