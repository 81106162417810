import classNames from 'classnames';
import React, { Fragment, ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { MemberCarePlan } from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT } from '@vestahealthcare/common/utils/constants';

import { BackgroundColors, Card, Colors, Fonts } from 'styleguide-v2';

import { MemberCarePlanResponse } from 'dash/src/services/ClinicalSummarySevices';

type Props = {
  className?: string;
  careplanResponse: MemberCarePlanResponse;
};

const useStyles = makeStyles({
  cardContent: {
    padding: '0 3.2rem 1rem',
  },
  container: {
    display: 'flex',
    flex: '1 1',
    flexFlow: 'column',
    gap: '1rem',

    '&& > div': {
      borderRadius: '8px',
      display: 'block',
      maxHeight: 'fit-content',
    },
  },
  dataContainer: {
    '&&': {
      borderLeft: '1px solid #eee',
      paddingLeft: '1rem',
    },
  },
  interventionsList: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    marginBottom: '1rem',

    '&& > li:before': {
      content: '1',
      position: 'absolute',
      left: 0,
    },
  },
  recommendation: {},
  sectionTitle: {
    alignSelf: 'baseline',
    color: Colors.iconGreen,
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontWeight: 500,
      margin: 0,
    },
  },
  sectionSubTitle: {
    alignSelf: 'baseline',
    color: Colors.iconGray,

    '&&': {
      fontFamily: Fonts.fontFamily,
      margin: 0,
    },
  },
  sectionSubSubTitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
    padding: '2.4rem 3.2rem',
  },
  subtitleText: {
    color: Colors.textGray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontStyle: 'italic',
  },
  tabs: {
    padding: '0 3.2rem',
    borderBottom: `1px solid ${BackgroundColors.grayLine}`,
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
  titleContainer: {
    '&&': {
      paddingBottom: '1rem',
    },
  },
});

export const CarePlanCard = ({ className, careplanResponse }: Props) => {
  const styles = useStyles();

  const {
    careplans,
    updatedAt,
    updatedBy,
    providerRecommendations,
    rnRecommendations,
  } = careplanResponse || {};

  const isRecommendationEmpty = (r?: string) => !r;

  const renderCard = (
    title: string,
    children: ReactNode,
    subtitle?: string,
  ) => (
    <Card
      className={classNames(className, styles.container)}
      key={`careplan-${title}`}
    >
      <div className={classNames(styles.subContainer, styles.titleContainer)}>
        <div className="flex spaced">
          <span className={styles.title}>{title}</span>
        </div>
      </div>
      <div className={styles.cardContent}>{children}</div>
      {subtitle && (
        <p className={classNames('flex space-between', styles.cardContent)}>
          <span />
          <span className={styles.subtitleText}>{subtitle}</span>
        </p>
      )}
    </Card>
  );

  const renderCarePlanCard = (data: MemberCarePlan, idx: number) =>
    renderCard(
      data.description,
      data.goals.map((goal, idx) => (
        <Fragment key={`careplan-${data.description}-goal-${goal.description}`}>
          {idx !== 0 && <div style={{ height: '1rem' }} />}
          <p className={styles.sectionTitle}>
            {translate('memberProfile.clinicalSummary.tables.careplan.goal')}
          </p>
          <p>{goal.description}</p>
          <div className={styles.dataContainer}>
            {goal.objectives.map((objective, idxo) => (
              <Fragment
                key={`care-plan-${data.description}-goal-${idx}-objective-${idxo}`}
              >
                {idxo !== 0 && (
                  <hr
                    style={{
                      margin: '10px 0',
                      width: 'calc(100% - 1rem)',
                    }}
                  />
                )}
                <p className={styles.sectionSubTitle}>
                  {translate(
                    'memberProfile.clinicalSummary.tables.careplan.objective',
                  )}
                </p>
                <p>{objective.description}</p>

                {!!objective.interventions?.length && (
                  <>
                    <p className={styles.sectionSubSubTitle}>
                      {translate(
                        'memberProfile.clinicalSummary.tables.careplan.interventions',
                      )}
                    </p>
                    <ul className={classNames(styles.interventionsList)}>
                      {objective.interventions.map((intervention, idxi) => (
                        <li
                          key={`care-plan-${data.description}-goal-${idx}-objective-${idxo}-intervention-${idxi}`}
                        >
                          {intervention}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </Fragment>
      )),
      idx === 0 && updatedAt
        ? translate('global.lastUpdatedBy', {
            date: updatedAt.format(DATE_FORMAT_SHORT),
            editor: updatedBy,
          })
        : '',
    );

  return (
    <div
      className="flex wrap extra-gap baseline"
      style={{ marginBottom: '5rem', width: '100%' }}
    >
      <div
        className={styles.container}
        style={{ flex: '2 1', minWidth: '550px' }}
      >
        {(careplans || [])
          .filter(({ goals }) => !!goals?.length)
          .map(renderCarePlanCard)}
      </div>
      <div
        className={styles.container}
        style={{ flex: '1 1', minWidth: '400px' }}
      >
        {renderCard(
          translate(
            'memberProfile.clinicalSummary.tables.careplan.providerRecommendations',
          ),
          isRecommendationEmpty(providerRecommendations?.description) ? (
            <p className="grey italic">
              {translate(
                'memberProfile.clinicalSummary.tables.careplan.emptyRecommendation',
              )}
            </p>
          ) : (
            <p>{providerRecommendations?.description}</p>
          ),
          providerRecommendations?.date
            ? translate('global.lastUpdatedBy', {
                date: providerRecommendations.date.format(DATE_FORMAT_SHORT),
                editor: providerRecommendations.user,
              })
            : undefined,
        )}
        {renderCard(
          translate(
            'memberProfile.clinicalSummary.tables.careplan.rnRecommendations',
          ),
          isRecommendationEmpty(rnRecommendations?.description) ? (
            <p className="grey italic">
              {translate(
                'memberProfile.clinicalSummary.tables.careplan.emptyRecommendation',
              )}
            </p>
          ) : (
            <p>{rnRecommendations?.description}</p>
          ),
          rnRecommendations?.date
            ? translate('global.lastUpdatedBy', {
                date: rnRecommendations.date.format(DATE_FORMAT_SHORT),
                editor: rnRecommendations.user,
              })
            : undefined,
        )}
      </div>
      <br />
      <br />
    </div>
  );
};
