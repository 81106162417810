import {
  Brand,
  CaregiverRelationship,
  Language,
  MemberContactMethodType,
  MemberRisk,
  MemberStatus,
  PatientGender,
  PatientLivingArrangement,
  ReasonWithdraw,
  ResidenceType,
  VirtualVisitPlatform,
  VirtualVisitStatus,
} from '../enums';
import moment from '../moment';
import { DATE_FORMAT_SHORT, EMPTY } from '../utils/constants';
import { Address } from './Address';
import { AppUser } from './AppUser';
import { BaseEnum } from './BaseEnum';
import { CarePlanGroup } from './CarePlanGroup';
import { Employee } from './Employee';
import { EmployeeGroup } from './EmployeeGroup';
import {
  PatientBrandHelper,
  hasBrandCareAtHome,
} from './Helpers/PatientBrandHelper';
import {
  VestaCoreLiteHelper,
  isVPC,
  isVestaCoreLite,
} from './Helpers/VestaCoreLiteHelper';
import { HouseHoldMember } from './HouseHoldMember';
import { MemberEmail } from './MemberEmail';
import { MemberPhone } from './MemberPhone';
import { MemberProgramExtension } from './MemberProgramExtension';
import { MemberReferral } from './MemberReferral';
import { make, makeArray, makeEnums } from './Model';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class Patient implements PatientBrandHelper, VestaCoreLiteHelper {
  // TODO: why are all these marked as optional?
  address: Address;

  birthDate?: string;

  carePlanGroup?: CarePlanGroup;

  careTeamInfo?: string;

  deceasedDate?: string;

  email?: MemberEmail;

  expectedWeeklyHealthReports?: number;

  externalId?: string;

  firstName = '';

  fullName = '';

  gender?: PatientGender;

  genderComment?: string;

  hciNotifications = false;

  householdMembers?: HouseHoldMember[];

  id = 0;

  // TODO: rename to "preferredLanguage" in front/back
  language?: Language;

  languages?: Language[];

  lastName = '';

  lastPCPVisit?: string;

  latestAppVersion = '';

  livingArrangement: PatientLivingArrangement[];

  livingArrangementOther?: string;

  careCoordinators?: Employee[];

  engagementOwners?: Employee[];

  healthCoaches?: Employee[];

  npOwner?: Employee;

  owner?: Employee;

  phoneNumber?: string;

  podManager?: Employee;

  reasonWithdraw?: ReasonWithdraw;

  reportingStartDate?: string;

  residenceType?: ResidenceType;

  residenceTypeOther?: string;

  riskLevel?: MemberRisk;

  riskLevelUpdatedAt = 0;

  riskLevelUpdatedBy?: Employee;

  selfDirecting = false;

  socialAssessmentCompletedAt?: string;

  status?: MemberStatus;

  statusUpdatedAt = 0;

  statusUpdatedBy?: Employee;

  strengthsAndBarriers?: string;

  phones?: MemberPhone[];

  primaryPhone?: MemberPhone;

  timeZone?: string;

  test = false;

  virtualVisitDate?: number;

  virtualVisitPlatform?: VirtualVisitPlatform;

  virtualVisitStatus?: VirtualVisitStatus;

  virtualVisitHostName?: string;

  virtualVisitHostRelationship?: CaregiverRelationship;

  virtualVisitHostRelationshipOther?: string;

  virtualVisitHostPhoneNumber?: number;

  virtualVisitHasTime?: boolean;

  'data-cy'?: string;

  activeReferrals?: MemberReferral[];

  deviceNotificationsEnabled = false;

  notes?: string;

  programExtensions: MemberProgramExtension[] = [];

  worklistGroup?: EmployeeGroup;

  hciReporter = false;

  languageEmrIntegrated = false;

  startDateEmrIntegrated = false;

  emrIntegrated = false;

  crmIntegrated = false;

  hciReporterStatus?: BaseEnum;

  hciReporterMethod?: MemberContactMethodType;

  appUser: AppUser;

  brand: Brand;

  lockedBrand = false;

  brandAutomatedUpdate = false;

  wasEverOnboarded = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.address = make(obj.address, Address);
    this.carePlanGroup = make(obj.carePlanGroup, CarePlanGroup);
    this.email = make(obj.email, MemberEmail);
    this.gender = PatientGender.byKey[obj.gender];
    this.language = obj.language && Language.byKey[obj.language];
    this.languages = makeEnums(obj.languages, Language);
    this.livingArrangement =
      obj.livingArrangement &&
      obj.livingArrangement.map(
        (i: string) => PatientLivingArrangement.byKey[i],
      );
    this.careCoordinators = makeArray(obj.careCoordinators, Employee);
    this.engagementOwners = makeArray(obj.engagementOwners, Employee);
    this.healthCoaches = makeArray(obj.healthCoaches, Employee);
    this.householdMembers = makeArray(obj.householdMembers, HouseHoldMember);
    this.npOwner = make(obj.npOwner, Employee);
    this.owner = make(obj.owner, Employee);
    this.podManager = make(obj.podManager, Employee);
    this.reasonWithdraw = ReasonWithdraw.byKey[obj.reasonWithdraw];
    this.residenceType = ResidenceType.byKey[obj.residenceType];
    this.riskLevel = MemberRisk.byKey[obj.riskLevel];
    this.riskLevelUpdatedBy = make(obj.riskLevelUpdatedBy, Employee);
    this.status = MemberStatus.byKey[obj.status];
    this.statusUpdatedBy = make(obj.statusUpdatedBy, Employee);
    this.activeReferrals = obj.activeReferrals?.map((o: any) =>
      make(o, MemberReferral),
    );
    this.phones = obj.phones?.map((o: any) => make(o, MemberPhone));
    this.primaryPhone = obj.primaryPhone && make(obj.primaryPhone, MemberPhone);
    this.programExtensions = makeArray(
      obj.programExtensions,
      MemberProgramExtension,
    );
    this.virtualVisitPlatform =
      VirtualVisitPlatform.byKey[obj.virtualVisitPlatform];
    this.virtualVisitStatus = VirtualVisitStatus.byKey[obj.virtualVisitStatus];
    this.virtualVisitHostRelationship =
      CaregiverRelationship.byKey[obj.virtualVisitHostRelationship];
    this.worklistGroup = make(obj.worklistGroup, EmployeeGroup);
    this.hciReporterStatus = make(obj.hciReporterStatus, BaseEnum);
    this.hciReporterMethod = obj.hciReporterMethod
      ? MemberContactMethodType.byKey[obj.hciReporterMethod]
      : undefined;
    this.appUser = make(obj.appUser, AppUser);
    this.brand = Brand.byKey[obj.brand];
  }

  get nameWithId() {
    return `${this.fullName} (${this.id})`;
  }

  get age() {
    return this.birthDate && moment().diff(this.birthDate, 'years');
  }

  get birthDateFormatted() {
    return this.birthDate && moment(this.birthDate).format(DATE_FORMAT_SHORT);
  }

  getTopThreeLanguages(): Language[] {
    const languages = this.languages || [];
    const values = languages.map((lang) => lang.valueOf());
    return Language.sortByHTPreferredOrder(values)
      .map((val) => Language.byKey[val])
      .slice(0, 3);
  }

  getSortedProgramExtensions(filter?: boolean) {
    const sorted = [...this.programExtensions].sort((a, b) =>
      a.programExtension.name.localeCompare(b.programExtension.name),
    );

    if (filter) {
      return sorted.filter(
        ({ status }) => status?.id !== ProgramExtensionStatus.DISENROLLED,
      );
    }
    return sorted;
  }

  get hasOwner() {
    return !!this.owner || !!this.npOwner;
  }

  isLanguageEditable() {
    return (
      !this.languageEmrIntegrated ||
      (this.status &&
        MemberStatus.ACTIVE !== this.status &&
        MemberStatus.ACCEPTED !== this.status)
    );
  }

  isEmrIntegrated() {
    return this.emrIntegrated;
  }

  isCrmIntegrated() {
    return this.crmIntegrated;
  }

  isInsuranceEditable() {
    return this.status && MemberStatus.ACTIVE !== this.status;
  }

  isDemographicsEditable() {
    return !this.emrIntegrated && !this.crmIntegrated;
  }

  isEditable() {
    return this.status !== MemberStatus.INVALID;
  }

  isOwnedByRN(user: Employee) {
    return this.owner?.id === user.id;
  }

  isOwnedByNP(user: Employee) {
    return this.npOwner?.id === user.id;
  }

  isOwnedByUser(user: Employee) {
    return this.isOwnedByRN(user) || this.isOwnedByNP(user);
  }

  isBrandCareAtHome(): boolean {
    return hasBrandCareAtHome(this);
  }

  isVPC(): false | MemberReferral {
    return isVPC(this as VestaCoreLiteHelper);
  }

  isVestaCoreLite(): false | MemberProgramExtension {
    return isVestaCoreLite(this as VestaCoreLiteHelper);
  }

  isVestaCoreLiteOrVPC(): false | MemberProgramExtension | MemberReferral {
    return this.isVPC() || this.isVestaCoreLite();
  }

  getMemberProgramExtensionByProExtId(proExtId: number) {
    return this.programExtensions.filter(
      ({ programExtension: { id } }) => id === proExtId,
    )[0];
  }

  get referralsNames() {
    return (
      this.activeReferrals
        ?.map((ref) => ref.organization.abbr?.toUpperCase())
        .join(', ') || EMPTY
    );
  }
}

export default Patient;
