import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { makeStyles } from '@mui/styles';

import { Language } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  EngagementAudience,
  EngagementContent,
  EngagementFileType,
} from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  FileInput,
  IconButton,
  Select,
  SwitchGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { CreateContentLibraryParams } from 'dash/src/services/EngagementServices';

const useStyles = makeStyles({
  attachIcon: {
    right: '-1.5rem',
  },
  firstIcon: {
    '&&': {
      margin: '2.25rem auto auto',
    },
  },
  icon: {
    '&&': {
      margin: '0.5rem auto auto',
    },
  },
  separator: {
    margin: '0.25rem',
  },
});

interface Props {
  content?: EngagementContent;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: CreateContentLibraryParams,
    files: File[],
    content?: EngagementContent,
  ) => Promise<boolean>;
}

type LanguageItem = {
  file?: File;
  fileStored?: boolean;
  language?: Language;
  text?: string;
};

const PERSON_VARIABLE = '<member_name>';
const MAX_FILE_SIZE = 300 * 1024 * 1024;

export const ContentEditModal = ({
  content,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [audiences, setAudiences] = useState<EngagementAudience[]>([]);
  const [fileTypes, setFileTypes] = useState<EngagementFileType[]>([]);
  const [types, setTypes] = useState<BaseEnum[]>([]);
  const [languagesAvailable, setLanguagesAvailable] = useState<Language[]>([]);

  const [active, setActive] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [fileType, setFileType] = useState<EngagementFileType>();
  const [description, setDescription] = useState<string>();
  const [type, setType] = useState<BaseEnum>();
  const [audience, setAudience] = useState<EngagementAudience[]>();

  const [languages, setLanguages] = useState<LanguageItem[]>([]);

  const getInitialData = async () => {
    setLoadingSelect(true);
    const [audiences, types, fileTypes, languages] = await Promise.all([
      CacheServices.getEngagementContentAudiences(),
      CacheServices.getEngagementContentTypes(),
      CacheServices.getEngagementFileTypes(),
      CacheServices.getEngagementLanguages(),
    ]);
    setAudiences(audiences);
    setTypes(types);
    setFileTypes(fileTypes);
    setLanguagesAvailable(languages);
    setLoadingSelect(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setSubmitted(false);
    setLoading(false);

    setActive(!!content?.active);
    setName(content?.name);
    setFileType(content?.fileType);
    setDescription(content?.description);
    setAudience(content?.tags);
    setType(content?.contentType);

    if (content?.contentItems?.length) {
      setLanguages(
        content.contentItems.map(({ language, rawText, referenceLink }) => ({
          language,
          fileStored: !!referenceLink,
          text: rawText,
        })),
      );
    } else {
      setLanguages([{ language: Language.ENGLISH }]);
    }
  }, [open]);

  const reloadFileTypes = () => {
    setLanguages([{ language: Language.ENGLISH }]);
  };

  const invalidFile = (fileType: EngagementFileType) => ({
    file,
    fileStored,
    language,
    text,
  }: LanguageItem) => {
    if (!language) return true;
    if (fileType.isText) return !text;
    return (!file && !fileStored) || (file && file.size > MAX_FILE_SIZE);
  };

  const validate = () =>
    name &&
    fileType &&
    type &&
    audience?.length &&
    !languages.find(invalidFile(fileType));

  const doSubmit = async () => {
    setSubmitted(true);
    if (name && fileType && audience && type && validate()) {
      setLoading(true);
      const result = await onSubmit(
        {
          active,
          name,
          description,
          contentTypeId: type.id,
          fileTypeId: fileType.id,
          tagIds: audience.map(({ id }) => id),
          contentItems: languages.map(({ file, language, text }) => ({
            fieldName: file?.name,
            hasChanged: !!file,
            languageId: language?.value || Language.ENGLISH.value,
            rawText: text,
          })),
        },
        languages
          .map(({ file }) => file)
          .filter((file): file is File => !!file),
        content,
      );
      if (result) {
        onClose();
      }
      setLoading(false);
    }
  };

  const getAccetpFiles = (fileType?: EngagementFileType) => {
    if (fileType?.isImage) return 'image/*';
    if (fileType?.isVideo) return 'video/*';
    if (fileType?.isPDF) return '.pdf,application/pdf';
    if (fileType?.isWord)
      return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  };

  const getFilePlaceholder = (
    fileType?: EngagementFileType,
    fileSaved?: boolean,
  ) => {
    if (fileSaved) return translate('campaigns.contentLibrary.fileNoChange');
    return fileType?.description || translate('campaigns.contentLibrary.file');
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('campaigns.contentLibrary.modalTitleAdd')}</h2>}
      size={650}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          {content && (
            <SwitchGroup
              className="grid-span-12"
              items={[
                {
                  label: translate('campaigns.contentLibrary.active'),
                  checked: active,
                },
              ]}
              onChange={(items) => setActive(!!items[0].checked)}
            />
          )}
          <TextInput
            className="grid-span-6"
            data-cy="edit-content-name"
            error={submitted && !name}
            label={translate('campaigns.contentLibrary.name')}
            onChange={(value) => {
              const safeValue = value
                ?.trim()
                .split(' ')
                .filter(Boolean)
                .join(' ');
              setName(safeValue || '');
            }}
            required
            value={name}
          />
          <Select
            className="grid-span-6"
            data-cy="edit-content-file-type"
            error={submitted && !fileType}
            getItemLabel={({ description }: EngagementFileType) => description}
            items={fileTypes}
            label={translate('campaigns.contentLibrary.fileType')}
            loading={loadingSelect}
            onChange={(val?: EngagementFileType) => {
              setFileType(val);
              reloadFileTypes();
            }}
            required
            value={fileType}
          />
          <TextArea
            className="grid-span-12"
            data-cy="edit-content-description"
            label={translate('campaigns.contentLibrary.description')}
            onChange={setDescription}
            rows={4}
            value={description}
          />
          <Select
            className="grid-span-6"
            data-cy="edit-content-type"
            error={submitted && !type}
            getItemLabel={({ description }: BaseEnum) => description}
            items={types}
            label={translate('campaigns.contentLibrary.contentType')}
            loading={loadingSelect}
            onChange={setType}
            required
            value={type}
          />
          <Select
            className="grid-span-6"
            data-cy="edit-content-audiece"
            error={submitted && !audience}
            getItemLabel={({ description }: BaseEnum) => description}
            items={audiences}
            label={translate('campaigns.contentLibrary.audience')}
            limitTags={1}
            multiple
            loading={loadingSelect}
            onChange={setAudience}
            required
            value={audience}
          />
          {languages.map(({ language, file, fileStored, text }, index) => (
            <Fragment key={`language-item-${index}`}>
              <Select
                className={fileType?.isText ? 'grid-span-11' : 'grid-span-5'}
                data-cy={`edit-content-language-${index}`}
                error={
                  submitted &&
                  !language &&
                  translate('global.missingRequiredFieldMin')
                }
                items={Enum.toSelectable(
                  languagesAvailable.filter(
                    (item) =>
                      language === item ||
                      !languages.find(({ language }) => language === item),
                  ),
                )}
                label={
                  index === 0
                    ? translate('campaigns.contentLibrary.language')
                    : undefined
                }
                onChange={(item?: Selectable) => {
                  delete languages[index].file;
                  languages[index].fileStored = false;
                  languages[index].language = item
                    ? Language.byKey[item.value]
                    : undefined;
                  setLanguages([...languages]);
                }}
                placeholder={translate('campaigns.contentLibrary.language')}
                value={language ? Enum.toSelectable([language])[0] : undefined}
              />
              {!fileType?.isText && (
                <FileInput
                  accept={getAccetpFiles(fileType)}
                  className="grid-span-6"
                  data-cy={`edit-content-file-${index}`}
                  disabled={!fileType}
                  error={
                    submitted &&
                    (file
                      ? file.size > MAX_FILE_SIZE &&
                        translate('campaigns.contentLibrary.errorFileSize')
                      : !fileStored)
                  }
                  file={file}
                  label={
                    index === 0
                      ? fileType?.description ||
                        translate('campaigns.contentLibrary.file')
                      : ''
                  }
                  onChange={(file?: File | null) => {
                    languages[index].file = file || undefined;
                    setLanguages([...languages]);
                  }}
                  placeholder={getFilePlaceholder(fileType, fileStored)}
                />
              )}
              <div className="grid-span-1 flex">
                {fileType?.isText &&
                  audience &&
                  audiences.find((audience) => !audience.isMember) && (
                    <IconButton
                      className={index === 0 ? styles.firstIcon : styles.icon}
                      size="small"
                      onClick={() => {
                        languages[index].text = `${
                          languages[index].text || ''
                        }${PERSON_VARIABLE}`;
                        setLanguages([...languages]);
                      }}
                      tooltip={translate(
                        'campaigns.contentLibrary.tooltipAddPerson',
                      )}
                    >
                      <PersonIcon fontSize="large" />
                    </IconButton>
                  )}
                <IconButton
                  className={index === 0 ? styles.firstIcon : styles.icon}
                  disabled={languages && languages.length < 2}
                  size="small"
                  onClick={() => {
                    languages?.splice(index, 1);
                    setLanguages([...languages]);
                  }}
                  tooltip={translate('global.delete')}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </div>
              <TextArea
                className="grid-span-12"
                data-cy={`edit-content-file-${index}`}
                error={submitted && fileType?.isText && !text}
                onChange={(text?: string) => {
                  languages[index].text = text || undefined;
                  setLanguages([...languages]);
                }}
                minRows={2}
                maxRows={5}
                placeholder={translate(
                  `campaigns.contentLibrary.${
                    fileType?.isText ? 'textTemplate' : 'textIntro'
                  }`,
                )}
                value={text}
              />
              {index !== languages.length - 1 && (
                <hr className={classNames('grid-span-12', styles.separator)} />
              )}
            </Fragment>
          ))}

          <div className="grid-span-3" />
          {languages?.length < languagesAvailable?.length && (
            <Button
              className="grid-span-6"
              color="secondary"
              disabled={!fileType}
              type="outlined"
              onClick={() =>
                setLanguages([...(languages || []), {} as LanguageItem])
              }
            >
              {translate('campaigns.contentLibrary.addLanguage')}
            </Button>
          )}
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" data-cy="edit-content-close" onClick={onClose}>
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-content-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default ContentEditModal;
