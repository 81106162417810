import React, { useEffect, useMemo, useState } from 'react';

import { EmployeeRole, Enum, States } from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  Organization,
  PodConfig,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import PodConfigEmployeeSlot from '@vestahealthcare/common/models/PodConfigEmployeeSlot';
import { getDiff } from '@vestahealthcare/common/utils/api';

import {
  Button,
  CollapsableSidebar,
  Select,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import {
  CreatePodConfigParams,
  UpdatePodConfigParams,
} from 'dash/src/services/PodServices';

import { PodEmployeeSlotField } from './PodEmployeeSlotField';

interface Props {
  data: {
    employees: SelectableInfo<Employee>[];
    languages: Selectable[];
    programExtensions: Selectable[];
    referrals: SelectableInfo<Organization>[];
  };
  loadingData: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: Partial<UpdatePodConfigParams> | CreatePodConfigParams,
  ) => Promise<void>;
  pod?: PodConfig;
}

const PodConfigEditModal = ({
  data,
  loadingData,
  open,
  onClose,
  onSubmit,
  pod,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [active, setActive] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [maxMembers, setMaxMembers] = useState<number>();
  const [manager, setManager] = useState<SelectableInfo<Employee>>();
  const [state, setState] = useState<Selectable[]>([]);
  const [language, setLanguage] = useState<Selectable[]>([]);
  const [referralSource, setReferralSource] = useState<
    SelectableInfo<Organization>[]
  >([]);
  const [np, setNP] = useState<PodConfigEmployeeSlot[]>([]);
  const [rn, setRN] = useState<PodConfigEmployeeSlot[]>([]);
  const [healthCoach, setHealthCoach] = useState<PodConfigEmployeeSlot[]>([]);
  const [engagementOwner, setEngagementOwner] = useState<
    PodConfigEmployeeSlot[]
  >([]);
  const [careCoordinator, setCareCoordinator] = useState<
    PodConfigEmployeeSlot[]
  >([]);
  const [programExtension, setProgramExtension] = useState<Selectable[]>([]);

  const podManagers = useMemo(
    () => data.employees?.filter(({ info }) => info?.isPodManager),
    [data.employees],
  );

  const nps = useMemo(() => data.employees?.filter(({ info }) => info?.isNP), [
    data.employees,
  ]);

  const rns = useMemo(() => data.employees?.filter(({ info }) => info?.isRN), [
    data.employees,
  ]);

  const healthCoaches = useMemo(
    () => data.employees?.filter(({ info }) => info?.isInHealthCoaches),
    [data.employees],
  );

  const engagementOwners = useMemo(
    () => data.employees?.filter(({ info }) => info?.isInEngagement),
    [data.employees],
  );

  const clinicalMedicalAssistant = useMemo(
    () => data.employees?.filter(({ info }) => info?.isCareCoordinator),
    [data.employees],
  );

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSubmitted(false);

      setActive(!!pod?.active);
      setName(pod?.name || '');
      setManager(
        pod?.podManager
          ? Employee.toSelectable([pod.podManager])[0]
          : undefined,
      );
      setMaxMembers(pod?.maxMembers);
      setState(States.toSelectable(pod?.states || []));
      setLanguage(Enum.toSelectable(pod?.languages || []));
      setReferralSource(Organization.toSelectable(pod?.referralSources || []));
      setNP(pod?.npOwners || []);
      setRN(pod?.rnOwners || []);
      setHealthCoach(pod?.healthCoaches || []);
      setEngagementOwner(pod?.engagementOwners || []);
      setCareCoordinator(pod?.careCoordinators || []);
      setProgramExtension(
        ProgramExtension.toSelectable(pod?.programExtensions || []),
      );
    }
  }, [open]);

  const validateEmployeeSlot = (slots?: PodConfigEmployeeSlot[]) =>
    !slots?.find((slot) => !slot?.maxMembers);

  const validate = () =>
    Boolean(
      name &&
        manager &&
        state?.length &&
        language?.length &&
        programExtension?.length &&
        maxMembers &&
        validateEmployeeSlot(np) &&
        validateEmployeeSlot(rn) &&
        validateEmployeeSlot(healthCoach) &&
        validateEmployeeSlot(engagementOwner) &&
        validateEmployeeSlot(careCoordinator),
    );

  const buildEmployeeSlots = (items?: PodConfigEmployeeSlot[]) =>
    items?.map(({ id, employee, maxMembers, role }) => ({
      id: id || undefined,
      employeeId: employee?.id,
      maxMembers,
      role: role || '',
    })) || [];

  const doSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      setLoading(true);

      const employeeSlots = [
        ...buildEmployeeSlots(np),
        ...buildEmployeeSlots(rn),
        ...buildEmployeeSlots(healthCoach),
        ...buildEmployeeSlots(engagementOwner),
        ...buildEmployeeSlots(careCoordinator),
      ];

      try {
        if (pod) {
          const oldEmployeeSlots = [
            ...buildEmployeeSlots(pod.npOwners),
            ...buildEmployeeSlots(pod.rnOwners),
            ...buildEmployeeSlots(pod.healthCoaches),
            ...buildEmployeeSlots(pod.engagementOwners),
            ...buildEmployeeSlots(pod.careCoordinators),
          ];

          await onSubmit(
            pod.id,
            getDiff<UpdatePodConfigParams>(
              {
                active: pod.active,
                name: pod.name,
                employees: oldEmployeeSlots,
                languages: pod.languages?.map(({ value }) => value as string),
                managerId: pod.podManager.id,
                maxMembers: pod.maxMembers,
                programExtensionIds: pod.programExtensions?.map(({ id }) => id),
                referralSourceIds: pod.referralSources?.map(({ id }) => id),
                states: pod.states?.map(({ value }) => value),
              },
              {
                active,
                name,
                employees: employeeSlots,
                languages: language?.map(({ value }) => value as string),
                managerId: manager?.value as number,
                maxMembers,
                programExtensionIds: programExtension?.map(
                  ({ value }) => value as number,
                ),
                referralSourceIds: referralSource?.map(
                  ({ value }) => value as number,
                ),
                states: state?.map(({ value }) => value as string),
              },
            ),
          );
        } else {
          await onSubmit(0, {
            name,
            employees: employeeSlots,
            languages: language?.map(({ value }) => value as string),
            managerId: manager?.value as number,
            maxMembers,
            programExtensionIds: programExtension?.map(
              ({ value }) => value as number,
            ),
            referralSourceIds: referralSource?.map(
              ({ value }) => value as number,
            ),
            states: state?.map(({ value }) => value as string),
          } as CreatePodConfigParams);
        }
        onClose();
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(`pods.config.modal.title${pod ? 'Edit' : 'Add'}`, {
            pod: pod?.name || 'POD',
          })}
        </h2>
      }
      size={500}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-12"
            error={submitted && !name}
            label={translate('pods.config.common.name')}
            onChange={(value) => {
              const safeValue = value
                ?.trim()
                .split(' ')
                .filter(Boolean)
                .join(' ');
              setName(safeValue || '');
            }}
            value={name}
            required
          />
          <Select
            className="grid-span-12"
            disableClearable
            error={submitted && !manager}
            items={podManagers}
            label={translate('pods.config.common.podManager')}
            loading={loadingData}
            onChange={setManager}
            value={manager}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !state?.length}
            items={Enum.toSelectable(States.asArray)}
            label={translate('pods.config.common.states')}
            limitTags={1}
            multiple
            onChange={setState}
            value={state}
            required
          />
          <Select
            className="grid-span-6"
            error={submitted && !language?.length}
            grouped={({ preferred }) =>
              preferred
                ? translate('global.preferred')
                : translate('global.other')
            }
            items={data.languages}
            label={translate('pods.config.common.languages')}
            loading={loadingData}
            limitTags={1}
            multiple
            onChange={setLanguage}
            value={language}
            required
          />
          <ReferralDropdown
            className="grid-span-12"
            items={data.referrals}
            label={translate('pods.config.common.referralSources')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setReferralSource}
            value={referralSource}
          />
          <Select
            className="grid-span-12"
            error={submitted && !programExtension?.length}
            items={data.programExtensions}
            label={translate('pods.config.common.programExtensions')}
            limitTags={1}
            loading={loadingData}
            multiple
            onChange={setProgramExtension}
            value={programExtension}
            required
          />
          <TextInput
            className="grid-span-12"
            error={submitted && !maxMembers}
            label={translate('pods.config.common.maxMembers')}
            onChange={(value) => {
              setMaxMembers(
                value !== undefined ? parseInt(value, 10) : undefined,
              );
            }}
            value={maxMembers}
            type="number"
            required
          />
          <PodEmployeeSlotField
            className="grid-span-12"
            items={nps}
            label={translate('pods.config.common.nps')}
            loading={loadingData}
            onChange={setNP}
            value={np}
            role={EmployeeRole.NP.value}
            showUnassigned={!!pod?.id}
            submitted={submitted}
          />
          <PodEmployeeSlotField
            className="grid-span-12"
            items={rns}
            label={translate('pods.config.common.rns')}
            loading={loadingData}
            onChange={setRN}
            value={rn}
            role={EmployeeRole.RN.value}
            showUnassigned={!!pod?.id}
            submitted={submitted}
          />
          <PodEmployeeSlotField
            className="grid-span-12"
            items={healthCoaches}
            label={translate('pods.config.common.healthCoaches')}
            loading={loadingData}
            onChange={setHealthCoach}
            value={healthCoach}
            role={EmployeeRole.HEALTH_COACH.value}
            showUnassigned={!!pod?.id}
            submitted={submitted}
          />
          <PodEmployeeSlotField
            className="grid-span-12"
            items={engagementOwners}
            label={translate('pods.config.common.engagementOwners')}
            loading={loadingData}
            onChange={setEngagementOwner}
            value={engagementOwner}
            role={EmployeeRole.ENGAGEMENT_COORDINATOR.value}
            showUnassigned={!!pod?.id}
            submitted={submitted}
          />
          <PodEmployeeSlotField
            className="grid-span-12"
            items={clinicalMedicalAssistant}
            label={translate('pods.config.common.careCoordinators')}
            loading={loadingData}
            onChange={setCareCoordinator}
            value={careCoordinator}
            role={EmployeeRole.MA.value}
            showUnassigned={!!pod?.id}
            submitted={submitted}
          />
          {pod?.id && (
            <SwitchGroup
              className="grid-span-12"
              items={[
                {
                  label: translate('pods.config.common.active'),
                  checked: active,
                },
              ]}
              onChange={(items) => setActive(!!items[0].checked)}
            />
          )}
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="add-pod-config-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="save-pod-config-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default PodConfigEditModal;
