// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-picker .grid-wrapper-none {
  align-items: center;
  margin-bottom: 1em;
}
.language-picker .grid-wrapper-none > * {
  align-self: start;
}
.language-picker .grid-wrapper-none .radio-toggle {
  margin-top: 7px;
  margin-left: 15px;
}
.language-picker .icon.delete-icon {
  height: 45px;
}
.language-picker .other-input {
  margin-top: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/ListPickerWithPreferred/main.less"],"names":[],"mappings":"AAEA;EAEI,mBAAA;EACA,kBAAA;AAFJ;AADA;EAMM,iBAAA;AAFN;AAJA;EAUM,eAAA;EACA,iBAAA;AAHN;AARA;EAgBI,YAAA;AALJ;AAXA;EAoBI,iBAAA;AANJ","sourcesContent":["@import (reference) '~styleguide/src/styles/variables.less';\n\n.language-picker {\n  .grid-wrapper-none {\n    align-items: center;\n    margin-bottom: 1em;\n\n    > * {\n      align-self: start;\n    }\n\n    .radio-toggle {\n      margin-top: 7px;\n      margin-left: 15px;\n    }\n  }\n\n  .icon.delete-icon {\n    height: 45px;\n  }\n\n  .other-input {\n    margin-top: 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
