import { Moment } from 'moment';

import { CCMTaskMember } from './CCMTaskMember';
import { make, makeMoment, makeUnix } from './Model';

export class CCMTask {
  taskId = 0;

  taskDate: Moment;

  callback?: Moment;

  ccmValidMinutes = 0;

  minMinutesNextCPT = 0;

  member: CCMTaskMember;

  numberOfCalls = 0;

  taskName = '';

  constructor(obj: any) {
    Object.assign(this, obj);

    this.callback = makeUnix(obj.callback);
    this.member = make(obj.member, CCMTaskMember);
    this.taskDate = makeMoment(obj.taskDate) as Moment;
  }
}

export default CCMTask;
