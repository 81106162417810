import moment, { Moment } from 'moment';

import { AppointmentStatus } from '../enums';
import { DATE_FORMAT } from '../utils/constants';
import { MemberDashboardBiannualScheduleInfo } from './MemberDashboardBiannualScheduleInfo';
import { make } from './Model';

export class MemberDashboardBiannual {
  lastBiannualDate?: Moment;

  lastBiannualType = '';

  lastBiannualEmployee = '';

  lastBiannualStatus?: AppointmentStatus;

  nextBiannualDate?: Moment;

  nextBiannualType = '';

  nextBiannualEmployee = '';

  nextBiannualStatus?: AppointmentStatus;

  nextBiannualDue?: Moment;

  biannualStructuredData?: MemberDashboardBiannualScheduleInfo;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.lastBiannualDate = obj.lastBiannualDate
      ? moment(obj.lastBiannualDate, DATE_FORMAT)
      : undefined;
    this.lastBiannualStatus = obj.lastBiannualStatus
      ? AppointmentStatus.byKey[obj.lastBiannualStatus]
      : undefined;
    this.nextBiannualDate = obj.nextBiannualDate
      ? moment(obj.nextBiannualDate, DATE_FORMAT)
      : undefined;
    this.nextBiannualDue = obj.nextBiannualDue
      ? moment(obj.nextBiannualDue, DATE_FORMAT)
      : undefined;
    this.nextBiannualStatus = obj.nextBiannualStatus
      ? AppointmentStatus.byKey[obj.nextBiannualStatus]
      : undefined;
    this.biannualStructuredData = make(
      obj.biannualStructuredData,
      MemberDashboardBiannualScheduleInfo,
    );
  }
}

export default MemberDashboardBiannual;
