import classNames from 'classnames';
import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import { EncounterType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CCMMemberDetail,
  ClinicalMember,
  MemberDashboardEvent,
  MemberDashboardTask,
} from '@vestahealthcare/common/models';
import { PaginatedResponse } from '@vestahealthcare/common/models/Pagination';

import { Toast } from 'styleguide';
import { BackgroundColors, Colors, Modal } from 'styleguide-v2';

import { BrandBanner } from 'dash/src/components/BrandBanner';
import { CCMCard } from 'dash/src/pages/MemberProfile/Dashboard/CCMCard';
import { ClinicalSummaryCard } from 'dash/src/pages/MemberProfile/Dashboard/ClinicalSummaryCard';
import { KeyContactsCard } from 'dash/src/pages/MemberProfile/Dashboard/KeyContactsCard';
import { NPBiannualCard } from 'dash/src/pages/MemberProfile/Dashboard/NPBiannualCard';
import { OpenTasksCard } from 'dash/src/pages/MemberProfile/Dashboard/OpenTasksCard';
import { PharmacyCard } from 'dash/src/pages/MemberProfile/Dashboard/PharmacyCard';
import { RPMCard } from 'dash/src/pages/MemberProfile/Dashboard/RPMCard';
import { RecentActionsCard } from 'dash/src/pages/MemberProfile/Dashboard/RecentActionsCard';
import { RecentEncountersCard } from 'dash/src/pages/MemberProfile/Dashboard/RecentEncountersCard';
import { RecentEventsCard } from 'dash/src/pages/MemberProfile/Dashboard/RecentEventsCard';
import { capitalize } from 'lodash';

import { BHICard } from '../../../../MemberProfile/Dashboard/BHICard';
import { ContactsInfoCard } from './ContactsInfoCard';
import { InfoModalHeader } from './InfoModalHeader';

type Props = {
  member?: ClinicalMember;
  detail?: CCMMemberDetail;
  events?: PaginatedResponse<MemberDashboardEvent>;
  getMoreEvents: () => Promise<void>;
  getMoreTasks: () => Promise<void>;
  open: boolean;
  onClose: () => void;
  onLogCall: (member: ClinicalMember, type: EncounterType) => void;
  tasks?: PaginatedResponse<MemberDashboardTask>;
};

const useStyles = makeStyles({
  banner: {
    bottom: '-1.5rem',
    left: '-2rem',
    paddingRight: '2.5rem',
    position: 'relative',
    width: 'calc(100% + 5rem)',
  },
  cardFit: {
    height: 'fit-content',
  },
  container: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '2fr 1fr',
    gridAutoRows: 'auto',
    paddingBottom: '2rem',
  },
  leftSubContainer: {
    '&&': {
      gridTemplateColumns: 'auto auto',
    },
  },
  modal: {
    '&& .MuiDialogTitle-root': {
      background: `${Colors.white}`,
      borderBottom: '2px solid #e7e7e7',

      '& .MuiIconButton-root': {
        top: 'initial',
      },
    },
    '&& .MuiDialogContent-root': {
      marginBottom: 0,
      paddingBottom: 0,
      background: `${BackgroundColors.gray}`,
    },
  },
  subcontainer: {
    display: 'grid',
    gap: '20px',
  },
});

export const InfoCCMModal = ({
  detail,
  events,
  getMoreEvents,
  getMoreTasks,
  member,
  open,
  onClose,
  onLogCall,
  tasks,
}: Props) => {
  const styles = useStyles();

  let toast: Toast;

  useEffect(() => {
    if (member?.id && member.isBrandCareAtHome()) {
      if (toast) {
        toast.dismiss();
      }
      const brandName = member.brand.value.split('_').map(capitalize).join(' ');
      toast = new Toast({
        title: translate('personalDetails.warningBrandTitle', {
          brand: brandName,
        }),
        body: translate('personalDetails.warningBrandBody', {
          brand: brandName,
        }),
        type: 'care-at-home',
        position: 'bottom-right',
      });

      return () => {
        if (toast) {
          toast.dismiss();
        }
      };
    }
  }, [member?.id]);

  if (!member || !detail) return <></>;

  return (
    <Modal
      className={styles.modal}
      maxWidth="xl"
      open={open}
      onClose={onClose}
      title={
        <div style={{ width: '100%' }}>
          <InfoModalHeader detail={detail} member={detail.header} />
          <BrandBanner className={styles.banner} member={detail.header} />
        </div>
      }
    >
      <div className={styles.container}>
        <div
          className={classNames(styles.subcontainer, styles.leftSubContainer)}
          style={{
            gridTemplateColumns: 'auto',
            gridAutoRows: 'max-content',
          }}
        >
          <CCMCard
            className="grid-span-12"
            data={detail?.ccmDetails}
            onLogCall={() => onLogCall(member, EncounterType.CCM)}
            warnings={detail?.warnings}
          />
          <RPMCard
            className="grid-span-12"
            data={detail?.rpmDetails}
            member={member}
            tasks={detail.openRPMTasks || []}
            warnings={detail?.warnings}
          />
          <BHICard
            className="grid-span-12"
            data={detail?.bhiDetails}
            onLogCall={() => onLogCall(member, EncounterType.BHI)}
            warnings={detail?.warnings}
          />
          <RecentEventsCard
            className={classNames('grid-span-6', styles.cardFit)}
            data={events?.items || []}
            onSeeMore={getMoreEvents}
            openEvents={detail?.openEvents}
            pagination={events?.pagination}
            member={member}
          />
          <RecentActionsCard
            className={classNames('grid-span-6', styles.cardFit)}
            data={detail?.actions}
            lastUpdated={detail?.actionsLastUpdatedAt}
            openActions={detail?.openActions}
          />
          <RecentEncountersCard
            className="grid-span-12"
            data={detail?.encounters}
          />
        </div>
        <div className={styles.subcontainer}>
          <ContactsInfoCard
            className="grid-span-12"
            data={detail?.contactSummary}
            member={member}
          />
          <NPBiannualCard
            className="grid-span-12"
            data={detail?.biannualDetails}
            extraData={detail?.appointmentDetails}
            warnings={detail?.warnings}
          />
          <ClinicalSummaryCard className="grid-span-12" member={member} />
          <KeyContactsCard
            className="grid-span-12"
            member={member}
            data={detail?.keyContacts}
            warnings={detail?.warnings}
          />
          <PharmacyCard className="grid-span-12" data={detail?.pharmacy} />
          <OpenTasksCard
            className="grid-span-12"
            data={tasks?.items || []}
            member={member}
            onSeeMore={getMoreTasks}
            openTasks={detail?.openTasks}
            pagination={tasks?.pagination}
          />
        </div>
      </div>
    </Modal>
  );
};
