// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#risk-profile-page .panel-heading {
  align-items: center;
}
#risk-profile-page .update-btn {
  margin-left: 15px;
}
#risk-profile-page .show-criteria-btn {
  color: #3f8bf4;
}
#risk-profile-page .show-criteria-btn i {
  margin-right: 3px;
}
#risk-profile-page .bordered-table {
  margin-top: 10px;
}
#risk-profile-page .bordered-table td .medium {
  margin-bottom: 10px;
}
.intervention-table.table {
  margin-top: 30px;
  margin-bottom: 30px;
}
.risk-selector {
  margin-bottom: 30px;
}
.ht-dropdown-tags {
  margin-bottom: 20px;
}
.create-plan {
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MemberProfile/PHIPProfile/main.less"],"names":[],"mappings":"AAEA;EAEI,mBAAA;AAFJ;AAAA;EAMI,iBAAA;AAHJ;AAHA;EAUI,cAAA;AAJJ;AANA;EAYM,iBAAA;AAHN;AATA;EAiBI,gBAAA;AALJ;AAZA;EAqBQ,mBAAA;AANR;AAYA;EACE,gBAAA;EACA,mBAAA;AAVF;AAaA;EACE,mBAAA;AAXF;AAcA;EACE,mBAAA;AAZF;AAeA;EACE,aAAA;EACA,yBAAA;AAbF","sourcesContent":["@import (reference) '~styleguide/src/styles/variables.less';\n\n#risk-profile-page {\n  .panel-heading {\n    align-items: center;\n  }\n\n  .update-btn {\n    margin-left: 15px;\n  }\n\n  .show-criteria-btn {\n    color: @btnBlue;\n    i {\n      margin-right: 3px;\n    }\n  }\n\n  .bordered-table {\n    margin-top: 10px;\n\n    td {\n      .medium {\n        margin-bottom: 10px;\n      }\n    }\n  }\n}\n\n.intervention-table.table {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.risk-selector {\n  margin-bottom: 30px;\n}\n\n.ht-dropdown-tags {\n  margin-bottom: 20px;\n}\n\n.create-plan {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
