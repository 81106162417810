import { MemberDashboardAppointment } from './MemberDashboardAppointment';
import { MemberDashboardAppointmentICD } from './MemberDashboardAppointmentICD';
import { makeArray } from './Model';

export class MemberDashboardAppointmentAcute extends MemberDashboardAppointment {
  icd10Codes: MemberDashboardAppointmentICD[];

  constructor(obj: any) {
    super(obj);
    this.icd10Codes = makeArray(obj.icd10Codes, MemberDashboardAppointmentICD);
  }
}

export default MemberDashboardAppointmentAcute;
