import { Moment } from 'moment';

import { makeMoment } from './Model';

export class MemberCarePlanRecommendation {
  description = '';

  date?: Moment;

  user = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.date = makeMoment(obj.date);
  }
}

export default MemberCarePlanRecommendation;
