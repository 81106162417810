import { useFlags } from 'launchdarkly-react-client-sdk';
// @ts-ignore
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

import { Choice } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { InsurancePlan } from '@vestahealthcare/common/models';

import {
  Button,
  FilterButton,
  IconButton,
  Panel,
  SearchInput,
  Select,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  InsurancePlanParams,
  createInsurancePlan,
  fetchInsurancePlans,
  updateInsurancePlan,
} from 'dash/src/services/InsurancePlansServices';
import Session from 'dash/src/services/SessionServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import InsurancePlansEdit from './InsurancePlansEdit';
import { InsurancePlansTable } from './InsurancePlansTable';

export const InsurancePlansDashboard = () => {
  const history = useHistory();
  const { showAddInsuranceButton } = useFlags();
  const { isInBilling, isInEligibility } = Session.actingUser;
  const canEdit = isInBilling || isInEligibility;

  const initFilters = () => {
    const query = useQueryParams();

    const ecwId = query.get('ecwId');
    const name = query.get('name');
    const inNetworkString = query.get('inNetwork');
    const isMedicaidString = query.get('isMedicaid');
    const isMedicareString = query.get('isMedicare');

    const inNetwork =
      inNetworkString !== null ? Boolean(inNetworkString) : undefined;

    const isMedicaid =
      isMedicaidString !== null ? Boolean(isMedicaidString) : undefined;

    const isMedicare =
      isMedicareString !== null ? Boolean(isMedicareString) : undefined;

    return {
      ecwId: ecwId || undefined,
      name: name || undefined,
      inNetwork,
      isMedicaid,
      isMedicare,
    };
  };

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlan[]>([]);
  const [editIP, setEditIP] = useState<InsurancePlan>();
  const [filteredInsurancePlans, setFilteredInsurancePlans] = useState<
    InsurancePlan[]
  >([]);
  const [filters, setFilters] = useState<InsurancePlanParams>(initFilters());
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>();

  const getInsurancePlans = async () => {
    setLoading(true);
    try {
      const ips = await fetchInsurancePlans({});
      setInsurancePlans(
        ips.sort(({ name: a }, { name: b }) => a.localeCompare(b)),
      );
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInsurancePlans();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFilteredInsurancePlans(
        insurancePlans.filter((ip) => {
          const { name, inNetwork, isMedicaid, isMedicare, ecwId } = filters;
          return (
            (!name ||
              ip.name?.toLowerCase().includes(name.toLocaleLowerCase())) &&
            (!ecwId ||
              ip.ecwIds
                ?.map(({ ecwId }) => ecwId)
                ?.join(', ')
                ?.toLocaleLowerCase()
                .includes(ecwId.toLocaleLowerCase())) &&
            (inNetwork === undefined || inNetwork === ip.inNetwork) &&
            (isMedicaid === undefined || isMedicaid === ip.isMedicaid) &&
            (isMedicare === undefined || isMedicare === ip.isMedicare)
          );
        }),
      );
    }, 0);
  }, [filters, insurancePlans]);

  useEffect(() => {
    const { name, inNetwork, isMedicaid, isMedicare, ecwId } = filters;

    const flatFilters = {
      name,
      inNetwork,
      isMedicaid,
      isMedicare,
      ecwId,
    };

    const query = qs.stringify(flatFilters, { arrayFormat: 'repeat' });
    history.replace(`?${query}`);
  }, [filters]);

  return (
    <Panel id="insurance-plans-section">
      <Panel.Heading title={translate('insurancePlans.title')}>
        <FilterButton
          className="filters"
          data-cy="insurance-plans-filters"
          filters={filters}
          onClick={() => setOpen(!isOpen)}
        />
        {showAddInsuranceButton && (
          <Panel.Actions>
            <IconButton
              onClick={() => setEditIP(new InsurancePlan({}))}
              tooltip={translate('insurancePlans.addInsurance')}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Panel.Actions>
        )}
        <Panel.Collapse data-cy="insurance-plans-collapsible" open={isOpen}>
          <div className="grid-wrapper grid-span-12 fit">
            <SearchInput
              className="grid-span-3"
              label=""
              placeholder={translate('insurancePlans.ecwId')}
              onChange={(value) => {
                const timer = setTimeout(() => {
                  setFilters({
                    ...filters,
                    ecwId: value || undefined,
                  });
                }, 500);
                if (searchTimer) {
                  clearTimeout(searchTimer);
                }
                setSearchTimer(timer);
              }}
              value={filters.ecwId}
            />
            <SearchInput
              className="grid-span-3"
              label=""
              placeholder={translate('insurancePlans.name')}
              onChange={(value) => {
                const timer = setTimeout(() => {
                  setFilters({ ...filters, name: value || undefined });
                }, 500);
                if (searchTimer) {
                  clearTimeout(searchTimer);
                }
                setSearchTimer(timer);
              }}
              value={filters.name}
            />
            <Select
              className="grid-span-3"
              data-cy="members-filter-in-network"
              items={Choice.getChoices()}
              onChange={(value: Selectable) => {
                setFilters({
                  ...filters,
                  inNetwork:
                    value?.value !== undefined
                      ? Boolean(value.value)
                      : undefined,
                });
              }}
              placeholder={translate('insurancePlans.inNetwork')}
              value={Choice.getChoices().find(
                ({ value }) => value === filters.inNetwork,
              )}
            />
            <Select
              className="grid-span-3"
              data-cy="members-filter-medicare"
              items={Choice.getChoices()}
              onChange={(value: Selectable) => {
                setFilters({
                  ...filters,
                  isMedicare:
                    value?.value !== undefined
                      ? Boolean(value.value)
                      : undefined,
                });
              }}
              placeholder={translate('insurancePlans.isMedicare')}
              value={Choice.getChoices().find(
                ({ value }) => value === filters.isMedicare,
              )}
            />
            <Select
              className="grid-span-3"
              data-cy="members-filter-medicaid"
              items={Choice.getChoices()}
              onChange={(value: Selectable) => {
                setFilters({
                  ...filters,
                  isMedicaid:
                    value?.value !== undefined
                      ? Boolean(value.value)
                      : undefined,
                });
              }}
              placeholder={translate('insurancePlans.isMedicaid')}
              value={Choice.getChoices().find(
                ({ value }) => value === filters.isMedicaid,
              )}
            />
            <div className="grid-span-6" />
            <Button
              className="grid-span-3"
              data-cy="members-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => setFilters({})}
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <InsurancePlansTable
          insurancePlans={filteredInsurancePlans}
          onEdit={canEdit ? setEditIP : undefined}
        />
        <InsurancePlansEdit
          insurancePlan={editIP}
          onClose={() => setEditIP(undefined)}
          onSubmit={async (id, params) => {
            try {
              if (id) {
                await updateInsurancePlan(id, params);
              } else {
                await createInsurancePlan(params);
              }
              CacheServices.invalidateInsurancePlansCache();
              getInsurancePlans();
            } catch (e) {
              return false;
            }
            return true;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default InsurancePlansDashboard;
