import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { Button, CollapsableSidebar, TextArea } from 'styleguide-v2';

import { CompleteCCMTaskParams } from 'dash/src/services/PodServices';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: CompleteCCMTaskParams) => Promise<void>;
  taskId?: number;
}

export const CCMTaskCompleteModal = ({
  open,
  onClose,
  onSubmit,
  taskId,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [note, setNote] = useState<string>();

  const reset = () => {
    setSubmitted(false);
    setLoading(false);
    setNote('');
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);

    if (note && taskId) {
      setLoading(true);
      try {
        await onSubmit(taskId, {
          notes: note,
        });
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={<h2>{translate('ccmTasks.modal.completeTitle')}</h2>}
      size={550}
    >
      <CollapsableSidebar.Body>
        <TextArea
          error={submitted && !note}
          label={translate('ccmTasks.modal.notes')}
          onChange={setNote}
          minRows={5}
          value={note}
          required
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="close-ccm-task-close"
          onClick={onClose}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          color="secondary"
          data-cy="close-ccm-task-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.close')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default CCMTaskCompleteModal;
