import {
  DayOfWeek,
  Language,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import {
  Address,
  BaseEnum,
  CareTeamCandidate,
  CareTeamContactInfo,
  CareTeamMember,
  CareTeamMemberAide,
  CareTeamMemberFamily,
  CareTeamMemberOther,
  CareTeamMemberProvider,
  CareTeamMemberType,
  CareTeamPerson,
  CareTeamPersonExtended,
  MemberContactInfoTimes,
  Organization,
  UserPhone,
} from '@vestahealthcare/common/models';
import { LOCAL_TIME_FORMAT } from '@vestahealthcare/common/utils/constants';

import { LanguagePreference } from 'dash/src/components/EditLanguages';
import Api, {
  PaginatedResponse,
  PaginationParams,
} from 'dash/src/services/Api';

export interface GetCareTeamPersonsParams extends PaginationParams {
  active?: boolean;
  contactMethods?: MemberContactMethodType[];
  count?: boolean;
  engagementOwnerId?: number[];
  extended?: boolean;
  firstName?: string;
  fullName?: string;
  id?: number[];
  language?: Language[];
  lastName?: string;
  linkedMembers?: boolean;
  phoneNumber?: string;
  preferredDays?: DayOfWeek[];
  preferredTime?: MemberContactTimeType[];
  sort?: string;
  statusId?: string[];
  typeId?: string[];
}

export const fetchCareTeamPeople = async ({
  contactMethods,
  language,
  preferredDays,
  preferredTime,
  sort,
  ...params
}: GetCareTeamPersonsParams): Promise<PaginatedResponse<CareTeamPerson>> => {
  const {
    careTeamPersons: { items, pagination },
  } = await Api.getv2('/care-team/care-team-persons', {
    sort: sort || 'fullName asc',
    contactMethods: contactMethods?.map((item) => item.value),
    language: language?.map((item) => item.value),
    preferredDays: preferredDays?.map((item) => item.value),
    preferredTime: preferredTime?.map((item) => item.value),
    ...params,
  });

  return {
    pagination,
    items: items?.map((x: any) =>
      params?.extended ? new CareTeamPersonExtended(x) : new CareTeamPerson(x),
    ),
  };
};

export const fetchCareTeamPerson = async (
  id: number,
): Promise<CareTeamPersonExtended> => {
  const { careTeamPerson } = await Api.getv2(
    `/care-team/care-team-persons/${id}`,
  );

  return new CareTeamPersonExtended(careTeamPerson);
};

export const fetchMemberCareTeamMembers = async (
  memberId: number,
): Promise<PaginatedResponse<CareTeamMember>> => {
  const {
    careTeamMembers: { items, pagination },
  } = await Api.getv2(`/care-team/care-team-members/${memberId}`);

  return {
    pagination,
    items: items.map((item: any) => {
      if (item.type.id === CareTeamMemberType.FAMILY_FRIEND) {
        return new CareTeamMemberFamily(item);
      }
      if (item.type.id === CareTeamMemberType.AIDE) {
        return new CareTeamMemberAide(item);
      }
      if (item.type.id === CareTeamMemberType.PROVIDER) {
        return new CareTeamMemberProvider(item);
      }
      if (item.type.id === CareTeamMemberType.OTHER) {
        return new CareTeamMemberOther(item);
      }
      return new CareTeamMember(item);
    }),
  };
};

export const fecthCareTeamMemberTypes = async (): Promise<BaseEnum[]> => {
  const {
    types: { items },
  } = await Api.getv2('/care-team/care-team-members/types');
  return items?.map((x: any) => new BaseEnum(x));
};

export const fecthCareTeamMemberFamilyRelationShips = async (): Promise<
  BaseEnum[]
> => {
  const {
    relationships: { items },
  } = await Api.getv2('/care-team/care-team-members/family/relationships');
  return items?.map((x: any) => new BaseEnum(x));
};

export const fecthCareTeamMemberAideTypes = async (): Promise<BaseEnum[]> => {
  const {
    types: { items },
  } = await Api.getv2('/care-team/care-team-members/aide/types');
  return items?.map((x: any) => new BaseEnum(x));
};

export const fecthCareTeamMemberProviderSpecialities = async (): Promise<
  BaseEnum[]
> => {
  const {
    specialityTypes: { items },
  } = await Api.getv2('/care-team/care-team-members/provider/speciality-types');
  return items?.map((x: any) => new BaseEnum(x));
};

export const fecthCareTeamMemberOtherRelationships = async (): Promise<
  BaseEnum[]
> => {
  const {
    relationships: { items },
  } = await Api.getv2('/care-team/care-team-members/other/relationships');
  return items?.map((x: any) => new BaseEnum(x));
};

export type PostQuickAddParams = {
  aideTypeId: BaseEnum;
  campaignEnabled: boolean;
  careTeamCandidateId?: number;
  careTeamPersonId?: number;
  emergencyContact: boolean;
  firstName: string;
  hciNotifications: boolean;
  healthCareProxy: boolean;
  healthCareProxyFileLocation: string;
  lastName: string;
  memberId: number;
  notes?: string;
  otherRelationshipId?: BaseEnum;
  phiAccess: boolean;
  phiExceptions: boolean;
  phones: UserPhone[];
  preferred: boolean;
  relationshipId?: BaseEnum;
  relationshipOther?: string;
  specialityIds?: BaseEnum[];
  specialityOther?: string;
  type: BaseEnum;
};

const OTHER = 'OTHER';

export const quickAddCareTeam = async ({
  aideTypeId,
  otherRelationshipId,
  phones,
  relationshipId,
  specialityIds,
  specialityOther,
  type,
  ...rest
}: PostQuickAddParams) => {
  const { careTeamMember } = await Api.postv2JSON(
    `/care-team/quick-add/${type.id}`,
    {
      aideTypeId: aideTypeId?.id,
      otherRelationshipId: otherRelationshipId?.id,
      phones: phones?.map(({ id, phone: { type, number }, primary }) => ({
        id: id || undefined,
        type: type?.valueOf(),
        number,
        primary: !!primary,
      })),
      relationshipId: relationshipId?.id,
      specialityIds: specialityIds?.map((item) => ({
        specialityType: item.id,
        otherSpeciality:
          item.id === OTHER ? specialityOther || undefined : undefined,
      })),
      typeId: type.id,
      ...rest,
    },
  );
  return new CareTeamMember(careTeamMember);
};

export const fecthCareTeamPersonStatuses = async (): Promise<BaseEnum[]> => {
  const {
    statuses: { items },
  } = await Api.getv2('/care-team/care-team-persons/statuses');
  return items?.map((x: any) => new BaseEnum(x));
};

export type UpdateCareTeamPersonParams = {
  authenticationPhone?: string | null;
  contactInfo?: Partial<CareTeamContactInfo>;
  address?: Partial<Address> | null;
  appUser?: boolean;
  email?: string | null;
  engagementOwnerId?: number | null;
  firstName?: string;
  languages?: LanguagePreference[] | null;
  lastName?: string;
  phones?: UserPhone[] | null;
  statusId?: string;
  textConsentVerbal?: boolean;
  textConsentWritten?: boolean;
  verifiedAddress?: boolean;
};

export const updateCareTeamPerson = async (
  id: number,
  {
    address,
    contactInfo,
    languages,
    phones,
    ...rest
  }: UpdateCareTeamPersonParams,
): Promise<CareTeamPerson> => {
  const { careTeamPerson } = await Api.patchv2JSON(
    `/care-team/care-team-persons/${id}`,
    {
      address: address
        ? {
            ...address,
            state: address.state?.value,
          }
        : address,
      contactInfo:
        contactInfo && Object.keys(contactInfo || {})?.length
          ? {
              contactFrequency: contactInfo.contactFrequency?.value || null,
              contactMethods:
                (contactInfo.contactMethods || [])?.map(
                  ({ contactMethod, contactMethodOther }) => ({
                    contactMethod: contactMethod.value,
                    contactMethodOther,
                  }),
                ) || [],
              contactTimes: (contactInfo.contactTimes || [])?.map(
                ({ id, type, dayOfWeek, startTime, endTime }) => ({
                  id,
                  type: type?.value,
                  dayOfWeek: dayOfWeek?.value,
                  startTime: startTime?.isValid()
                    ? startTime.format(LOCAL_TIME_FORMAT)
                    : undefined,
                  endTime: endTime?.isValid()
                    ? endTime.format(LOCAL_TIME_FORMAT)
                    : undefined,
                }),
              ),
            }
          : undefined,
      languages:
        languages !== null
          ? languages
              ?.map(({ preferred, language }) => ({
                preferred,
                language: language?.value,
              }))
              .filter(Boolean)
          : null,
      phones:
        phones !== null
          ? phones?.map(({ id, phone: { number, type }, primary }) => ({
              id: id || undefined,
              number,
              type: type?.value,
              primary,
            }))
          : null,
      ...rest,
    },
    { showToast: true },
  );
  return new CareTeamPerson(careTeamPerson);
};

type CreateCareTeamMemberCommonParams = {
  campaignEnabled: boolean;
  careTeamPersonId: number;
  emergencyContact: boolean;
  hciNotifications: boolean;
  healthCareProxy: boolean;
  healthCareProxyFileLocation?: string;
  memberId: number;
  notes?: string;
  phiAccess: boolean;
  phiExceptions: boolean;
  preferred: boolean;
  type: BaseEnum;
};
export interface CreateCareTeamMemberFamilyParams
  extends CreateCareTeamMemberCommonParams {
  relationshipId: BaseEnum;
  paid?: boolean;
  livesWithMember?: boolean;
}
export interface CreateCareTeamMemberAideParams
  extends CreateCareTeamMemberCommonParams {
  agency?: Organization;
  aideTimes: MemberContactInfoTimes[];
  aideTypeId: BaseEnum;
  externalId?: string;
  otherFaxNumber?: string | null;
  otherNotes?: string | null;
  otherOrganizationName?: string | null;
  otherPhoneNumber?: string | null;
  paid: boolean;
  temp?: boolean;
  tempUntil?: string | null;
}
export interface CreateCareTeamMemberProviderParams
  extends CreateCareTeamMemberCommonParams {
  specialityIds: BaseEnum[];
  specialityOther?: string;
  practiceName?: string;
  providerNotes?: string;
}
export interface CreateCareTeamMemberOtherParams
  extends CreateCareTeamMemberCommonParams {
  otherRelationshipId: BaseEnum;
  otherOrganizationName?: string;
  relationshipOther?: string;
}

export type CreateCareTeamMemberParams =
  | CreateCareTeamMemberFamilyParams
  | CreateCareTeamMemberAideParams
  | CreateCareTeamMemberProviderParams
  | CreateCareTeamMemberOtherParams;

export type UpdateCareTeamMemberParams = Omit<
  Partial<CreateCareTeamMemberParams>,
  'careTeamPersonId' | 'type' | 'memberId'
>;

export const isCreateCareTeamMemberParams = (
  x: any,
): x is CreateCareTeamMemberParams => !!x.careTeamPersonId;

const isCreateCareTeamMemberFamilyParams = (
  x: any,
): x is CreateCareTeamMemberFamilyParams => !!x.relationshipId;

const isCreateCareTeamMemberAideParams = (
  x: any,
): x is CreateCareTeamMemberAideParams => !!x.aideTypeId;

const isCreateCareTeamMemberProviderParams = (
  x: any,
): x is CreateCareTeamMemberProviderParams => !!x.specialityIds;

const prepareCareTeamMemberParams = (
  params: CreateCareTeamMemberParams | UpdateCareTeamMemberParams,
) => {
  const commonFields: any = {
    campaignEnabled: params.campaignEnabled,
    emergencyContact: params.emergencyContact,
    hciNotifications: params.hciNotifications,
    healthCareProxy: params.healthCareProxy,
    healthCareProxyFileLocation: params.healthCareProxyFileLocation,
    notes: params.notes,
    phiAccess: params.phiAccess,
    phiExceptions: params.phiExceptions,
    preferred: params.preferred,
  };
  let customFields: any = {};

  if (isCreateCareTeamMemberParams(params)) {
    commonFields.careTeamPersonId = params.careTeamPersonId;
    commonFields.memberId = params.memberId;
    commonFields.typeId = params.type.id;
  }

  if (isCreateCareTeamMemberFamilyParams(params)) {
    customFields = {
      relationshipId: params.relationshipId.id,
      paid: params.paid,
      livesWithMember: params.livesWithMember,
    };
  } else if (isCreateCareTeamMemberAideParams(params)) {
    customFields = {
      agencyId: params.agency?.id,
      aideTimes: params.aideTimes?.map(
        ({ id, dayOfWeek, startTime, endTime }) => ({
          id,
          dayOfWeek: dayOfWeek?.value,
          startTime: startTime?.isValid()
            ? startTime.format(LOCAL_TIME_FORMAT)
            : undefined,
          endTime: endTime?.isValid()
            ? endTime.format(LOCAL_TIME_FORMAT)
            : undefined,
        }),
      ),
      aideTypeId: params.aideTypeId.id,
      externalId: params.externalId,
      otherFaxNumber: params.otherFaxNumber,
      otherNotes: params.otherNotes,
      otherOrganizationName: params.otherOrganizationName,
      otherPhoneNumber: params.otherPhoneNumber,
      paid: params.paid,
      temp: params.temp,
      tempUntil: params.tempUntil,
    };
  } else if (isCreateCareTeamMemberProviderParams(params)) {
    customFields = {
      providerNotes:
        params.providerNotes?.length === 0 ? null : params.providerNotes,
      practiceName: params.practiceName,
      specialityIds: params.specialityIds?.map((item) => ({
        specialityType: item.id,
        otherSpeciality: item.id === OTHER ? params.specialityOther : undefined,
      })),
    };
  } else {
    customFields = {
      otherRelationshipId: (params as CreateCareTeamMemberOtherParams)
        ?.otherRelationshipId?.id,
      relationshipOther: (params as CreateCareTeamMemberOtherParams)
        ?.relationshipOther,
      otherOrganizationName: (params as CreateCareTeamMemberOtherParams)
        .otherOrganizationName,
    };
  }

  return {
    ...commonFields,
    ...customFields,
  };
};

export const createCareTeamMember = async (
  type: CareTeamMemberType,
  params: CreateCareTeamMemberParams,
) => {
  const { careTeamMember } = await Api.postv2JSON(
    `/care-team/care-team-members/${type.id}`,
    prepareCareTeamMemberParams(params),
  );

  return new CareTeamMember(careTeamMember);
};

export const updateCareTeamMember = async (
  id: number,
  type: CareTeamMemberType,
  params: UpdateCareTeamMemberParams,
) => {
  const { careTeamMember } = await Api.patchv2JSON(
    `/care-team/care-team-members/${type.id}/${id}`,
    prepareCareTeamMemberParams(params),
  );

  return new CareTeamMember(careTeamMember);
};

export type DeleteCareTeamMemberParams = {
  inactiveReasonId: BaseEnum;
  inactiveReasonOther?: string;
};

export const invalidateCareTeamMember = async (
  id: number,
  { inactiveReasonId, ...params }: DeleteCareTeamMemberParams,
) => {
  const { careTeamMember } = await Api.patchv2JSON(
    `/care-team/care-team-members/${id}/inactivate`,
    {
      inactiveReasonId: inactiveReasonId?.id,
      ...params,
    },
  );

  return new CareTeamMember(careTeamMember);
};

export type ChangeRoleCareTeamMemberParams = {
  type: CareTeamMemberType;
};

export const changeRoleCareTeamMember = async (
  id: number,
  type: BaseEnum,
  params: CreateCareTeamMemberParams,
) => {
  const { careTeamMember } = await Api.postv2JSON(
    `/care-team/care-team-members/${id}/change-role/${type.id}`,
    prepareCareTeamMemberParams(params),
  );

  return new CareTeamMember(careTeamMember);
};

export const fetchInactiveReasons = async () => {
  const {
    inactiveReasons: { items },
  } = await Api.getv2(`/care-team/care-team-members/inactive-reasons`);

  return items.map((x: any) => new BaseEnum(x));
};

export const activateCareTeamMember = async (id: number) => {
  const { careTeamMember } = await Api.patchv2JSON(
    `/care-team/care-team-members/${id}/activate`,
  );

  return new CareTeamMember(careTeamMember);
};

export const sendAppInvite = async (id: number, os: 'android' | 'ios') =>
  await Api.postv2JSON(
    `/care-team/care-team-persons/${id}/actions/app-invite/${os}`,
  );

export const sendTextConsent = async (id: number) =>
  await Api.postv2JSON(
    `/care-team/care-team-persons/${id}/actions/text-consent`,
  );

export type CareTeamCandidatesParams = {
  limit?: number;
  memberId?: number;
};

export const fetchCareTeamCandidates = async (
  params: CareTeamCandidatesParams,
): Promise<CareTeamCandidate[]> => {
  const {
    careTeamCandidates: { items },
  } = await Api.getv2(`/care-team/candidates`, params);

  return items.map((x: any) => new CareTeamCandidate(x));
};

export const deleteCareTeamCandidate = async (id: number) =>
  await Api.deletev2(`/care-team/candidates/${id}`);
