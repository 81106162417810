import moment, { Moment } from 'moment';

import {
  MemberAllergy,
  MemberCarePlan,
  MemberCarePlanRecommendation,
  MemberDiagnostic,
  MemberMedication,
} from '@vestahealthcare/common/models';
import { makeMoment } from '@vestahealthcare/common/models/Model';

import Api from 'dash/src/services/Api';

export type MemberAllergiesResponse = {
  items: MemberAllergy[];
  nkda: boolean;
  updatedAt?: Moment;
};

export const fetchMemberAllergies = async (
  memberId: number | string,
): Promise<MemberAllergiesResponse> => {
  const {
    responseAllergies: { memberAllergies, nkda, updatedAt },
  } = await Api.getv2(`/patients/${memberId}/ecw/allergies`);
  return {
    items: memberAllergies.map((i: any) => new MemberAllergy(i)),
    nkda,
    updatedAt: updatedAt ? moment.unix(updatedAt) : undefined,
  };
};

export type MemberDiagnosticsResponse = {
  items: MemberDiagnostic[];
  nkp: boolean;
  updatedAt?: Moment;
};

export const fetchMemberDiagnostics = async (
  memberId: number | string,
): Promise<MemberDiagnosticsResponse> => {
  const {
    responseDiagnostics: { memberDiagnostics, nkp, updatedAt },
  } = await Api.getv2(`/patients/${memberId}/ecw/diagnostics`);
  return {
    items: memberDiagnostics.map((i: any) => new MemberDiagnostic(i)),
    nkp,
    updatedAt: updatedAt ? moment.unix(updatedAt) : undefined,
  };
};

export type MemberMedicationsResponse = {
  items: MemberMedication[];
  updatedAt?: Moment;
};

export const fetchMemberMedications = async (
  memberId: number | string,
): Promise<MemberMedicationsResponse> => {
  const {
    responseMedications: { memberMedications, updatedAt },
  } = await Api.getv2(`/patients/${memberId}/ecw/medications`);
  return {
    items: memberMedications.map((i: any) => new MemberMedication(i)),
    updatedAt: updatedAt ? moment.unix(updatedAt) : undefined,
  };
};

export type MemberCarePlanResponse = {
  careplans: MemberCarePlan[];
  providerRecommendations?: MemberCarePlanRecommendation;
  rnRecommendations?: MemberCarePlanRecommendation;
  updatedAt?: Moment;
  updatedBy?: string;
};

export const fetchMemberCarePlan = async (
  memberId: number | string,
): Promise<MemberCarePlanResponse> => {
  const { responseCarePlanDetails } = await Api.getv2(
    `/patients/${memberId}/ecw/care-plan-details`,
    {},
    {
      omitModal: true,
    },
  );

  const {
    problems,
    providerRecommendations,
    rnRecommendations,
    updatedAt,
    updatedBy,
  } = responseCarePlanDetails;
  return {
    careplans: (problems || []).map((i: any) => new MemberCarePlan(i)),
    providerRecommendations: providerRecommendations
      ? new MemberCarePlanRecommendation(providerRecommendations)
      : undefined,
    rnRecommendations: rnRecommendations
      ? new MemberCarePlanRecommendation(rnRecommendations)
      : undefined,
    updatedAt: makeMoment(updatedAt) || undefined,
    updatedBy,
  };
};
