import classNames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { makeStyles } from '@mui/styles';
import {
  PieChart as MPieChart,
  pieArcLabelClasses,
} from '@mui/x-charts/PieChart';
import { MakeOptional } from '@mui/x-charts/internals';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';

import { Colors } from '../../styles/Colors';
import { Fonts } from '../../styles/Variables';

type Data = PieValueType;

type ColorType = 'default' | 'status';
type SizeType = 'xs' | 's' | 'm' | 'l' | 'xl';
type TypeOption = 'contained' | 'outlined';

const SIZES: { [size in SizeType]: number } = {
  xs: 100,
  s: 150,
  m: 200,
  l: 250,
  xl: 300,
};

type Props = {
  color?: ColorType;
  className?: string;
  'data-cy'?: string;
  data: Data[];
  getTooltipValue?: (
    data: Partial<PieValueType>,
    idx: number,
    percentage: number,
  ) => string;
  showHighlight?: boolean;
  showLegend?: boolean;
  showPercentage?: boolean;
  size?: SizeType;
  type?: TypeOption;
};

const COLORS_STATUS = [
  Colors.iconGreen,
  Colors.gold,
  Colors.error,
  Colors.textRed2,
  Colors.purple,
];

const useStyles = makeStyles({
  container: {
    margin: '0.5rem',
  },
  noLegendExtraSmall: {
    width: SIZES.xs,
    overflow: 'hidden',
  },
  noLegendSmall: {
    width: SIZES.s,
    overflow: 'hidden',
  },
  noLegendMedium: {
    width: SIZES.m,
    overflow: 'hidden',
  },
  noLegendLarge: {
    width: SIZES.l,
    overflow: 'hidden',
  },
  noLegendExtraLarge: {
    width: SIZES.xl,
    overflow: 'hidden',
  },
});

export const PieChart = ({
  className,
  color = 'default',
  data,
  'data-cy': dataCy,
  getTooltipValue,
  showHighlight,
  showLegend,
  showPercentage,
  size = 'm',
  type = 'contained',
}: Props) => {
  const styles = useStyles();
  const showPercentageCalc = showPercentage && type === 'contained';
  const total = useMemo(
    () => data.reduce((acc, { value }) => acc + (value || 0), 0),
    [data],
  );

  const legendSize = useMemo(
    () =>
      showLegend
        ? data.reduce((acc, { label }) => {
            const calcSize = (label?.length || 0) * 5.25;
            return acc < calcSize ? calcSize : acc;
          }, 0) + 70
        : 95,
    [showPercentageCalc, data],
  );

  const showPercentageProps: Omit<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type' | 'data'
  > = {
    arcLabel: (item: Data) => `${Math.round((100 * item.value) / total)}%`,
    arcLabelMinAngle: 35,
    arcLabelRadius: '67%',
  };

  const showHighlightProps: Omit<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type' | 'data'
  > = {
    highlightScope: { fade: 'global', highlight: 'item' },
    faded: { additionalRadius: -SIZES[size] / 10, color: 'gray' },
  };

  const getColors = (c: ColorType) => {
    if (c === 'status') return COLORS_STATUS;
    return undefined;
  };

  return (
    <div
      className={classNames(
        className,
        styles.container,
        !showLegend && size === 'xs' && styles.noLegendExtraSmall,
        !showLegend && size === 's' && styles.noLegendSmall,
        !showLegend && size === 'm' && styles.noLegendMedium,
        !showLegend && size === 'l' && styles.noLegendLarge,
        !showLegend && size === 'xl' && styles.noLegendExtraLarge,
      )}
    >
      <MPieChart
        colors={getColors(color)}
        data-cy={dataCy}
        series={[
          {
            cornerRadius: size === 'xs' ? 5 : 10,
            cx: SIZES[size] / 2 - 5,
            cy: SIZES[size] / 2 - 5,
            innerRadius:
              type === 'contained' ? SIZES[size] / 6 : SIZES[size] / 3.5,
            outerRadius: SIZES[size] / 2,
            paddingAngle: 1,
            ...(showPercentageCalc ? showPercentageProps : {}),
            ...(showHighlight ? showHighlightProps : {}),
            data,
            valueFormatter: getTooltipValue
              ? (v, { dataIndex: idx }) =>
                  getTooltipValue(v, idx, Math.round((100 * v.value) / total))
              : (v) => v.value?.toString(),
          },
        ]}
        slotProps={{
          legend: { direction: 'column', hidden: !showLegend },
        }}
        sx={{
          ...(showPercentageCalc
            ? {
                [`& .${pieArcLabelClasses.root}`]: {
                  fontSize: size === 'xs' ? 12 : undefined,
                  fill: Colors.textWhite,
                  fontFamily: Fonts.fontFamily,
                  fontWeight: size === 'xs' ? 600 : 500,
                },
              }
            : {}),
        }}
        width={SIZES[size] + legendSize}
        height={SIZES[size]}
      />
    </div>
  );
};
