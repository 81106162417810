import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { EmployeeRole, States } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, PaginationType } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, IconButton, Table } from 'styleguide-v2';

import LoginServices from 'dash/src/services/LoginServices';
import Session from 'dash/src/services/SessionServices';

interface Props {
  employees: Employee[];
  nlcStates: States[];
  onEdit: (emplooyee: Employee) => void;
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (pageSize: number) => void;
}

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    gap: '0.5rem',
  },
  boolean: {
    verticalAlign: 'middle',
  },
  icon: {
    '&&': {
      color: Colors.iconGreen,
      padding: 0,
    },
  },
  subtitle: {
    color: Colors.gray,
  },
});

const renderBoolean = (active: boolean, className: string) =>
  active ? (
    <CheckCircleIcon className={className} color="primary" />
  ) : (
    <CancelIcon className={className} color="error" />
  );

const EMPLOYEES_COLUMNS = (
  { nlcStates, onEdit }: Props,
  styles: ClassNameMap<'icon' | 'actions' | 'boolean' | 'subtitle'>,
) => {
  const { actingUser } = Session;
  const isAdmin = actingUser.role === EmployeeRole.ADMIN;
  const canEditEmployee =
    isAdmin ||
    actingUser.isInLicensureAdministration ||
    actingUser.isPodManager;

  const columns = [
    {
      headerName: translate('employees.table.id'),
      field: 'id',
      width: 50,
    },
    {
      headerName: translate('employees.table.name'),
      component: ({ email, fullName }: Employee) => (
        <>
          <p>{fullName}</p>
          <p className={styles.subtitle}>{email}</p>
        </>
      ),
      width: 220,
    },
    {
      headerName: translate('employees.table.groups'),
      component: ({ activeGroups }: Employee) =>
        activeGroups?.map(({ name }) => name)?.join(', ') || EMPTY,
      width: 200,
    },
    {
      headerName: translate('employees.table.role'),
      component: ({ role }: Employee) => role.toString() || EMPTY,
      width: 200,
    },
    {
      headerName: translate('employees.table.permission'),
      component: ({ dashPermissions }: Employee) =>
        dashPermissions
          ?.map(({ name }) =>
            `${name.charAt(0).toLocaleUpperCase()}${name
              .substring(1)
              .toLocaleLowerCase()}`.replace('_', ' '),
          )
          ?.join(', ') || EMPTY,
      width: 100,
    },
    {
      headerName: translate('employees.table.phiAccess'),
      component: ({ phiAccess }: Employee) =>
        renderBoolean(phiAccess, styles.boolean),
      width: 50,
    },
    {
      headerName: translate('employees.table.lead'),
      component: ({ lead }: Employee) => renderBoolean(lead, styles.boolean),
      width: 50,
    },
    {
      headerName: translate('employees.table.incidents'),
      component: ({ incidentReviewRequired }: Employee) =>
        renderBoolean(incidentReviewRequired, styles.boolean),
      width: 50,
    },
    {
      headerName: translate('employees.table.state'),
      component: ({ licences, nlc }: Employee) =>
        nlc || licences?.length ? (
          <>
            {nlc && <b>NLC</b>}
            {nlc && !!licences?.length && ', '}
            {licences
              ?.filter((state) => !nlc || !nlcStates.includes(state))
              ?.map((item) => item?.toString())
              ?.join(', ')}
          </>
        ) : (
          EMPTY
        ),
    },
    {
      headerName: translate('employees.table.language'),
      component: ({ languages }: Employee) =>
        languages?.length
          ? languages
              .map(String)
              .sort((a, b) => a.localeCompare(b))
              .join(', ')
          : EMPTY,
    },
    {
      headerName: translate('employees.table.active'),
      component: ({ enabled }: Employee) =>
        renderBoolean(enabled, styles.boolean),
      width: 70,
    },
  ];
  if (canEditEmployee) {
    columns.push({
      headerName: '',
      component: (e: Employee) => (
        <div className={styles.actions}>
          <IconButton
            className={styles.icon}
            onClick={() => onEdit(e)}
            size="small"
            tooltip={translate('global.edit')}
          >
            <EditIcon />
          </IconButton>
          {isAdmin && actingUser.isAdmin && !e.isAdmin && e.enabled && (
            <IconButton
              className={styles.icon}
              onClick={() => LoginServices.impersonate(e.id)}
              size="small"
              tooltip={translate('employees.table.impersonate')}
            >
              <i className="fa fa-user-secret" />
            </IconButton>
          )}
        </div>
      ),
      width: 70,
    });
  }
  return columns;
};

export const EmployeesTable = (props: Props) => {
  const { employees, pagination, onChangePage, onChangeRowsPerPage } = props;
  const styles = useStyles();

  return (
    <Table
      config={{
        columns: EMPLOYEES_COLUMNS(props, styles),
        data: employees,
        pagination,
      }}
      fontSize="small"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
};

export default EmployeesTable;
