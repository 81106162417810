import moment, { Moment } from 'moment';

import { MemberDashboardAction } from './MemberDashboardAction';
import { MemberDashboardAppointmentDetails } from './MemberDashboardAppointmentDetails';
import { MemberDashboardBHI } from './MemberDashboardBHI';
import { MemberDashboardBiannual } from './MemberDashboardBiannual';
import { MemberDashboardCCM } from './MemberDashboardCCM';
import { MemberDashboardEncounter } from './MemberDashboardEncounter';
import { MemberDashboardKeyContact } from './MemberDashboardKeyContact';
import { MemberDashboardPharmacy } from './MemberDashboardPharmacy';
import { MemberDashboardRPM } from './MemberDashboardRPM';
import { make, makeArray } from './Model';

export class MemberDashboard {
  actions: MemberDashboardAction[];

  actionsLastUpdatedAt: Moment;

  appointmentDetails: MemberDashboardAppointmentDetails;

  bhiDetails: MemberDashboardBHI;

  biannualDetails: MemberDashboardBiannual;

  ccmDetails: MemberDashboardCCM;

  encounters: MemberDashboardEncounter[];

  keyContacts: MemberDashboardKeyContact[];

  openActions = 0;

  openEvents = 0;

  openTasks = 0;

  pharmacy: MemberDashboardPharmacy;

  rpmDetails: MemberDashboardRPM;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.actions = makeArray(obj.actions, MemberDashboardAction);
    this.actionsLastUpdatedAt = moment.unix(obj.actionsLastUpdatedAt);
    this.appointmentDetails = make(
      obj.appointmentDetails,
      MemberDashboardAppointmentDetails,
    );
    this.bhiDetails = make(obj.bhiDetails, MemberDashboardBHI);
    this.biannualDetails = make(obj.biannualDetails, MemberDashboardBiannual);
    this.ccmDetails = make(obj.ccmDetails, MemberDashboardCCM);
    this.encounters = makeArray(obj.encounters, MemberDashboardEncounter);
    this.keyContacts = makeArray(obj.keyContacts, MemberDashboardKeyContact);
    this.pharmacy = make(obj.pharmacy, MemberDashboardPharmacy);
    this.rpmDetails = make(obj.rpmDetails, MemberDashboardRPM);
  }
}

export default MemberDashboard;
