import moment, { Moment } from 'moment';

import { ProgramExtensionStatusReason } from '../enums/ProgramExtensionStatusReason';
import { DATE_FORMAT } from '../utils/constants';
import { make } from './Model';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class MemberDashboardCCM {
  programExtensionStatus: ProgramExtensionStatus;

  programExtensionStatusReason?: ProgramExtensionStatusReason;

  programExtensionStatusReasonOther = '';

  ccmTotalMinutes = 0;

  ccmProfessionalMinutes = 0;

  lastCompletedDate?: Moment;

  lastCompletedEmployee = '';

  lastCarePlanDate?: Moment;

  lastCarePlanEmployee = '';

  worklistGroup = '';

  callCadenceGroup = '';

  hasValidCCM = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.programExtensionStatus = make(
      obj.programExtensionStatus,
      ProgramExtensionStatus,
    );
    this.programExtensionStatusReason = obj.programExtensionStatusReason
      ? ProgramExtensionStatusReason.byKey[obj.programExtensionStatusReason]
      : undefined;
    this.lastCompletedDate = obj.lastCompletedDate
      ? moment(obj.lastCompletedDate, DATE_FORMAT)
      : undefined;
    this.lastCarePlanDate = obj.lastCarePlanDate
      ? moment(obj.lastCarePlanDate, DATE_FORMAT)
      : undefined;
  }
}

export default MemberDashboardCCM;
