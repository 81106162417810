import React, { Fragment } from 'react';

import { makeStyles } from '@mui/styles';

import { ProgramExtensionStatusReason } from '@vestahealthcare/common/enums';
import {
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { MemberProgramExtension } from '@vestahealthcare/common/models/MemberProgramExtension';

import { Colors } from 'styleguide-v2/src/styles/Colors';

import { Tooltip } from '../Tooltip';

interface Props {
  className?: string;
  'data-cy'?: string;
  hideTooltips?: boolean;
  memberId?: number;
  programExtensions?: MemberProgramExtension[];
}

const useStyles = makeStyles({
  info: { color: Colors.blue },
  action: { color: Colors.textGray },
  secondary: { color: Colors.green },
  primary: { color: Colors.orange },
  error: { color: 'red' },
  warning: { color: Colors.gold },
});

export const MemberProgramExtensions = ({
  className,
  'data-cy': dataCy,
  hideTooltips,
  memberId,
  programExtensions,
}: Props) => {
  const styles = useStyles();

  const getProgramExtension = (
    pe: ProgramExtension,
    status?: ProgramExtensionStatus,
  ) => (
    <span className={styles[ProgramExtensionStatus.getColorStatus(status)]}>
      {pe.abbr.toString()}
    </span>
  );

  const getProgramExtensionTooltip = (pe: MemberProgramExtension) =>
    `${pe.status?.description}${
      pe.status?.id === ProgramExtensionStatus.HOLD
        ? ` (${
            pe.statusReason === ProgramExtensionStatusReason.OTHER &&
            pe.statusReasonOther
              ? pe.statusReasonOther
              : pe.statusReason?.toString()
          })`
        : ''
    }`;

  return (
    <div className={className} data-cy={dataCy}>
      {programExtensions
        ?.sort(
          (
            { programExtension: { abbr: a } },
            { programExtension: { abbr: b } },
          ) => a.localeCompare(b),
        )
        ?.map((item, index) => (
          <Fragment
            key={`program-extension-index-${index}-member-${
              memberId || 'unknown'
            }`}
          >
            {hideTooltips ? (
              getProgramExtension(item.programExtension, item.status)
            ) : (
              <Tooltip text={getProgramExtensionTooltip(item)}>
                {getProgramExtension(item.programExtension, item.status)}
              </Tooltip>
            )}
            {index + 1 < programExtensions.length && ', '}
          </Fragment>
        ))}
    </div>
  );
};

export default MemberProgramExtensions;
