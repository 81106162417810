// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-preview {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.95);
  overflow: auto;
}
.pdf-preview .pdf-content {
  justify-content: center;
  flex-direction: column;
}
.pdf-preview .pdf-content canvas {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.pdf-preview .pdf-content .react-pdf__Page__annotations {
  display: none;
}
.pdf-preview .pdf-pagination {
  width: 100%;
  position: fixed;
  bottom: 2em;
  left: 0;
}
.pdf-preview .pdf-close {
  position: fixed;
  top: 2em;
  right: 2em;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/PDFPreview/main.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,aAAA;EACA,qCAAA;EACA,cAAA;AACF;AAbA;EAeI,uBAAA;EACA,sBAAA;AACJ;AAjBA;EAkBM,2CAAA;AAEN;AApBA;EAqBM,aAAA;AAEN;AAvBA;EA0BI,WAAA;EACA,eAAA;EACA,WAAA;EACA,OAAA;AAAJ;AA7BA;EAiCI,eAAA;EACA,QAAA;EACA,UAAA;AADJ","sourcesContent":[".pdf-preview {\n  position: fixed;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  text-align: center;\n  z-index: 2000;\n  background: rgba(255, 255, 255, 0.95);\n  overflow: auto;\n\n  .pdf-content {\n    justify-content: center;\n    flex-direction: column;\n    canvas {\n      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);\n    }\n    .react-pdf__Page__annotations {\n      display: none;\n    }\n  }\n\n  .pdf-pagination {\n    width: 100%;\n    position: fixed;\n    bottom: 2em;\n    left: 0;\n  }\n\n  .pdf-close {\n    position: fixed;\n    top: 2em;\n    right: 2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
