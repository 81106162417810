import moment, { Moment } from 'moment';

export const getNumericQueryParam = (
  query: URLSearchParams,
  param: string,
): number | undefined => {
  const val = query.get(param);
  return val ? Number.parseInt(val, 10) : undefined;
};

export const roundToNearest30 = (now: Moment) => {
  const remainder = 30 - (now.minute() % 30);
  return now.add(remainder, 'minutes');
};

export const getDueDate = (date?: Moment, time?: Moment) => {
  if (!date) return undefined;

  return moment(
    new Date(
      date.year(),
      date.month(),
      date.date(),
      time?.hour() || 0,
      time?.minute() || 0,
    ),
  );
};
