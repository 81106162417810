import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ChannelList, ChannelPreviewUIComponentProps } from 'stream-chat-react';

import { makeStyles } from '@material-ui/styles';

import { Button, TextInput } from 'styleguide';

import { VestaChatContext } from 'dash/src/contexts/VestaChatContext';
import { debounce } from 'lodash';

import { ChatFilters } from '.';
import { useQuery } from '..';
import MessagePreview from './MessagePreview';
import { ChannelFilters } from 'stream-chat';

const SEARCH_WAIT = 300;

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
  },
  unreadIndicator: {
    marginLeft: 2,
    height: 8,
    width: 8,
    backgroundColor: 'red',
    borderRadius: 50,
  },
});

interface HeaderProps {
  filters: ChatFilters;
  refresh: number;
}

const ChannelListHeader = ({ filters, refresh }: HeaderProps) => {
  const query = useQuery();
  const history = useHistory();
  const styles = useStyles();

  const { memberId, name, closed } = filters;
  const isClosed = closed?.$eq;
  const { unreadCount } = useContext(VestaChatContext);
  const unreadCloseCount =
    (unreadCount?.total || 0) - (unreadCount?.opened || 0);
  const queryName = query.get('name') || undefined;

  const renderClosedFilterButtons = () => (
    <>
      <Button
        color={isClosed ? 'secondary' : 'primary'}
        href={`/#/chats${memberId ? `?memberId=${memberId}` : ''}`}
      >
        <span className={styles.btnContainer}>
          Opened {!!unreadCount?.opened && <>({unreadCount?.opened})</>}
          {!!unreadCount?.opened && <div className={styles.unreadIndicator} />}
        </span>
      </Button>
      <Button
        color={isClosed ? 'primary' : 'secondary'}
        href={`/#/chats?closed=true${memberId ? `&memberId=${memberId}` : ''}`}
      >
        <span className={styles.btnContainer}>
          Closed {!!unreadCloseCount && <>({unreadCloseCount})</>}
          {!!unreadCloseCount && <div className={styles.unreadIndicator} />}
        </span>
      </Button>
    </>
  );

  const search = (val: string | undefined) => {
    if (val && val.trim().length > 0) {
      query.set('name', val);
    } else if (queryName) {
      query.delete('name');
    }

    history.push(`/chats?${query.toString()}`);
  };

  const clearName = () => {
    if (queryName) {
      query.delete('name');
      history.push(`/chats?${query.toString()}`);
    }
  };

  const memoizedChannels = useMemo(
    () => (
      <ChannelList
        key={`channel-list-${refresh}`}
        // cast because ChannelFilters requires using Stream's type PrimitiveFilter,
        // which is not as helpful to us as actually using the primitives
        filters={filters as ChannelFilters}
        sort={{ last_updated: -1, has_unread: -1 }}
        Preview={
          MessagePreview as React.ComponentType<
            ChannelPreviewUIComponentProps<any>
          >
        }
      />
    ),
    [memberId, name, closed, refresh],
  );

  return (
    <div className="vesta-channel-list">
      {!memberId && (
        <div className="filter-buttons">{renderClosedFilterButtons()}</div>
      )}
      <div className="filter-search">
        <TextInput
          onChange={debounce(search, SEARCH_WAIT)}
          placeholder="Search name"
          value={queryName}
        />
        {queryName && <i className="fa fa-times" onClick={clearName} />}
      </div>
      {memoizedChannels}
    </div>
  );
};

export default ChannelListHeader;
