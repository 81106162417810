// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ht-nav-controls .patient-search-textbox {
  min-width: 275px;
  position: relative;
}
#ht-nav-controls .patient-search-textbox .form-group {
  margin: 0;
}
#ht-nav-controls .patient-search-textbox .form-group .form-control-feedback {
  height: 38px;
  width: 38px;
  line-height: 38px !important;
}
#ht-nav-controls .patient-search-textbox input.form-control {
  padding: 0px 12px;
  height: 38px;
  min-height: 38px;
  border: none;
  border-radius: 0;
  background-color: #ffffff;
}
#ht-nav-controls .patient-search-textbox input.form-control::-webkit-input-placeholder {
  color: #444444;
}
#patient-search-popover {
  user-select: none;
  min-width: 275px;
  max-height: 500px;
  overflow-y: auto;
  position: absolute;
  top: 36px;
}
#patient-search-popover h4 {
  margin-top: 0;
}
#patient-search-popover .popover-content {
  padding: 20px;
}
#patient-search-popover .arrow {
  display: none;
}
#patient-search-popover .patient-search-list-item {
  cursor: pointer;
}
#patient-search-popover .patient-search-list-item:not(:last-child) {
  padding-bottom: 7px;
}
#patient-search-popover .patient-search-list-item:hover,
#patient-search-popover .patient-search-list-item.selected-item {
  background-color: #d8f2ec;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/PatientSearch/main.less"],"names":[],"mappings":"AAEA;EAEI,gBAAA;EACA,kBAAA;AAFJ;AADA;EAMM,SAAA;AAFN;AAJA;EASQ,YAAA;EACA,WAAA;EACA,4BAAA;AAFR;AATA;EAgBM,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;AAJN;AAMM;EACE,cAAA;AAJR;AAUA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;AARF;AAEA;EASI,aAAA;AARJ;AADA;EAaI,aAAA;AATJ;AAJA;EAiBI,aAAA;AAVJ;AAPA;EAqBI,eAAA;AAXJ;AAaI;EACE,mBAAA;AAXN;AAcI;;EAEE,yBAAA;AAZN","sourcesContent":["@import (reference) '~styleguide/src/styles/variables.less';\n\n#ht-nav-controls {\n  .patient-search-textbox {\n    min-width: 275px;\n    position: relative;\n\n    .form-group {\n      margin: 0;\n\n      .form-control-feedback {\n        height: 38px;\n        width: 38px;\n        line-height: 38px !important; // bootstrap css uses important\n      }\n    }\n\n    input.form-control {\n      padding: 0px 12px;\n      height: 38px;\n      min-height: 38px;\n      border: none;\n      border-radius: 0;\n      background-color: @white;\n\n      &::-webkit-input-placeholder {\n        color: @textBlack;\n      }\n    }\n  }\n}\n\n#patient-search-popover {\n  user-select: none;\n  min-width: 275px;\n  max-height: 500px;\n  overflow-y: auto;\n  position: absolute;\n  top: 36px;\n\n  h4 {\n    margin-top: 0;\n  }\n\n  .popover-content {\n    padding: 20px;\n  }\n\n  .arrow {\n    display: none;\n  }\n\n  .patient-search-list-item {\n    cursor: pointer;\n\n    &:not(:last-child) {\n      padding-bottom: 7px;\n    }\n\n    &:hover,\n    &.selected-item {\n      background-color: @lightMint;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
