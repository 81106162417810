import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { makeStyles } from '@mui/styles';

import { ProgramExtensionStatusReason } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CCMMember,
  MemberDashboardBHI,
  MemberWarning,
  Patient,
  ProgramExtensionStatus,
  RPMMember,
} from '@vestahealthcare/common/models';
import MemberWarningType from '@vestahealthcare/common/models/member-warnings/MemberWarningType';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Card,
  Chip,
  Colors,
  Fonts,
  Summary,
} from 'styleguide-v2';

const MIN_BHI_MINUTES = 20;

type Props = {
  className?: string;
  data?: MemberDashboardBHI;
  member?: Patient | CCMMember | RPMMember;
  onLogCall: () => void;
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },

    '& > div:not(:last-child)': {
      borderRight: `1px solid ${BackgroundColors.grayLine}`,
    },
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  summary: {
    width: '24%',
  },
  title: {
    alignItems: 'center',
    display: 'flex',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const BHICard = ({ className, data, onLogCall, warnings }: Props) => {
  const { showBhi } = useFlags();
  const styles = useStyles();

  const getMinutesColor = () => {
    if (!data?.bhiTotalMinutes || data?.bhiTotalMinutes < MIN_BHI_MINUTES)
      return Colors.iconGray;
    if (data?.bhiTotalMinutes >= MIN_BHI_MINUTES) return Colors.iconGreen;

    return Colors.paleGray;
  };

  const getLastCarePlanColor = () => {
    const hasWarnings = !!warnings?.filter(({ type }) =>
      [
        MemberWarningType.DUE_CARE_PLAN_INITIAL,
        MemberWarningType.DUE_CARE_PLAN_UPDATE,
      ].includes(type.id),
    )?.length;
    if (hasWarnings) return Colors.orange;
    return Colors.iconGreen;
  };

  const getLastCarePlanSubtitle = () => {
    if (data?.lastCarePlanEmployee)
      return <span className="bold">with {data?.lastCarePlanEmployee}</span>;
    return '';
  };

  const getProgramExtensionStatus = ({
    programExtensionStatus: status,
    programExtensionStatusReasonOther: reasonOther,
    programExtensionStatusReason: reason,
  }: MemberDashboardBHI) =>
    `${status?.description}${
      status?.id === ProgramExtensionStatus.HOLD
        ? ` (${
            reason === ProgramExtensionStatusReason.OTHER && reasonOther
              ? reasonOther
              : reason?.toString()
          })`
        : ''
    }`;

  if (
    !showBhi ||
    !data ||
    !data.programExtensionStatus ||
    ![
      ProgramExtensionStatus.ACTIVE,
      ProgramExtensionStatus.DELAYED_ESCALATED,
      ProgramExtensionStatus.DELAYED_TECH_ISSUE,
      ProgramExtensionStatus.DELAYED_UNREACHABLE,
      ProgramExtensionStatus.HOLD,
    ].includes(data.programExtensionStatus?.id)
  )
    return <></>;

  return (
    <>
      <Card className={classNames(className, styles.container)}>
        <div className={styles.subContainer}>
          <div className="flex spaced">
            <span className={styles.title}>
              BHI&nbsp;&nbsp;
              <Chip
                color={ProgramExtensionStatus.getColorStatus(
                  data.programExtensionStatus,
                )}
                type="contained-2"
              >
                <span className="bold">{`BHI: ${getProgramExtensionStatus(
                  data,
                )?.toLocaleUpperCase()}`}</span>
              </Chip>
            </span>
            <a
              className="flex middle min-gap"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onLogCall();
              }}
              style={{ color: Colors.textGreen, fontWeight: 500 }}
            >
              {translate('memberProfile.dashboard.logCall')}{' '}
              <AddIcCallIcon fontSize="small" />
            </a>
          </div>
          <br />
          <div className="flex spaced">
            <Summary
              body={data.bhiTotalMinutes}
              className={styles.summary}
              color={getMinutesColor()}
              footer={translate('memberProfile.dashboard.cumulative')}
              title={translate('memberProfile.dashboard.totalBHIMInutes')}
            />
            <Summary
              body={data.lastCompletedDate?.format('M/D/YY') || EMPTY}
              className={styles.summary}
              color={Colors.mint}
              footer={
                data.lastCompletedEmployee ? (
                  <span className="bold">
                    with {data.lastCompletedEmployee}
                  </span>
                ) : undefined
              }
              title={translate('memberProfile.dashboard.ccmLastCompleted')}
            />
            <Summary
              body={data.lastCarePlanDate?.format('M/D/YY') || EMPTY}
              className={styles.summary}
              color={getLastCarePlanColor()}
              footer={getLastCarePlanSubtitle()}
              title={translate('memberProfile.dashboard.ccmLastCarePlan')}
            />
            <div className={styles.summary} />
          </div>
        </div>
      </Card>
    </>
  );
};
