import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useMemo } from 'react';

import AddCallIcon from '@mui/icons-material/AddIcCall';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { ClassNameMap, makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { CCMTask, PaginationType } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Colors,
  IconButton,
  MemberInfo,
  Table,
  Tooltip,
  UpdatedBy,
} from 'styleguide-v2';

interface Props {
  tasks: CCMTask[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickRow?: (task: CCMTask) => void;
  onComplete: (task: CCMTask) => void;
  onLogCall: (task: CCMTask) => void;
}

const useStyles = makeStyles({
  iconMargin: {
    marginLeft: '0.5rem',
  },
  minutes: {
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontWeight: 500,
    minWidth: '3rem',
    padding: '0.5rem 0.75rem',
    textAlign: 'center',
  },
  warningMinutes: {
    background: BackgroundColors.lightYellow,
  },
  errorMinutes: {
    color: Colors.textRed2,
    background: `${Colors.textRed}33`,
  },
});

const CCM_TASK_COLUMNS = (
  { onClickRow, onComplete, onLogCall }: Props,
  showHouseHoldMembers: boolean,
  styles: ClassNameMap<
    'iconMargin' | 'warningMinutes' | 'errorMinutes' | 'minutes'
  >,
) => [
  {
    headerName: translate('ccmTasks.table.member'),
    component: ({ member }: CCMTask) => (
      <>
        <MemberInfo
          icon={
            member.unreachable ? (
              <Tooltip text={translate('ccmTasks.table.unreachable')}>
                <PhoneDisabledIcon color="error" />
              </Tooltip>
            ) : undefined
          }
          member={member}
          link={onClickRow ? `#/patients/${member?.id}` : undefined}
          showHouseHoldMembers={showHouseHoldMembers}
          showLanguage
          showState
        />
      </>
    ),
  },
  {
    headerName: translate('ccmTasks.table.task'),
    component: ({ taskDate, taskName }: CCMTask) =>
      taskDate ? (
        <UpdatedBy
          user={taskName}
          date={taskDate}
          dateFormat={DATE_FORMAT_SHORT}
        />
      ) : (
        taskName
      ),
  },
  {
    headerName: translate('ccmTasks.table.contactPreference'),
    component: ({ taskId, member }: CCMTask) =>
      member
        .getDaysOfService()
        ?.map(({ dates, startTime, endTime, type }, index) => (
          <p className="no-margin" key={`ccm-task-${taskId}-days-${index}`}>
            {!!dates?.length && (
              <span>
                {DayOfWeek.stringify(dates)}
                {(type || startTime || endTime) && ': '}
              </span>
            )}
            {type && type !== MemberContactTimeType.CUSTOM && (
              <span>{`${type?.toString()} `}</span>
            )}
            {(startTime || endTime) && (
              <span>
                {startTime
                  ? moment(startTime.getTime()).format(TIME_FORMAT)
                  : '*'}
                {' - '}
                {endTime ? moment(endTime.getTime()).format(TIME_FORMAT) : '*'}
              </span>
            )}
          </p>
        )) || EMPTY,
  },
  {
    headerName: translate('ccmTasks.table.numberOfCalls'),
    component: ({ numberOfCalls }: CCMTask) =>
      numberOfCalls !== undefined ? numberOfCalls : EMPTY,
  },
  {
    headerName: translate('ccmTasks.table.validMins'),
    component: ({ ccmValidMinutes }: CCMTask) =>
      ccmValidMinutes ? (
        <Tooltip text={ccmValidMinutes}>
          <CheckCircleIcon className={styles.iconMargin} color="primary" />
        </Tooltip>
      ) : (
        <CancelIcon className={styles.iconMargin} color="error" />
      ),
  },
  {
    headerName: translate('ccmTasks.table.ccmMinMinutesNextCpt'),
    component: ({ minMinutesNextCPT }: CCMTask) => {
      if (minMinutesNextCPT === null)
        return (
          <span className={classNames(styles.errorMinutes, styles.minutes)}>
            MAX
          </span>
        );

      if (!minMinutesNextCPT) return EMPTY;
      return (
        <span className={classNames(styles.warningMinutes, styles.minutes)}>
          {minMinutesNextCPT}
        </span>
      );
    },
  },
  {
    headerName: translate('ccmTasks.table.callback'),
    component: ({ callback }: CCMTask) =>
      callback ? <UpdatedBy date={callback} contrast /> : EMPTY,
  },
  {
    headerName: ' ',
    component: (task: CCMTask) => (
      <div className="flex gap">
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            onComplete(task);
          }}
          tooltip={translate('ccmTasks.table.completeTask')}
        >
          <AddTaskIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            onLogCall(task);
          }}
          tooltip={translate('ccmTasks.table.logCall')}
        >
          <AddCallIcon />
        </IconButton>
      </div>
    ),
    width: 50,
  },
];

export const CCMTasksTable = (props: Props) => {
  const styles = useStyles();
  const { showHouseHoldMembers } = useFlags();

  const columns = useMemo(
    () => CCM_TASK_COLUMNS(props, showHouseHoldMembers, styles),
    [props.onComplete, props.onLogCall],
  );

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.tasks,
        defaultPageSize: 25,
        getRowBrand: ({ member }: CCMTask) => {
          if (member.isVPC()) return 'VPC';
          return member.brand;
        },
        pagination: props.pagination,
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
      onClickRow={props.onClickRow}
    />
  );
};

export default CCMTasksTable;
