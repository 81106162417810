import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { AppointmentStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  MemberDashboardAppointment,
  MemberDashboardAppointmentDetails,
  MemberDashboardBiannual,
  MemberWarning,
  Patient,
} from '@vestahealthcare/common/models';
import MemberWarningType from '@vestahealthcare/common/models/member-warnings/MemberWarningType';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Card,
  Chip,
  Collapse,
  Colors,
  Data,
  Fonts,
  SeeMore,
  StatusIndicator,
} from 'styleguide-v2';

type Props = {
  className?: string;
  data?: MemberDashboardBiannual;
  extraData?: MemberDashboardAppointmentDetails;
  member?: Patient;
  warnings?: MemberWarning[];
};

const useStyles = makeStyles({
  container: {
    '&&': {
      borderRadius: '8px',
    },

    display: 'flex',

    '& > div': {
      flex: 1,
      padding: '2.4rem 3.2rem',
    },
  },
  dataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3rem 0.5rem',

    '& > *': {
      flex: `1 1 calc(50% - 1rem)`,
      minWidth: '180px',
    },
  },
  dataMarginTop: {
    marginTop: '0.5rem',
  },
  dataSeeMore: {
    marginLeft: '-0.625rem',
  },
  dueError: {
    color: Colors.textRed,
  },
  subContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',

    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 500,
      margin: 0,
    },
  },
});

export const NPBiannualCard = ({
  className,
  data,
  extraData,
  warnings,
}: Props) => {
  const styles = useStyles();

  const [futureApptOpen, setFutureApptOpen] = useState<boolean>(false);

  const getNextBiannualSubtitle = () => (
    <>
      {(data?.nextBiannualType || data?.nextBiannualEmployee) && (
        <div className="no-margin">
          {`${data?.nextBiannualType ? `${data?.nextBiannualType}` : ''}${
            data.nextBiannualType && data.nextBiannualEmployee ? ', ' : ''
          }${
            data?.nextBiannualEmployee
              ? `with ${data.nextBiannualEmployee}`
              : ''
          }`}
        </div>
      )}
      {data?.nextBiannualDue && (
        <span
          className={classNames(
            'no-margin',
            data.nextBiannualDue.isBefore(moment()) && styles.dueError,
          )}
        >
          {`Due: ${data.nextBiannualDue.format('MMMM, YYYY')}`}
        </span>
      )}
    </>
  );

  const getAppointmentSublabel = (aapt?: MemberDashboardAppointment) =>
    aapt?.type || aapt?.employee
      ? `${aapt?.type ? `${aapt?.type}` : ''}${
          aapt.type && aapt.employee ? ', ' : ''
        }${aapt?.employee ? `with ${aapt.employee}` : ''}`
      : undefined;

  const firstFutureAppt =
    extraData?.nextAppointments && extraData?.nextAppointments[0];

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={styles.subContainer}>
        <div className="flex spaced">
          <span className={styles.title}>
            {translate('memberProfile.dashboard.npBiannuals')}
            {warnings
              ?.filter(({ type }) =>
                [MemberWarningType.DUE_NP_BIANNUAL].includes(type.id),
              )
              .map(({ type: { id, description } }) => (
                <Fragment key={`member-dashboar-warning-${id}`}>
                  &nbsp; &nbsp;
                  <Chip color="error" type="contained-2">
                    <span className="bold">
                      {description.toLocaleUpperCase()}
                    </span>
                  </Chip>
                </Fragment>
              ))}
          </span>
        </div>
        <br />
        <div className={styles.dataContainer}>
          <Data
            label={translate('memberProfile.dashboard.lastAppointment')}
            value={
              <div className="flex gap wrap">
                <span>
                  {data?.lastBiannualDate?.format(DATE_FORMAT_SHORT) || 'N/A'}
                </span>
                {data?.lastBiannualStatus && (
                  <StatusIndicator
                    status={data.lastBiannualStatus.toString()}
                    color={AppointmentStatus.getColor(
                      data.lastBiannualStatus.toString(),
                    )}
                  />
                )}
              </div>
            }
            subvalue={
              data?.lastBiannualType || data?.lastBiannualEmployee
                ? `${
                    data?.lastBiannualType ? `${data?.lastBiannualType}` : ''
                  }${
                    data.lastBiannualType && data.lastBiannualEmployee
                      ? ', '
                      : ''
                  }${
                    data?.lastBiannualEmployee
                      ? `with ${data.lastBiannualEmployee}`
                      : ''
                  }`
                : undefined
            }
          />
          <div>
            <Data
              label={translate('memberProfile.dashboard.nextAppointment')}
              labelTooltip={
                data?.biannualStructuredData && (
                  <>
                    <p className="no-margin flex spaced gap white">
                      <span>
                        {translate(
                          'memberProfile.dashboard.nextAppointmentScheduleInfo.due',
                        )}
                      </span>

                      <span className="bold">
                        {translate(
                          `global.${
                            data.biannualStructuredData.dueForBiannual
                              ? 'yes'
                              : 'no'
                          }`,
                        )}
                      </span>
                    </p>
                    {data.biannualStructuredData.dueForBiannual && (
                      <p className="no-margin flex spaced gap white">
                        <span>
                          {translate(
                            'memberProfile.dashboard.nextAppointmentScheduleInfo.visitScheduled',
                          )}
                        </span>
                        <span className="bold">
                          {translate(
                            `global.${
                              data.biannualStructuredData.visitScheduled
                                ? 'yes'
                                : 'no'
                            }`,
                          )}
                        </span>
                      </p>
                    )}
                    {data.biannualStructuredData?.dueForBiannual &&
                      (data.biannualStructuredData.visitScheduled ? (
                        <>
                          <p className="no-margin flex spaced gap white">
                            <span>
                              {translate(
                                'memberProfile.dashboard.nextAppointmentScheduleInfo.educationProvided',
                              )}
                            </span>
                            <span className="bold">
                              {data.biannualStructuredData
                                .visitScheduledEducationProvided || EMPTY}
                            </span>
                          </p>
                          <p className="no-margin flex spaced gap white">
                            <span>
                              {translate(
                                'memberProfile.dashboard.nextAppointmentScheduleInfo.hostFullname',
                              )}
                            </span>
                            <span className="bold">
                              {data.biannualStructuredData
                                .visitScheduledVideoVisitHost || EMPTY}
                            </span>
                          </p>
                          <p className="no-margin flex spaced gap white">
                            <span>
                              {translate(
                                'memberProfile.dashboard.nextAppointmentScheduleInfo.connectionTested',
                              )}
                            </span>
                            <span className="bold">
                              {data.biannualStructuredData
                                .visitScheduledConnectionTested || EMPTY}
                            </span>
                          </p>
                        </>
                      ) : (
                        <p className="no-margin flex spaced gap white">
                          <span>
                            {translate(
                              'memberProfile.dashboard.nextAppointmentScheduleInfo.visitNotScheduledReason',
                            )}
                            :
                          </span>
                          <span className="bold">
                            {data.biannualStructuredData
                              .visitNotScheduledReason || EMPTY}
                          </span>
                        </p>
                      ))}
                    <br />
                    <p className="no-margin flex spaced gap white italic">
                      <span>
                        {translate('global.lastUpdatedBy', {
                          date: data.biannualStructuredData.date?.format(
                            DATE_FORMAT_SHORT,
                          ),
                          editor:
                            data.biannualStructuredData.employee?.fullName,
                        })}
                      </span>
                    </p>
                  </>
                )
              }
              value={
                <div className="flex gap wrap middle">
                  <span>
                    {data?.nextBiannualDate?.format(DATE_FORMAT_SHORT) || 'N/A'}
                  </span>
                  {data?.nextBiannualStatus && (
                    <StatusIndicator
                      status={data.nextBiannualStatus.toString()}
                      color={AppointmentStatus.getColor(
                        data.nextBiannualStatus.toString(),
                      )}
                    />
                  )}
                </div>
              }
              subvalue={getNextBiannualSubtitle()}
            />
          </div>
          <Data
            label={translate('memberProfile.dashboard.lastAppointmentAcute')}
            value={
              <>
                <div className="flex gap wrap">
                  <span>
                    {extraData?.lastAcuteAppointment?.date?.format(
                      DATE_FORMAT_SHORT,
                    ) || 'N/A'}
                  </span>
                  {extraData?.lastAcuteAppointment?.status && (
                    <StatusIndicator
                      status={extraData?.lastAcuteAppointment?.status.toString()}
                      color={AppointmentStatus.getColor(
                        extraData?.lastAcuteAppointment?.status.toString(),
                      )}
                    />
                  )}
                </div>
                {!!extraData?.lastAcuteAppointment?.icd10Codes?.length && (
                  <div
                    className="flex min-gap wrap"
                    style={{ minWidth: 'max-content' }}
                  >
                    {extraData.lastAcuteAppointment.icd10Codes?.map((item) => (
                      <Chip
                        key={`icd-code-${item.id}`}
                        color="info"
                        type={item.primary ? 'contained' : 'outlined'}
                        title={item.description}
                        size="small"
                      >
                        {item.id}
                      </Chip>
                    ))}
                  </div>
                )}
              </>
            }
            subvalue={getAppointmentSublabel(extraData?.lastAcuteAppointment)}
          />
          <div>
            <Data
              label={translate('memberProfile.dashboard.futureAppointments')}
              value={
                <div className="flex gap wrap">
                  <span>
                    {firstFutureAppt?.date?.format(DATE_FORMAT_SHORT) || 'N/A'}
                  </span>
                  {firstFutureAppt?.status && (
                    <StatusIndicator
                      status={firstFutureAppt?.status?.toString()}
                      color={AppointmentStatus.getColor(
                        firstFutureAppt?.status?.toString(),
                      )}
                    />
                  )}
                </div>
              }
              subvalue={getAppointmentSublabel(firstFutureAppt)}
            />
            {extraData && extraData?.nextAppointments?.length > 1 && (
              <>
                <Collapse open={futureApptOpen}>
                  {extraData?.nextAppointments?.map((item, idx) =>
                    idx === 0 ? (
                      <></>
                    ) : (
                      <Data
                        className={styles.dataMarginTop}
                        label=""
                        value={
                          <div className="flex gap wrap">
                            <span>
                              {item?.date?.format(DATE_FORMAT_SHORT) || 'N/A'}
                            </span>
                            {item?.status && (
                              <StatusIndicator
                                status={item?.status?.toString()}
                                color={AppointmentStatus.getColor(
                                  item?.status?.toString(),
                                )}
                              />
                            )}
                          </div>
                        }
                        subvalue={getAppointmentSublabel(item)}
                      />
                    ),
                  )}
                </Collapse>
                <SeeMore
                  className={styles.dataSeeMore}
                  count={extraData.nextAppointments.length - 1}
                  onClick={() => setFutureApptOpen(!futureApptOpen)}
                  open={futureApptOpen}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
