import { Enum } from './Enum';

export class DayOfWeek extends Enum {
  translationKey = 'dayOfWeek';

  static asArray: DayOfWeek[] = [];

  static byKey: { [name: string]: DayOfWeek } = {};

  static readonly SUNDAY = new DayOfWeek('SUNDAY', 'Sun', 0);

  static readonly MONDAY = new DayOfWeek('MONDAY', 'Mon', 1);

  static readonly TUESDAY = new DayOfWeek('TUESDAY', 'Tues', 2);

  static readonly WEDNESDAY = new DayOfWeek('WEDNESDAY', 'Wed', 3);

  static readonly THURSDAY = new DayOfWeek('THURSDAY', 'Thurs', 4);

  static readonly FRIDAY = new DayOfWeek('FRIDAY', 'Fri', 5);

  static readonly SATURDAY = new DayOfWeek('SATURDAY', 'Sat', 6);

  constructor(
    public readonly value: string,
    public readonly short: string,
    public readonly order: number,
  ) {
    super();
    DayOfWeek.asArray.push(this);
    DayOfWeek.byKey[value] = this;
  }

  static stringify(days: DayOfWeek[]) {
    const result: DayOfWeek[][] = [];
    let temp: DayOfWeek[] = [];

    const daysSorted = Object.values(days.sort((a, b) => a.order - b.order));
    daysSorted.forEach((ele) => {
      if (
        !temp.length ||
        Math.abs(temp[temp.length - 1].order - ele.order) === 1
      ) {
        temp.push(ele);
      } else {
        result.push(temp);
        temp = [ele];
      }
    });

    result.push(temp);

    const sunIndex = result.findIndex((res) => res.includes(this.SUNDAY));
    const satIndex = result.findIndex((res) => res.includes(this.SATURDAY));
    if (sunIndex > -1 && satIndex > -1 && sunIndex !== satIndex) {
      result[satIndex] = result[satIndex].concat(result[sunIndex]);
      result.splice(sunIndex, 1);
    }
    return result
      .map((res) =>
        res.length === 1
          ? res[0]?.short
          : `${res[0]?.short}-${res[res.length - 1]?.short}`,
      )
      .join(', ');
  }
}

export default DayOfWeek;
