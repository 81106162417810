import React from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient } from '@vestahealthcare/common/models';

import { Colors, Tooltip } from 'styleguide-v2';

type Props = {
  patient: Patient;
};

export const MemberEmail = ({ patient }: Props) => (
  <>
    <h4 className="grid-span-5 grid-span-row-2">
      {translate('personalDetails.email')}
    </h4>
    <div className="grid-span-6 grid-span-row-2" data-cy="member-mail">
      {patient?.email?.notProvided === true ? (
        <p className="no-margin flex gap wrap">
          <span style={{ marginTop: '0.125rem' }}>
            {translate('personalDetails.emailNotProvided')}:
          </span>
          <span style={{ marginTop: '0.125rem' }}>
            {patient.email?.reason?.description}
          </span>
        </p>
      ) : (
        <a
          className="size-s"
          href={`mailto:${patient?.email?.email}`}
          target="_blank"
          rel="noreferrer"
        >
          {patient?.email?.email}
        </a>
      )}
    </div>
    <p className="grid-span-1">
      <Tooltip text={translate('personalDetails.emailTooltip')}>
        <InfoIcon htmlColor={Colors.iconGreen} />
      </Tooltip>
    </p>
  </>
);
