import React, { ReactNode, useEffect, useState } from 'react';

import config from 'dash/environments';
import { getChatJWT } from 'dash/src/services/ChatServices';
import Session from 'dash/src/services/SessionServices';

import { getUserImage } from '../components/Header/Avatar';
import { ChannelSort, Channel as ChannelType, StreamChat } from 'stream-chat';

// @ts-ignore
const isTest = !!window.Cypress;

interface ChatContextProps {
  chatClient?: StreamChat;
  channels?: ChannelType[];
  unreadCount?: {
    assigned: number;
    opened: number;
    total: number;
  };
}

enum StreamEvent {
  MESSAGE_NEW = 'message.new',
  MESSAGE_READ = 'message.read',
  MESSAGE_UPDATED = 'message.updated',
  CHANNEL_UPDATED = 'channel.updated',
}

const chatClient = new StreamChat(config.streamChat);
export const VestaChatContext = React.createContext<ChatContextProps>({});

export const countUnreads = (
  channels?: ChannelType[],
  assigned?: boolean,
  opened?: boolean,
) =>
  channels
    ?.filter((c) => {
      if (assigned && Session.actingUser.isRN) {
        return c.data?.rnOwnerId === Session.actingUser.id;
      }
      return !opened || !c.data?.closed;
    })
    .map((c) => c.countUnread())
    .reduce((prev, curr) => prev + curr, 0) ?? 0;

const notificationsSupported = 'Notification' in window;

export const ChatProvider = (props: { children: ReactNode }) => {
  if (config.isDev) return <>{props.children}</>;

  const [channels, setChannels] = useState<ChannelType[]>();
  const [unreadCount, setUnreadCount] = useState({
    assigned: 0,
    opened: 0,
    total: 0,
  });
  const filters = { type: 'messaging' };
  const sort = { last_message_at: -1 };
  const options = {
    state: true,
    watch: true,
  };

  if (Session.actingUser.isExternal) return <>{props.children}</>;

  useEffect(() => {
    if (notificationsSupported) {
      Notification.requestPermission();
    }

    const initClient = async () => {
      await chatClient.setUser(
        {
          id: `employee${Session.actingUser.id}`,
          name: Session.actingUser.isClinical
            ? `${
                Session.actingUser.firstName
              }, ${Session.actingUser.role.toString()}`
            : Session.actingUser.firstName,
          image: Session.actingUser.hasPicture
            ? getUserImage(Session.actingUser.id)
            : undefined,
        },
        async () => {
          const { chat } = await getChatJWT();
          return chat.token;
        },
      );

      const qChannels = await chatClient.queryChannels(
        filters,
        sort as ChannelSort,
        options,
      );
      if (qChannels && qChannels.length) {
        setChannels(qChannels);
        setUnreadCount({
          assigned: countUnreads(qChannels, true, false),
          opened: countUnreads(qChannels, false, true),
          total: countUnreads(qChannels),
        });
      }
    };
    !isTest && initClient();

    return () => {
      chatClient.disconnect();
    };
  }, []);

  useEffect(() => {
    const eventHandler = (event: any) => {
      if (chatClient.user) {
        if (event.type === StreamEvent.MESSAGE_NEW) {
          const channel = channels?.find((c) => c.cid === event.cid);
          /*
            if dash user responds to a message from an app user, add user as "member" of the channel
            so that they will get a correct "total_unread_count" from the event. technically this is not necessary atm
            bc we tend to count unreads from all channels, but we should keep an accurate members list
          */
          if (event.user.id === chatClient.user.id) {
            channel?.addMembers([chatClient.user.id]);
          } else if (!window.location.hash.includes('chats')) {
            // create a desktop notification if dash user is RN owner of channel when off /chats
            if (
              channel?.data?.rnOwnerId === Session.actingUser.id &&
              notificationsSupported
            ) {
              new Notification('New Vesta Message', {
                body: event.message.text,
              });
            }
          }

          // note: to account for ALL new messages, do not use "total_unread_count" from the event
          setUnreadCount({
            assigned: countUnreads(channels, true),
            opened: countUnreads(channels, false, true),
            total: countUnreads(channels),
          });
        }
        if (event.type === StreamEvent.MESSAGE_READ) {
          setUnreadCount({
            assigned: countUnreads(channels, true),
            opened: countUnreads(channels, false, true),
            total: countUnreads(channels),
          });
        }
        if (event.type === StreamEvent.CHANNEL_UPDATED) {
          setUnreadCount({
            assigned: countUnreads(channels, true),
            opened: countUnreads(channels, false, true),
            total: countUnreads(channels),
          });
        }
      }
    };
    chatClient.on(eventHandler);

    return () => {
      chatClient.off(eventHandler);
    };
  }, [channels]);

  return (
    <VestaChatContext.Provider
      value={{
        chatClient,
        channels,
        unreadCount,
      }}
    >
      {props.children}
    </VestaChatContext.Provider>
  );
};
