import { BaseEnum } from './BaseEnum';
import { make } from './Model';

export class MemberEmail {
  email = '';

  reason?: BaseEnum;

  notProvided = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.reason = make(obj.reason, BaseEnum);
  }
}

export default MemberEmail;
