import { BaseEnum } from './BaseEnum';

export class MemberDashboardAppointmentICD extends BaseEnum {
  primary = false;

  constructor(obj: any) {
    super(obj);
    this.primary = obj.primary;
  }
}

export default MemberDashboardAppointmentICD;
