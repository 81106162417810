import moment from 'moment';

import { Employee, EmployeeGroup } from '.';
import { TaskStatus } from '../enums/TaskStatus';
import {
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
} from '../utils/constants';
import { make, makeArray } from './Model';
import { Patient } from './Patient';
import { TaskCustomField } from './TaskCustomField';
import { TaskDefinition } from './TaskDefinition';
import { TaskDefinitionOutcome } from './TaskDefinitionOutcome';

export class Task {
  id = 0;

  description?: string;

  status: TaskStatus;

  taskDefinition: TaskDefinition;

  dueDate?: number;

  completedAt?: number;

  assignee?: Employee;

  assignedBy?: Employee;

  createdBy?: Employee;

  updatedBy?: Employee;

  healthAlertId?: number;

  eventId?: number;

  member?: Patient;

  taskDefinitionOutcome?: TaskDefinitionOutcome;

  employeeGroup: EmployeeGroup;

  position = 0;

  parentId?: number;

  children?: Task[];

  customFields?: TaskCustomField[];

  canBeStarted = false;

  createdAt = 0;

  updatedAt = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.status = TaskStatus.byKey[obj.status];
    this.taskDefinition = make(obj.taskDefinition, TaskDefinition);
    this.employeeGroup = make(obj.employeeGroup, EmployeeGroup);
    this.assignee = make(obj.assignee, Employee);
    this.assignedBy = make(obj.assignedBy, Employee);
    this.createdBy = make(obj.createdBy, Employee);
    this.updatedBy = make(obj.updatedBy, Employee);
    this.member = make(obj.member, Patient);
    this.children = makeArray(obj.children, Task);
    this.customFields = makeArray(obj.customFields, TaskCustomField);
    this.taskDefinitionOutcome = make(
      obj.taskDefinitionOutcome,
      TaskDefinitionOutcome,
    );
  }

  displayPosition = () => this.position + 1;

  displayDueOrCompletedDate = () => {
    if (this.status === TaskStatus.COMPLETED) {
      return this.displayCompletedDate();
    }

    if (!this.status.closed) {
      return this.displayDueDate();
    }

    return undefined;
  };

  displayCompletedDate = () => {
    if (!this.completedAt) return undefined;

    const date = this.formatTaskDate(this.completedAt);

    return date;
  };

  displayDueDate = () => {
    if (!this.dueDate) return undefined;

    const date = this.formatTaskDate(this.dueDate);

    return date;
  };

  formatTaskDate = (date: number) => {
    if (!date) return undefined;
    const momentDate = moment.unix(date);

    // Check if the date is at the beginnning of the day (12am)
    return momentDate.isSame(moment.unix(date).startOf('day'))
      ? momentDate.format(DATE_FORMAT_SHORT)
      : momentDate.format(DATE_FORMAT_SHORT_WITH_TIME);
  };

  hasOpenSubtasks = (): boolean => {
    const openSubtasks = this.children?.filter((t) => !t.status.closed).length;
    return (openSubtasks && openSubtasks > 0) || false;
  };

  isSubtask = () => !!this.parentId;

  isOutcomeRequiredForStatus = (status: TaskStatus) =>
    this.isSubtask() && status === TaskStatus.COMPLETED;

  get isUrgent() {
    return (
      !this.status.closed &&
      this.dueDate &&
      moment.unix(this.dueDate).isBefore(moment())
    );
  }

  static sortByStatus({ status: a }: Task, { status: b }: Task) {
    if ([TaskStatus.DELAYED, TaskStatus.ESCALATED].includes(a)) return -1;
    if ([TaskStatus.DELAYED, TaskStatus.ESCALATED].includes(b)) return 1;
    if ([TaskStatus.IN_PROGRESS, TaskStatus.PENDING_AUTHORIZATION].includes(a))
      return -1;
    if ([TaskStatus.IN_PROGRESS, TaskStatus.PENDING_AUTHORIZATION].includes(b))
      return 1;
    return 0;
  }
}

export default Task;
