import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { translate } from '@vestahealthcare/common/i18n';

import { Sidebar } from 'styleguide';

export const ExternalDashboardSidebar = () => {
  const { pathname } = useLocation();
  const { showCcmClinical, showCcmTasksClinical } = useFlags();

  const LINKS = {
    header: 'Clinical',
    links: [] as any[],
  };

  if (showCcmClinical) {
    LINKS.links.push({
      active: pathname.endsWith('/dashboard/ccm'),
      href: '/dashboard/ccm',
      text: translate('clinicalDashboard.sidebar.ccm'),
      dataCy: 'sidebar-ccm-link',
    });
  }

  if (showCcmTasksClinical) {
    LINKS.links.push({
      active: pathname.includes('/dashboard/ccm-tasks'),
      href: '/dashboard/ccm-tasks',
      text: translate('clinicalDashboard.sidebar.ccmTasks'),
      dataCy: 'sidebar-ccm-task-link',
    });
  }

  const getLinks = (): any[] => {
    return [
      {
        header: LINKS.header,
        links: [...LINKS.links],
      },
    ];
  };

  if (LINKS.links.length < 2) return <></>;

  return <Sidebar collapsible sections={getLinks()} />;
};

export default ExternalDashboardSidebar;
