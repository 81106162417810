import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ArchiveIcon from '@mui/icons-material/Archive';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MarkChatUnread from '@mui/icons-material/MarkChatUnread';
import { makeStyles } from '@mui/styles';

import { MessageType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Conversation as ConversationModel,
  PhoneUser,
} from '@vestahealthcare/common/models';

import { Toast } from 'styleguide';
import {
  BrandMemberIcon,
  Button,
  ChatInput,
  Chip,
  Colors,
  Conversation,
  Fonts,
  Icon,
  IconButton,
  Panel,
  Spinner,
  Tooltip,
} from 'styleguide-v2';
import { MessageModel } from 'styleguide-v2/src/components/Chat/Conversation';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchSMSUnreadCount } from 'dash/src/redux/slices/ChatSlice';
import { useSelector } from 'dash/src/redux/store';
import { PaginatedResponseValues } from 'dash/src/services/Api';
import {
  closeConversation,
  getPhoneUsers,
  getSMS,
  getSMSConversations,
  markConversationRead,
  sendSMS,
} from 'dash/src/services/MessageServices';
import Session from 'dash/src/services/SessionServices';
import { capitalize } from 'lodash';

import { BrandBanner } from '../../../components/BrandBanner';
import { ChatUsers } from './components/ChatUsers';

const MESSAGE_LIMIT = 10;
const REFRESH_INTERVAL = 5 * 1000;

const useStyles = makeStyles({
  banner: {
    width: 'calc(100% + 8rem)',
    left: '-4rem',
    position: 'relative',
  },
  bannerCollapse: {
    '&&': {
      height: '2rem',
    },
  },
  link: {
    color: 'inherit',
  },
  linkIcon: {
    color: Colors.green,
  },
  memberSelector: {
    width: '25rem',
  },
  phoneUsers: {
    display: 'flex',
  },
  subtitle: {
    color: Colors.textGray,
  },
  bold: {
    fontWeight: '500',
  },
  paddingButtons: {
    padding: '0.5rem 0',
  },
  tabButtons: {
    '&&': {
      height: 'auto',
      justifyContent: 'flex-end',
      padding: '0.5rem 4rem',
    },
  },
  phoneSeparator: {
    width: '5px',
    paddingTop: '0.85rem',
  },
  title: {
    fontFamily: `${Fonts.fontFamily}!important`,
    margin: 0,
  },
  titleContainer: {
    gap: '.5rem',
    marginTop: '2rem',
  },
  spinner: {
    display: 'flex',
    margin: 'auto',
  },
});

type Params = {
  userPhone: string;
  patientId?: string;
};

type Props = {
  showHeader?: boolean;
  showPhone?: boolean;
};

export const ConversationPage = ({ showHeader, showPhone }: Props) => {
  const { disableSmsChat } = useFlags();

  const { patientId, userPhone } = useParams<Params>();
  const patient = useSelector((state) => state.memberInfoSlice.patient);
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [headerLoading, setHeaderLoading] = useState(false);
  const [lastConversation, setLastConversation] = useState<ConversationModel>();
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [phoneUsers, setPhoneUsers] = useState<PhoneUser[]>([]);
  const [pagination, setPagination] = useState<PaginatedResponseValues>();
  const [smoothScroll, setSmoothScroll] = useState(true);
  const [scrollToIndex, setScrollToIndex] = useState<number | 'last'>('last');
  const firsPhoneUser = phoneUsers[0];

  useEffect(() => {
    if (phoneUsers?.length && phoneUsers[0].isBrandCareAtHome()) {
      const brandName = (phoneUsers[0]?.brand?.value || '')
        .split('_')
        .map(capitalize)
        .join(' ');
      new Toast({
        title: translate('personalDetails.warningBrandTitle', {
          brand: brandName,
        }),
        body: translate('personalDetails.warningBrandBody', {
          brand: brandName,
        }),
        type: 'care-at-home',
        position: 'bottom-right',
      });
    }
  }, [phoneUsers]);

  const fetchMessages = async ({
    actualPagination,
    createdAtFrom,
  }: {
    actualPagination?: PaginatedResponseValues;
    createdAtFrom?: string;
  } = {}) => {
    const actualOffset = actualPagination?.offset || 0;
    const actualLimit = actualPagination?.limit || 0;
    const actualTotal = actualPagination?.total || 1;

    if (!lastConversation) {
      return;
    }

    if (actualOffset + actualLimit >= actualTotal) {
      return;
    }
    let offset = 0;
    if (actualPagination) {
      offset = actualOffset + actualLimit;
    }
    try {
      const { items, pagination } = await getSMS({
        createdAtFrom,
        userPhone: [userPhone],
        limit: MESSAGE_LIMIT,
        offset,
      });

      if (lastConversation && lastConversation.isUnread()) {
        await markConversationRead(lastConversation.id, true);
        dispatch(await fetchSMSUnreadCount());
        if (patientId) {
          dispatch(
            await fetchSMSUnreadCount(
              patient?.phones?.map(({ phone }) => phone.number),
            ),
          );
        }
      }

      const serverMessages = items
        .reverse()
        .map(
          ({
            automated,
            createdAt,
            createdBy,
            conversation,
            id,
            message,
            status,
            type,
            userId,
          }) => ({
            automated,
            conversation,
            date: createdAt,
            id,
            text: message,
            status,
            type,
            user: userId,
            userLabel: createdBy?.fullName,
          }),
        );

      if (createdAtFrom) {
        if (serverMessages.length) {
          setScrollToIndex(messages.length);
          setMessages([...messages, ...serverMessages]);
        }
      } else if (offset) {
        setScrollToIndex(items.length);
        setMessages([...serverMessages, ...messages]);
        setPagination(pagination);
      } else {
        setMessages(serverMessages);
        setPagination(pagination);
      }
    } catch (e) {
      showGlobalError(e as string);
    }

    setLoading(false);
  };

  const fetchConversation = async () => {
    const lastConversation = (
      await getSMSConversations({
        userPhone: [userPhone],
        lastConversation: true,
        limit: 1,
      })
    )?.items[0];
    setLastConversation(lastConversation);
    return lastConversation;
  };

  const fetchPhoneOwners = async () => {
    setHeaderLoading(true);
    try {
      const items = await getPhoneUsers(userPhone);
      setPhoneUsers(items);
    } finally {
      setHeaderLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setSmoothScroll(true);
    fetchConversation();
    if (showHeader) {
      fetchPhoneOwners();
    }
  };

  useEffect(() => {
    fetchData();
  }, [userPhone]);

  useEffect(() => {
    if (!messages.length) {
      fetchMessages();
    }
  }, [lastConversation]);

  const refreshMessages = () => {
    if (messages.length) {
      setSmoothScroll(true);
      fetchMessages({
        createdAtFrom: moment
          .unix(messages[messages.length - 1].date + 1)
          .format(),
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => refreshMessages(), REFRESH_INTERVAL);
    return () => clearInterval(interval);
  });

  const onScrollTop = async () => {
    setConversationLoading(true);
    setSmoothScroll(false);
    await fetchMessages({ actualPagination: pagination });
    setConversationLoading(false);
  };

  const formatPhone = (phone?: string) =>
    phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  const getTitle = () => {
    if (showPhone || !phoneUsers.length) {
      return formatPhone(userPhone);
    }
    if (phoneUsers.length === 1) {
      return (
        <div className="flex gap middle">
          {phoneUsers[0].user.fullName}
          {phoneUsers[0].isBrandCareAtHome() && (
            <BrandMemberIcon size="l" brand="careAtHome" />
          )}
        </div>
      );
    }
    const members = phoneUsers.filter(({ user }) => user.isMember());
    const careTeamPersons = phoneUsers.filter(({ user }) =>
      user.isCareTeamPerson(),
    );
    const caregivers = phoneUsers.filter(({ user }) => user.isCaregiver());
    if (members.length === 1) {
      return (
        <div className="flex gap middle">
          {members[0].user.fullName}
          {members[0].isBrandCareAtHome() && (
            <BrandMemberIcon size="l" brand="careAtHome" />
          )}
        </div>
      );
    }
    if (members.length) {
      return `${members.length} ${translate('chat.members')}`;
    }
    if (careTeamPersons.length === 1) {
      return caregivers[0].user.fullName;
    }
    if (careTeamPersons.length > 1) {
      return `${careTeamPersons.length} ${translate('chat.careTeam')}`;
    }
    if (caregivers.length === 1) {
      return caregivers[0].user.fullName;
    }
    return `${caregivers.length} ${translate('chat.caregivers')}`;
  };

  const getSubtitle = () => {
    if (showPhone) {
      const phone = patientId
        ? phoneUsers.find(({ user }) => user.id === Number(patientId))
        : phoneUsers[0];
      if (phone) {
        return (
          <>
            {phone.primary && (
              <>
                <span className={styles.bold}>{translate('chat.primary')}</span>
                &nbsp;
                <FiberManualRecordIcon className={styles.phoneSeparator} />
                &nbsp;
              </>
            )}
            {translate(`enums.phoneType.${phone.phone.type?.value}`)}
          </>
        );
      }
      return;
    }
    const members = phoneUsers.filter(({ user }) => user.isMember());
    if (members.length === 1 && !showPhone) {
      return (
        <>
          <span className={styles.bold}>{translate('chat.member')}</span>
          <span>
            &nbsp;
            <a
              target="blank"
              rel="noreferrer"
              href={`#/patients/${firsPhoneUser?.user?.id}`}
            >
              {firsPhoneUser?.user?.id}
            </a>
          </span>
          &nbsp;
          <FiberManualRecordIcon className={styles.phoneSeparator} />
          &nbsp;
          <span>{formatPhone(userPhone)}</span>
        </>
      );
    }
    const careTeamPersons = phoneUsers.filter(({ user }) =>
      user.isCareTeamPerson(),
    );
    const caregivers = phoneUsers.filter(({ user }) => user.isCaregiver());
    if (members.length || caregivers.length > 1) {
      return <span className={styles.bold}>{formatPhone(userPhone)}</span>;
    }

    if (!showPhone) {
      if (careTeamPersons.length === 1) {
        return (
          <>
            <span className={styles.bold}>
              {translate('chat.careTeamPerson')}
            </span>
            <span>
              &nbsp;
              <a
                target="blank"
                rel="noreferrer"
                href={`#/care-team/${firsPhoneUser?.user?.id}`}
              >
                {firsPhoneUser?.user?.id}
              </a>
            </span>
            &nbsp;
            <FiberManualRecordIcon className={styles.phoneSeparator} />
            &nbsp;
            <span>{formatPhone(userPhone)}</span>
          </>
        );
      }
      if (caregivers.length === 1) {
        return (
          <>
            <span className={styles.bold}>{translate('chat.caregiver')}</span>
            &nbsp;
            <span>{caregivers[0].user.id}</span>
            &nbsp;
            <FiberManualRecordIcon className={styles.phoneSeparator} />
            &nbsp;
            <span>{formatPhone(userPhone)}</span>
          </>
        );
      }
    }
  };

  const getOtherUsers = () => {
    const members = phoneUsers.filter(({ user }) => user.isMember());
    const careTeamPersons = phoneUsers.filter(({ user }) =>
      user.isCareTeamPerson(),
    );
    const caregivers = phoneUsers.filter(({ user }) => user.isCaregiver());
    if (!showPhone) {
      if (members.length === 1) {
        return [...phoneUsers].slice(1);
      }
      if (members.length === 0 && careTeamPersons.length === 1) {
        return [...phoneUsers].slice(1);
      }
      if (members.length === 0 && caregivers.length === 1) {
        return [...phoneUsers].slice(1);
      }
    }
    return phoneUsers;
  };

  const markConversationUnread = async () => {
    const lastOpenedConversation = lastConversation?.id;
    if (lastOpenedConversation) {
      await markConversationRead(lastOpenedConversation, false);
      dispatch(await fetchSMSUnreadCount());
      if (patientId) {
        dispatch(
          await fetchSMSUnreadCount(
            patient?.phones?.map(({ phone }) => phone.number),
          ),
        );
      }
    }
    history.goBack();
  };

  const archiveConversation = async () => {
    const lastOpenedConversation = lastConversation?.id;
    if (lastOpenedConversation) {
      await closeConversation(lastOpenedConversation);
    }
    history.goBack();
  };

  const send = async (message?: string) => {
    if (message) {
      await sendSMS(userPhone, message);
      const conversation = await fetchConversation();
      setScrollToIndex('last');
      setSmoothScroll(true);
      setMessages([
        ...messages,
        {
          conversation,
          text: message || '',
          user: 2,
          userLabel: Session.actingUser.fullName,
          date: moment().unix(),
          type: MessageType.SMS_OUTBOUND,
        },
      ]);
    }
  };

  return (
    <Panel>
      {showHeader && (
        <Panel.Heading loading={headerLoading}>
          {!headerLoading && (
            <>
              <div
                className={classnames('title', styles.titleContainer)}
                style={{ gap: '.5rem' }}
              >
                <Button
                  href="blank"
                  className={styles.link}
                  data-cy="sms-back-button"
                  onClick={() => history.goBack()}
                >
                  <Icon className="fa fa-arrow-left" color="green" size="sm" />
                  &nbsp;{translate('chat.backToMessages')}
                </Button>
                <div className="flex">
                  <h2 className={styles.title} data-cy="sms-user-name">
                    {getTitle()}
                  </h2>
                  &nbsp;
                  {firsPhoneUser?.phone?.textReminderOptOut && (
                    <Chip color="error" label={translate('chat.smsOptOut')} />
                  )}
                </div>
                <p className={styles.subtitle} data-cy="sms-user-type">
                  {getSubtitle()}
                </p>
              </div>
              <Panel.Actions
                className={styles.phoneUsers}
                data-cy="sms-other-users"
              >
                {!!getOtherUsers()?.length && (
                  <ChatUsers users={getOtherUsers()} />
                )}
              </Panel.Actions>
              {!lastConversation?.isClosed() && (
                <Panel.Tabs
                  className={classnames('flex gap', styles.tabButtons)}
                >
                  <IconButton
                    onClick={markConversationUnread}
                    tooltip={translate('chat.markUnread')}
                  >
                    <MarkChatUnread />
                  </IconButton>
                  <IconButton
                    onClick={archiveConversation}
                    tooltip={translate('chat.archiveConversation')}
                  >
                    <ArchiveIcon />
                  </IconButton>
                </Panel.Tabs>
              )}
              {phoneUsers[0]?.isBrandCareAtHome() && (
                <Panel.Collapse className={styles.bannerCollapse} open>
                  <BrandBanner
                    className={styles.banner}
                    member={phoneUsers[0]}
                  />
                </Panel.Collapse>
              )}
            </>
          )}
        </Panel.Heading>
      )}
      <Panel.Body onScrollTop={onScrollTop} loading={loading}>
        {conversationLoading && (
          <Spinner className={styles.spinner} width={40} />
        )}

        <Conversation
          data-cy="conversation-messages"
          lastConversation={lastConversation}
          messages={messages}
          scrollToIndex={scrollToIndex}
          smoothScroll={smoothScroll}
          leftUser={{ label: firsPhoneUser?.user?.firstName }}
          rightUser={{ label: '' }}
        />
      </Panel.Body>
      <Panel.Footer>
        {disableSmsChat ? (
          <Tooltip followCursor text={translate('chat.disabledChat')}>
            <div>
              <ChatInput
                data-cy="conversation-input"
                disabled
                onSend={() => {}}
              />
            </div>
          </Tooltip>
        ) : (
          <ChatInput
            data-cy="conversation-input"
            disabled={
              !!phoneUsers.find(({ phone }) => phone.number === userPhone)
                ?.phone?.textReminderOptOut
            }
            onSend={send}
          />
        )}
      </Panel.Footer>
    </Panel>
  );
};

export default ConversationPage;
