import classnames from 'classnames';
import EmojiPicker from 'emoji-picker-react';
import React, { ChangeEvent, useRef, useState } from 'react';

import { AttachFile, EmojiEmotionsOutlined, Send } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  onSend: (message?: string, image?: File) => void;
  showAttachFile?: boolean;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    margin: '0.5rem 2rem 1rem',
    gap: '1rem',
  },
  disabled: {
    cursor: 'not-allowed',
    '& input[disabled]': {
      cursor: 'not-allowed',
    },
  },
  input: {
    flex: '100%',
  },
  button: {
    flex: '10rem',
  },
  '@global': {
    'fieldset > legend': {
      display: 'none',
    },
    'aside.emoji-picker-react, .emoji-picker-react input.emoji-search': {
      background: BackgroundColors.lightMint,
      border: `1px solid ${Colors.mint}`,
    },
    '.emoji-picker-react .active-category-indicator-wrapper .active-category-indicator': {
      background: Colors.mint,
    },
    '.emoji-picker-react .emoji-group:before': {
      background: BackgroundColors.lightMint,
    },
    '.MuiOutlinedInput-root > input': {
      padding: '1rem',
      fontSize: Fonts.fontSize,
      fontFamily: Fonts.fontFamily,
    },
  },
  emojiPicker: {
    position: 'absolute',
    right: '10rem',
    bottom: '6.5rem',
  },
});

const ENTER = 13;

export const ChatInput = ({
  className,
  'data-cy': dataCy,
  disabled,
  onSend,
  showAttachFile,
}: Props) => {
  const styles = useStyles();
  const [message, setMessage] = useState('');
  const [isEmojiVisible, setEmojiVisible] = useState(false);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const classes = classnames(className, styles.container);

  const sendMessage = () => {
    if (message) {
      setEmojiVisible(false);
      setMessage('');
      onSend(message);
    }
  };

  const handleKeyPress = (keyCode: number) => {
    if (keyCode === ENTER) {
      sendMessage();
    }
  };

  const handleFileChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt?.target?.files && evt?.target?.files[0]) {
      setEmojiVisible(false);
      setMessage('');
      onSend(message, evt.target.files[0]);
      if (inputRef?.current?.value) {
        inputRef.current.value = '';
      }
    }
  };

  return (
    <div className={classes} data-cy={dataCy}>
      <OutlinedInput
        disabled={disabled}
        value={message}
        onChange={(evt) => setMessage(evt?.target.value)}
        onFocus={() => setEmojiVisible(false)}
        className={classnames(styles.input, disabled && styles.disabled)}
        onKeyDown={(evt) => handleKeyPress(evt.keyCode)}
        endAdornment={
          <>
            <InputAdornment position="start">
              <IconButton
                data-cy={`${dataCy}-emoji`}
                disabled={disabled}
                onClick={() => setEmojiVisible(!isEmojiVisible)}
              >
                <EmojiEmotionsOutlined fontSize="large" />
              </IconButton>
            </InputAdornment>
            {showAttachFile && (
              <InputAdornment position="start">
                <IconButton
                  data-cy={`${dataCy}-file`}
                  disabled={disabled}
                  onClick={() => inputRef?.current?.click()}
                >
                  <AttachFile fontSize="large" />
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
      />
      <Button
        data-cy={`${dataCy}-send`}
        disabled={disabled}
        endIcon={<Send />}
        onClick={() => sendMessage()}
        variant="outlined"
      >
        {translate('global.send')}
      </Button>
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
      <div className={styles.emojiPicker} hidden={!isEmojiVisible}>
        <EmojiPicker
          disableAutoFocus
          native
          onEmojiClick={(_, { emoji }) => setMessage(`${message}${emoji}`)}
        />
      </div>
    </div>
  );
};

export default ChatInput;
