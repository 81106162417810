import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { translate } from '@vestahealthcare/common/i18n';
import {
  EventEvaluation,
  EventIntervention,
  EventModel,
  EventNote,
  EventTriage,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';

import { Button, IconButton, Spinner, Tooltip } from 'styleguide-v2';

import { fetchEventTriages } from 'dash/src/services/EventServices';

import { EventDetailCard } from './EventDetailCard';
import { eventEvaluationDetailStyles } from './EventDetailEvaluationStyles';
import { EventDetailNotes } from './EventDetailNotes';
import { EventDetailTriage } from './EventDetailTriage';

type Props = {
  className?: string;
  evaluation?: EventEvaluation;
  event: EventModel;
  editMode?: boolean;
  onAddIntervention: () => void;
  onAddTriage: () => void;
  onCompleteEvaluation: (evaluationId: number) => void;
  onEditNote: (note?: EventNote) => void;
  onUpdateEvaluation: (evaluation: EventEvaluation) => void;
  onShowTriage: (triage: EventTriage) => void;
};

export const EventDetailEvaluation = ({
  className,
  editMode,
  evaluation,
  onAddIntervention,
  onAddTriage,
  onCompleteEvaluation,
  onEditNote,
  onUpdateEvaluation,
  onShowTriage,
}: Props) => {
  const styles = eventEvaluationDetailStyles();
  const [triages, setTriages] = useState<EventTriage[]>();
  const [interventions, setInterventions] = useState<EventIntervention[]>();

  const isEditable = !evaluation?.closed;

  const getTriages = async () => {
    if (evaluation) {
      setTriages(undefined);
      const triages = await fetchEventTriages(evaluation?.id);
      setTriages(triages || []);
    }
  };

  useEffect(() => {
    getTriages();
    setInterventions(
      evaluation?.interventions.sort(({ type: a }, { type: b }) => a.id - b.id),
    );
  }, [evaluation]);

  const shouldAllowToCloseEvaluation = () =>
    triages?.length && interventions?.length;

  return !evaluation || !triages ? (
    <div className={classnames(styles.container, className, 'flex')}>
      <Spinner className={styles.spinner} width={32} color="primary" />
    </div>
  ) : (
    <div className={classnames(styles.container, className)}>
      {!triages && <Spinner width={32} color="primary" />}
      {!!triages?.length && (
        <EventDetailCard
          className={classnames(styles.triage, styles.card)}
          title={translate('memberEvents.detail.triage')}
          titleIcon={
            isEditable && (
              <IconButton
                className={styles.cardTitleAction}
                onClick={() => onAddTriage()}
                tooltip={translate('memberEvents.detail.addTriage')}
                size="small"
              >
                <AddIcon color="primary" />
              </IconButton>
            )
          }
        >
          <EventDetailTriage
            triages={triages}
            onClickInfo={(triage) => {
              if (triage) {
                onShowTriage(triage);
              }
            }}
          />
        </EventDetailCard>
      )}
      {!!(triages?.length || interventions?.length) && (
        <EventDetailCard
          className={styles.card}
          title={translate('memberEvents.detail.interventions')}
          titleIcon={
            isEditable && (
              <IconButton
                className={styles.cardTitleAction}
                onClick={() => onAddIntervention()}
                size="small"
                tooltip={translate('memberEvents.detail.addIntervention')}
              >
                <AddIcon color="primary" />
              </IconButton>
            )
          }
        >
          <ul className={styles.interventionContainer}>
            {interventions?.length ? (
              interventions?.map((intervention, index) => {
                const { type } = intervention;

                return (
                  <li
                    key={`intervention-${index}`}
                    className={styles.interventionRow}
                  >
                    <div>
                      <p>{type.description}</p>
                      <p className={styles.createdInfo}>
                        {intervention.createdAt.format(
                          DATE_FORMAT_SHORT_WITH_TIME,
                        )}{' '}
                        by {intervention.createdBy.fullName}
                      </p>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className={styles.interventionRow}>
                <p className="grey">
                  {translate('memberEvents.detail.noInterventions')}
                </p>
              </li>
            )}
          </ul>
        </EventDetailCard>
      )}
      <EventDetailNotes
        className={styles.card}
        canAdd={isEditable}
        notes={evaluation.notes?.filter(({ addendum }) => !addendum)}
        onEdit={onEditNote}
      />

      <EventDetailNotes
        addendum
        className={styles.card}
        canAdd={!isEditable}
        notes={evaluation.notes?.filter(({ addendum }) => addendum)}
        onEdit={onEditNote}
      />

      {!evaluation.closed && !!triages?.length && (
        <div className={styles.buttons}>
          <Tooltip
            text={translate('memberEvents.detail.warningCloseEvaluation')}
          >
            <div style={{ width: 'fit-content', margin: 'auto' }}>
              <Button
                color="secondary"
                disabled={!shouldAllowToCloseEvaluation()}
                onClick={() => onCompleteEvaluation(evaluation.id)}
              >
                {translate('memberEvents.detail.closeEvaluation')}
              </Button>
            </div>
          </Tooltip>
        </div>
      )}

      {!evaluation.closed && !triages?.length && (
        <div className={styles.buttons}>
          <Button color="secondary" onClick={() => onAddTriage()}>
            {translate('memberEvents.detail.addTriage')}
          </Button>
        </div>
      )}

      {evaluation.closed && editMode && (
        <div className={styles.buttons}>
          <Button
            color="secondary"
            onClick={() =>
              onUpdateEvaluation({
                ...evaluation,
                interventions: interventions || [],
              })
            }
          >
            {translate('memberEvents.detail.saveEvaluation')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EventDetailEvaluation;
