import { Selectable } from '../enums/Enum';

export class BaseEnum {
  id = '';

  description = '';

  active = false;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.active = obj.active === undefined ? true : obj.active;
  }

  static OTHER = 'OTHER';

  static sortOtherLast({ id: a }: BaseEnum, { id: b }: BaseEnum) {
    if (a === BaseEnum.OTHER) return 1;
    if (b === BaseEnum.OTHER) return -1;
    return 0;
  }

  static sort({ description: a }: BaseEnum, { description: b }: BaseEnum) {
    return a.localeCompare(b);
  }

  static toSelectable(items: BaseEnum[]): Selectable[] {
    return items.map(({ id, description }) => ({
      value: id,
      label: description,
    }));
  }

  static getLabel(item?: BaseEnum) {
    return item?.description || '';
  }
}

export default BaseEnum;
