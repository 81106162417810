// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ht-nav-logo-wrapper #ht-nav-logo {
  height: 20px;
}
#ht-environment-banner {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin: 0 20px;
  padding: 0 9px;
  font-weight: bold;
  line-height: 100%;
}
#ht-nav-controls {
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: flex-end;
  flex: 1 0 auto;
}
#ht-nav-controls #add-member-btn {
  margin-right: 20px;
}
#ht-nav-controls > li {
  display: inline-flex;
  margin-left: 20px;
}
#ht-nav-controls > li #ht-nav-user-menu {
  display: inline-flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.banner-warning {
  background-color: #fff3c6;
  color: #fec25f;
}
.banner-dev {
  background-color: #ffffff;
}
.banner-qa {
  background-color: #ffc966;
}
.banner-stage {
  background-color: #ffc0bd;
}
.banner-demo {
  background-color: #e2b7e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/main.less"],"names":[],"mappings":"AAEA;EAEI,YAAA;AAFJ;AAMA;EACE,aAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,4BAAA;EACA,yBAAA;EACA,cAAA;AALF;AAAA;EAQI,kBAAA;AALJ;AAHA;EAYI,oBAAA;EACA,iBAAA;AANJ;AAPA;EAgBM,oBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AANN;AAWA;EACE,yBAAA;EACA,cAAA;AATF;AAYA;EACE,yBAAA;AAVF;AAaA;EACE,yBAAA;AAXF;AAcA;EACE,yBAAA;AAZF;AAeA;EACE,yBAAA;AAbF","sourcesContent":["@import (reference) '~styleguide/src/styles/variables.less';\n\n#ht-nav-logo-wrapper {\n  #ht-nav-logo {\n    height: 20px;\n  }\n}\n\n#ht-environment-banner {\n  display: flex;\n  flex: 1 1 auto;\n  justify-content: center;\n  align-items: center;\n  height: 38px;\n  margin: 0 20px;\n  padding: 0 9px;\n  font-weight: bold;\n  line-height: 100%;\n}\n\n#ht-nav-controls {\n  display: flex;\n  align-items: center;\n  align-content: space-between;\n  justify-content: flex-end;\n  flex: 1 0 auto;\n\n  #add-member-btn {\n    margin-right: 20px;\n  }\n\n  > li {\n    display: inline-flex;\n    margin-left: 20px;\n\n    #ht-nav-user-menu {\n      display: inline-flex;\n      list-style: none;\n      justify-content: center;\n      align-items: center;\n      align-content: center;\n    }\n  }\n}\n\n.banner-warning {\n  background-color: @yellow;\n  color: @gold;\n}\n\n.banner-dev {\n  background-color: @white;\n}\n\n.banner-qa {\n  background-color: lighten(orange, 20%);\n}\n\n.banner-stage {\n  background-color: @pink;\n}\n\n.banner-demo {\n  background-color: @magenta;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
