import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

import {
  DayOfWeek,
  Language,
  MemberContactMethodType,
  MemberContactTimeType,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareTeamMember,
  CareTeamMemberType,
  CareTeamPersonExtended,
  Employee,
  PaginationType,
} from '@vestahealthcare/common/models';
import { LS_ADMIN_CARE_TEAM_FILTERS } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  FilterButton,
  IconButton,
  Panel,
  Select,
  Tabs,
  TextInput,
} from 'styleguide-v2';
import { TabsItem } from 'styleguide-v2/src/components/Tabs';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import {
  GetCareTeamPersonsParams,
  fetchCareTeamPeople,
  quickAddCareTeam,
} from 'dash/src/services/CareTeamServices';
import Session from 'dash/src/services/SessionServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';
import {
  getAsQuery,
  getBoolean,
  getCustom,
  getStoredFilters,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { CareTeamPersonTable } from './CareTeamPersonTable';

const CARE_TEAM_TAB_ALL = 'care-team-tab-all';
const CARE_TEAM_TAB_ASSIGNED_TO_ME = 'care-team-tab-assigned';
const DEFAULT_TABS = [
  { value: CARE_TEAM_TAB_ALL, label: translate('careTeam.all') },
  {
    value: CARE_TEAM_TAB_ASSIGNED_TO_ME,
    label: translate('careTeam.assignedToMe'),
  },
];

const PAGE_SIZE = 10;

const initFilters = (query: URLSearchParams): GetCareTeamPersonsParams => {
  const filters = getStoredFilters(LS_ADMIN_CARE_TEAM_FILTERS);

  const statusId = query.getAll('statusId');
  const typeId = query.getAll('typeId');

  return {
    id: getCustom(
      query.getAll('id'),
      filters.id as string[],
      Number,
    ) as number[],
    fullName: query.get('fullName') || (filters.fullName as string),
    statusId: statusId?.length ? statusId : (filters.statusId as string[]),
    typeId: typeId?.length ? typeId : (filters.typeId as string[]),
    engagementOwnerId: getCustom(
      query.getAll('engagementOwnerId'),
      filters.engagementOwnerId as string[],
      Number,
    ) as number[],
    language: getCustom(
      query.getAll('language'),
      filters.language as string[],
      (x) => Language.byKey[x],
    ) as Language[],
    contactMethods: getCustom(
      query.getAll('contactMethods'),
      filters.contactMethods as string[],
      (x) => MemberContactMethodType.byKey[x],
    ) as MemberContactMethodType[],
    preferredDays: getCustom(
      query.getAll('preferredDays'),
      filters.preferredDays as string[],
      (x) => DayOfWeek.byKey[x],
    ) as DayOfWeek[],
    preferredTime: getCustom(
      query.getAll('preferredTime'),
      filters.preferredTime as string[],
      (x) => MemberContactTimeType.byKey[x],
    ) as MemberContactTimeType[],
    linkedMembers: getBoolean(query, filters, 'linkedMembers'),
  };
};

export const CareTeamDashboard = () => {
  const history = useHistory();
  const query = useQueryParams();

  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(CARE_TEAM_TAB_ALL);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<GetCareTeamPersonsParams>(
    initFilters(query),
  );
  const [nameSearch, setNameSearch] = useState<string>();

  const [tabs, setTabs] = useState<TabsItem[]>(DEFAULT_TABS);
  const [countAll, setCountAll] = useState<number>();
  const [countAssign, setCountAssign] = useState<number>();

  const [languages, setLanguages] = useState<Language[]>([]);
  const [statuses, setStatuses] = useState<BaseEnum[]>([]);
  const [roles, setRoles] = useState<CareTeamMemberType[]>([]);
  const [engagementOwners, setEngagementOwners] = useState<Employee[]>([]);

  const fullName = useDebounce(nameSearch, 300);

  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [careTeamPersons, setCareTeamPersons] = useState<
    CareTeamPersonExtended[]
  >([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const getInitialData = async () => {
    setLoadingFilters(true);
    const [l, roles, statuses, eo] = await Promise.all([
      await fetchSupportedLanguages(),
      CacheServices.getCareTeamMemberTypes(),
      CacheServices.getCareTeamPersonStatuses(),
      CacheServices.getEngagementOwners(),
    ]);

    setLanguages(l);
    setRoles(roles);
    setStatuses(statuses);
    setEngagementOwners(eo);
    setLoadingFilters(false);
  };

  const getTabFilters = (tab?: string): GetCareTeamPersonsParams => {
    if ((tab || selectedTab) === CARE_TEAM_TAB_ASSIGNED_TO_ME) {
      return {
        ...filters,
        fullName,
        engagementOwnerId: [Session.actingUser.id],
      };
    }
    return {
      ...filters,
      fullName,
    };
  };

  const getData = async () => {
    if (page !== 0 && page * pageSize < careTeamPersons?.length) return;

    setLoading(true);
    try {
      const { items, pagination } = await fetchCareTeamPeople({
        extended: true,
        offset: page * pageSize,
        limit: pageSize,
        ...getTabFilters(),
      });
      if (page === 0) {
        setCareTeamPersons(items as CareTeamPersonExtended[]);
      } else {
        setCareTeamPersons([
          ...careTeamPersons,
          ...items,
        ] as CareTeamPersonExtended[]);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getCountData = async (tab = CARE_TEAM_TAB_ALL) => {
    const { pagination } = await fetchCareTeamPeople({
      count: true,
      offset: page * pageSize,
      limit: pageSize,
      ...getTabFilters(tab),
    });

    if (tab === CARE_TEAM_TAB_ASSIGNED_TO_ME) {
      setCountAssign(pagination.total);
    } else {
      setCountAll(pagination.total);
    }
  };

  const onAddCTP = () => {
    setOpenAddModal(true);
  };

  useEffect(() => {
    getInitialData();
    getCountData(CARE_TEAM_TAB_ASSIGNED_TO_ME);
  }, []);

  useEffect(() => {
    getData();
    getCountData();
  }, [page, pageSize, selectedTab, filters, fullName]);

  useEffect(() => {
    const {
      contactMethods,
      preferredDays,
      preferredTime,
      language,
      ...rest
    } = filters;

    const flatFilters = {
      contactMethods: contactMethods?.map(({ value }) => value),
      preferredDays: preferredDays?.map(({ value }) => value),
      preferredTime: preferredTime?.map(({ value }) => value),
      language: language?.map(({ value }) => value),
      ...rest,
      fullName,
    };

    saveStoredFilters(LS_ADMIN_CARE_TEAM_FILTERS, flatFilters);
    history.replace(`?${getAsQuery(flatFilters)}`);
  }, [filters, fullName]);

  useEffect(() => {
    setTabs([
      {
        value: CARE_TEAM_TAB_ALL,
        label: `${translate('careTeam.all')}${
          countAll ? ` (${countAll})` : ''
        }`,
      },
      {
        value: CARE_TEAM_TAB_ASSIGNED_TO_ME,
        label: `${translate('careTeam.assignedToMe')}${
          countAssign ? ` (${countAssign})` : ''
        }`,
      },
    ]);
  }, [countAll, countAssign]);

  return (
    <Panel id="care-team-section">
      <Panel.Heading title={translate('careTeam.title')}>
        <FilterButton
          className="filters"
          onClick={() => setOpenFilters(!openFilters)}
          filters={filters}
        />
        <Panel.Actions>
          <IconButton onClick={onAddCTP} tooltip={translate('careTeam.add')}>
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
        <Panel.Tabs>
          <Tabs
            disabled={loading}
            items={tabs}
            onChange={setSelectedTab}
            value={selectedTab}
          />
        </Panel.Tabs>
        <Panel.Collapse open={openFilters}>
          <div className="grid-wrapper fit">
            <Select
              allowAddNewValue
              className="grid-span-3"
              data-cy="care-team-filter-id"
              items={[]}
              multiple
              noOptionsText={translate('careTeam.typeToSearch')}
              onChange={(values: Selectable[]) =>
                setFilters({
                  ...filters,
                  id: values.map((v) => Number(v.label)),
                })
              }
              placeholder={translate('careTeam.common.id')}
              value={filters.id?.map((id) => ({ label: id }))}
              size="small"
            />
            <TextInput
              className="grid-span-3"
              data-cy="care-team-filters-name"
              onChange={(val) => {
                setPage(0);
                setNameSearch(val);
              }}
              placeholder={translate('careTeam.common.name')}
              value={filters.fullName}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-status"
              getItemLabel={({ description }: CareTeamMemberType) =>
                description
              }
              items={statuses}
              multiple
              onChange={(newValue: CareTeamMemberType[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  statusId: newValue?.length
                    ? newValue.map(({ id }) => id)
                    : undefined,
                });
              }}
              placeholder={translate('careTeam.common.status')}
              value={statuses.filter(({ id }) =>
                filters.statusId?.includes(id),
              )}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-role"
              getItemLabel={({ description }: CareTeamMemberType) =>
                description
              }
              items={roles}
              multiple
              onChange={(newValue: CareTeamMemberType[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  typeId: newValue?.length
                    ? newValue.map(({ id }) => id)
                    : undefined,
                });
              }}
              placeholder={translate('careTeam.common.role')}
              value={roles.filter(({ id }) => filters.typeId?.includes(id))}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-engagementOwner"
              getItemLabel={({ fullName }: Employee) => fullName}
              items={engagementOwners}
              multiple
              onChange={(newValue: Employee[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  engagementOwnerId: newValue?.length
                    ? newValue.map(({ id }) => id)
                    : undefined,
                });
              }}
              placeholder={translate('careTeam.common.engagementOwner')}
              value={engagementOwners.filter(({ id }) =>
                filters.engagementOwnerId?.includes(id),
              )}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-language"
              grouped={({ preferred }) => (preferred ? 'Preferred' : 'Other')}
              items={Language.toSelectable(languages).filter(
                ({ disabled }) => !disabled,
              )}
              loading={loadingFilters}
              multiple
              onChange={(values: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  language: values.map((v) => Language.byKey[v.value]),
                });
              }}
              placeholder={translate('careTeam.common.language')}
              value={Enum.toSelectable(filters.language || [])}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-contactMethods"
              items={MemberContactMethodType.toSelectable()}
              multiple
              onChange={(newValue: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  contactMethods: newValue?.length
                    ? newValue
                        .map(
                          ({ value }) => MemberContactMethodType.byKey[value],
                        )
                        .filter(Boolean)
                    : undefined,
                });
              }}
              placeholder={translate('careTeam.common.engagementPreference')}
              value={Enum.toSelectable(filters.contactMethods || [])}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-preferred-days"
              items={Enum.toSelectable(DayOfWeek.asArray)}
              multiple
              onChange={(newValue: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  preferredDays: newValue?.length
                    ? newValue
                        .map(({ value }) => DayOfWeek.byKey[value])
                        .filter(Boolean)
                    : undefined,
                });
              }}
              placeholder={translate('careTeam.common.preferredDays')}
              value={Enum.toSelectable(filters.preferredDays || [])}
              size="small"
            />

            <Select
              className="grid-span-3"
              data-cy="care-team-filters-preferred-time"
              items={Enum.toSelectable([
                MemberContactTimeType.MORNING,
                MemberContactTimeType.AFTERNOON,
              ])}
              multiple
              onChange={(newValue: Selectable[]) => {
                setPage(0);
                setFilters({
                  ...filters,
                  preferredTime: newValue?.length
                    ? newValue
                        .map(({ value }) => MemberContactTimeType.byKey[value])
                        .filter(Boolean)
                    : undefined,
                });
              }}
              placeholder={translate('careTeam.common.preferredTimes')}
              value={Enum.toSelectable(filters.preferredTime || [])}
              size="small"
            />

            <Select.Choice
              className="grid-span-3"
              data-cy="care-team-filters-linked-members"
              onChange={(linkedMembers?: boolean) => {
                setPage(0);
                setFilters({
                  ...filters,
                  linkedMembers,
                });
              }}
              placeholder={translate('careTeam.common.linkedMembers')}
              value={filters.linkedMembers}
              size="small"
            />

            <div className="grid-span-3" />

            <Button
              className="grid-span-3"
              data-cy="employees-clear-filters"
              color="secondary"
              type="outlined"
              onClick={() => {
                setPage(0);
                setFilters({});
              }}
              size="s"
            >
              {translate('global.clearFilters')}
            </Button>
          </div>
        </Panel.Collapse>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <CareTeamPersonTable
          careTeamPersons={careTeamPersons}
          onAdd={onAddCTP}
          onEdit={(ctm) => {
            history.push(
              `/admin/care-team/${ctm.id}?firstName=${ctm.firstName}&lastName=${ctm.lastName}`,
            );
          }}
          onChangePage={setPage}
          onChangePageSize={(val) => {
            setPage(0);
            setPageSize(val);
          }}
          pagination={pagination}
        />
        <CareTeamQuickAddModal
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
          onSubmit={async (params) => {
            let ctm: CareTeamMember | undefined;
            try {
              ctm = await quickAddCareTeam(params);
              await getData();
            } catch (e) {
              showGlobalError(e as string);
            }
            return ctm?.careTeamPerson || false;
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CareTeamDashboard;
