import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import StyleguideV2 from 'styleguide-v2/src/pages/Styleguide';
import Styleguide from 'styleguide/src/pages/Styleguide';

import AdminDashboard from 'dash/src/pages/Admin';
import Chats from 'dash/src/pages/Chats';
import ClinicalDashboard from 'dash/src/pages/Clinical';
import DevDashboard from 'dash/src/pages/Dev';
import Home from 'dash/src/pages/Home';
import ClinicalProfile from 'dash/src/pages/MemberProfile';
import Ops from 'dash/src/pages/Ops';
import Session from 'dash/src/services/SessionServices';
import { some } from 'lodash';

import CareTeamDetail from './pages/Admin/CareTeam/CareTeamDetail';

export const getPageElement = () => document.getElementById('page');

export const Page = (props: RouteComponentProps) => {
  const [prevProps, setPrevProps] = useState(props);

  useEffect(() => {
    if (isNewPageTransition(prevProps, props)) {
      updateGoogleAnalytics(props);
      setPrevProps(props);
      window.setTimeout(scrollPageTo);
    }
  });

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/dashboard" component={ClinicalDashboard} />
      <Route path="/chats" component={Chats} />
      <Route path="/care-team/:id" component={CareTeamDetail} />
      <Route path="/patients/:patientId" component={ClinicalProfile} />
      <Route path="/ops" component={Ops} />
      {Session.actingUser.isAdmin && (
        <Route path="/admin" component={AdminDashboard} />
      )}
      {Session.actingUser.isAdmin && (
        <Route path="/dev/styleguide" component={Styleguide} />
      )}
      {Session.actingUser.isAdmin && (
        <Route path="/dev/styleguide-v2" component={StyleguideV2} />
      )}
      {Session.actingUser.isAdmin && (
        <Route path="/dev" component={DevDashboard} />
      )}

      <Redirect from="/tasks/:taskId" to="/dashboard/tasks/:taskId" />
      <Redirect from="**" to={Session.actingUser.homepage} />
    </Switch>
  );
};

// Set the page's scroll position
export const scrollPageTo = (position = 0) => {
  const page = getPageElement();
  if (page) {
    page.scrollTop = position;
  }
};

// Adjust the page's scroll position from its current location
export const scrollPageFrom = (position: number) => {
  const page = getPageElement();
  if (page) {
    page.scrollTop += position;
  }
};

// If the beginning and end route contains any of these keywords
// don't automatically scroll to the top of the page
const EXEMPT_ROUTES = ['interventions', 'phip-profile'];

const isNewPageTransition = (
  prevProps: RouteComponentProps,
  newProps: RouteComponentProps,
) => {
  const prevPath = prevProps.location.pathname;
  const newPath = newProps.location.pathname;
  const isExempt = some(
    EXEMPT_ROUTES,
    (route) => prevPath.includes(route) && newPath.includes(route),
  );
  return !isExempt && prevPath !== newPath;
};

// Google Analytics
const updateGoogleAnalytics = (props: RouteComponentProps) => {
  // @ts-ignore
  if (window.ga) {
    // @ts-ignore
    window.ga('set', 'page', props.location.pathname);
    // @ts-ignore
    window.ga('send', 'pageview');
  }
};

export default withRouter(Page);
