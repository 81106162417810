function excludeOtherValuesFunction<T extends { id: number | string }>(
  items: T[],
  values: T[],
  currenValue?: T,
): T[] {
  return items.filter(
    (item) =>
      !item.id ||
      item.id === currenValue?.id ||
      !values?.find((value) => value?.id === item?.id),
  );
}

export const excludeOtherValues = excludeOtherValuesFunction;
