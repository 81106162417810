import { Selectable } from '../enums/Enum';

export const isSelectable = (x: any): x is Selectable =>
  x?.value !== undefined || x?.label !== undefined;

export const isSelectableArray = (x: any): x is Selectable[] =>
  x instanceof Array && x?.length !== 0 && isSelectable(x[0]);

export const isBoolean = (bool: string) => ['true', 'false'].includes(bool);

export const isDate = (date: string) => /[\d-]+T[\d+-:]+/.test(date);
