// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.json-schema-form .field .full-width .form-group {
  margin-bottom: 0;
}
.json-schema-form .field .full-width input {
  max-width: 100%;
}
.json-schema-form .field .full-width .Select {
  max-width: 100%;
}
.json-schema-form .field .full-width .help-block {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/Phone/main.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAKI,eAAA;AAAJ;AALA;EAQI,eAAA;AAAJ;AARA;EAWI,eAAA;AAAJ","sourcesContent":[".json-schema-form .field .full-width {\n  .form-group {\n    margin-bottom: 0;\n  }\n  input {\n    max-width: 100%;\n  }\n  .Select {\n    max-width: 100%;\n  }\n  .help-block {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
