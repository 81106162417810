import React, { useEffect, useMemo, useState } from 'react';

import {
  EmployeeRole,
  Enum,
  Language,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, EmployeeGroup } from '@vestahealthcare/common/models';
import DashPermission from '@vestahealthcare/common/models/DashPermission';
import { getDiff } from '@vestahealthcare/common/utils/api';

import {
  Button,
  CollapsableSidebar,
  Modal,
  Select,
  SwitchGroup,
  TextInput,
} from 'styleguide-v2';
import { SwitchItem } from 'styleguide-v2/src/components/Switch/SwitchGroup';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  EmployeeCreateParam,
  EmployeeUpdateParam,
  fetchPaginatedEmployees,
  updateEmployeeLeads,
} from 'dash/src/services/EmployeeServices';
import Session from 'dash/src/services/SessionServices';

type EmployeeKeys = keyof EmployeeUpdateParam;

type Props = {
  employee?: Employee;
  employees?: Employee[];
  open: boolean;
  onClose: () => void;
  onCreate: (params: EmployeeCreateParam) => Promise<void>;
  onEdit: (id: number, params: Partial<EmployeeUpdateParam>) => Promise<void>;
};

const VESTA_EMAIL = '@vestahealthcare.com';

export const EmployeeEditModal = ({
  employee,
  employees,
  onClose,
  onCreate,
  onEdit,
  open,
}: Props) => {
  const { actingUser } = Session;

  const disabledFields: EmployeeKeys[] = useMemo(() => {
    const commonFields = [
      'dashPermissionId',
      'ecwId',
      'email',
      'employeeGroupIds',
      'enabled',
      'firstName',
      'lastName',
      'phiAccess',
      'sfId',
    ] as EmployeeKeys[];
    if (actingUser.role === EmployeeRole.ADMIN) return [];
    if (actingUser.isPodManager)
      return [
        ...commonFields,
        ...(actingUser.role !== EmployeeRole.ADMIN &&
        employee?.role === EmployeeRole.ADMIN
          ? (['role'] as EmployeeKeys[])
          : []),
      ];
    if (actingUser.isInLicensureAdministration)
      return [...commonFields, 'role', 'incidentReviewRequired', 'isLead'];

    return [];
  }, [employee]);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [updateLeadWarningCount, setUpdateLeadWarningCount] = useState<number>(
    0,
  );

  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const [nlcStates, setNLCStates] = useState<States[]>([]);
  const [permissions, setPermissions] = useState<DashPermission[]>([]);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [ecwId, setEcwId] = useState<string>();
  const [sfId, setSfId] = useState<number>();
  const [group, setGroup] = useState<EmployeeGroup[]>([]);
  const [role, setRole] = useState<EmployeeRole>();
  const [permission, setPermission] = useState<DashPermission>();
  const [states, setStates] = useState<States[]>([]);
  const [phi, setPhi] = useState<boolean>(false);
  const [incidents, setIncidents] = useState<boolean>(true);
  const [lead, setLead] = useState<boolean>(false);
  const [employeeLead, setEmployeeLead] = useState<Employee>();
  const [enabled, setEnabled] = useState<boolean>(true);
  const [nlc, setNLC] = useState<boolean>(false);
  const [languages, setLanguages] = useState<Language[]>([]);

  const [employeeLeadReplacement, setEmployeeLeadReplacement] = useState<
    Employee
  >();

  const leads = useMemo(
    () => employees?.filter(({ id }) => id !== employee?.id),
    [employees, employee],
  );

  const getInitialData = async () => {
    const [groups, permissions, nlcStates, languages] = await Promise.all([
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getDashPermissions(),
      CacheServices.getEmployeeNLCStates(),
      CacheServices.getLanguages(),
    ]);
    setGroups(groups);
    setNLCStates(nlcStates);
    setSupportedLanguages(languages);
    setPermissions(permissions);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setLoadingButton(false);

      setUpdateLeadWarningCount(0);
      setEmployeeLeadReplacement(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (employee?.id) {
      setEcwId(employee.ecwId);
      setEmail(employee.email);
      setEmployeeLead(employee.employeeLead);
      setEnabled(employee.enabled);
      setFirstName(employee.firstName);
      setGroup(employee.activeGroups || []);
      setIncidents(employee.incidentReviewRequired);
      setLanguages(employee.languages);
      setLastName(employee.lastName);
      setLead(employee.lead);
      setNLC(employee.nlc);
      setPermission(
        employee.dashPermissions ? employee.dashPermissions[0] : undefined,
      );
      setPhi(employee.phiAccess);
      setRole(employee.role);
      setSfId(employee.sfId);
      setStates(employee.licences || []);
    } else {
      setEcwId('');
      setEmail('');
      setEmployeeLead(undefined);
      setFirstName('');
      setIncidents(true);
      setLanguages([]);
      setLastName('');
      setLead(false);
      setNLC(false);
      setRole(undefined);
      setSfId(undefined);
    }
  }, [employee]);

  const validateCreate = () =>
    firstName &&
    lastName &&
    email?.endsWith(VESTA_EMAIL) &&
    role &&
    permission &&
    (!incidents || lead || employeeLead);

  const shouldUpdateEmployeeLeads =
    employee?.lead && employee?.enabled && (!enabled || !lead);

  const removeDisabledParams = (
    currentEmployee: Employee,
    disabledParams: EmployeeKeys[],
  ) => {
    const params = getDiff<Partial<EmployeeUpdateParam>>(
      {
        dashPermissionId:
          (currentEmployee.dashPermissions &&
            currentEmployee.dashPermissions[0]) ||
          undefined,
        employeeGroupIds: currentEmployee.employeeGroups,
        employeeLeadId: currentEmployee.employeeLead?.id || null,
        licenceStates: currentEmployee.licences,
        sfId: currentEmployee.sfId || 0,
        ...currentEmployee,
      },
      {
        dashPermissionId: permission,
        enabled,
        email,
        employeeGroupIds: group,
        employeeLeadId: employeeLead?.id || null,
        firstName,
        incidentReviewRequired: incidents,
        lastName,
        isLead: lead,
        licenceStates: states.filter(
          (state) => !nlc || !nlcStates.includes(state),
        ),
        languages,
        phiAccess: phi,
        role,
        ecwId: ecwId || undefined,
        sfId: sfId || 0,
        nlc,
      },
    );

    disabledParams.forEach((key) => delete params[key]);

    return params;
  };

  const doSubmit = async () => {
    setSubmitted(true);

    if (!validateCreate()) return;

    if (employee?.id) {
      if (role && permission) {
        setLoadingButton(true);
        try {
          await onEdit(
            employee.id,
            removeDisabledParams(employee, disabledFields),
          );
          onClose();
        } catch (e) {
          showGlobalError(e as string);
        }
        setLoadingButton(false);
      }
    } else if (firstName && lastName && email && role && permission) {
      setLoadingButton(true);
      try {
        await onCreate({
          dashPermissionId: permission,
          email,
          employeeGroupIds: group,
          firstName,
          lastName,
          licenceStates: states.filter(
            (state) => !nlc || !nlcStates.includes(state),
          ),
          phiAccess: phi,
          role,
          ecwId: ecwId || undefined,
          sfId: sfId || 0,
          nlc,
        });
        onClose();
      } catch (e) {
        showGlobalError(e as string);
      }
      setLoadingButton(false);
    }
  };

  const checkEmployeeLead = async () => {
    setSubmitted(true);
    if (!validateCreate()) return;

    if (employee && shouldUpdateEmployeeLeads) {
      setLoadingButton(true);
      const { pagination } = await fetchPaginatedEmployees({
        count: true,
        employeeLeadId: employee.id,
        enabled: true,
      });
      if (pagination.total !== 0) {
        setUpdateLeadWarningCount(pagination.total);
        setLoadingButton(false);
      } else {
        doSubmit();
      }
    } else {
      await doSubmit();
    }
  };

  const replaceEmployeeLead = async () => {
    if (!employee || !employeeLeadReplacement) return;
    await updateEmployeeLeads({
      oldLeadId: employee.id,
      newLeadId: employeeLeadReplacement.id,
    });
    await doSubmit();
  };

  return (
    <>
      <CollapsableSidebar
        title={
          <h2>
            {employee?.id
              ? `${translate('global.edit')} ${employee.fullName}`
              : translate('employees.modal.titleAdd')}
          </h2>
        }
        onClose={onClose}
        open={open}
        size={550}
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper fit">
            <TextInput
              className="grid-span-6"
              data-cy="employees-modal-first-name"
              disabled={disabledFields.includes('firstName')}
              error={submitted && !firstName}
              label={translate('employees.modal.firstName')}
              onChange={setFirstName}
              value={firstName}
              required
            />
            <TextInput
              className="grid-span-6"
              data-cy="employees-modal-last-name"
              disabled={disabledFields.includes('lastName')}
              error={submitted && !lastName}
              label={translate('employees.modal.lastName')}
              onChange={setLastName}
              value={lastName}
              required
            />
            <TextInput
              className="grid-span-12"
              data-cy="employees-modal-email"
              disabled={disabledFields.includes('email')}
              error={
                submitted &&
                (email
                  ? !email?.endsWith(VESTA_EMAIL) &&
                    translate('employees.modal.emailError')
                  : true)
              }
              label={translate('employees.modal.email')}
              onChange={setEmail}
              value={email}
              required
            />
            <Select
              className="grid-span-6"
              data-cy="employees-modal-role"
              disabled={disabledFields.includes('role')}
              error={submitted && !role}
              getItemDisabled={(role: Selectable) =>
                role.value === EmployeeRole.ADMIN.value &&
                actingUser.role !== EmployeeRole.ADMIN
              }
              items={Enum.toSelectable(
                EmployeeRole.asArray,
              ).sort(({ label: a }, { label: b }) =>
                (a as string).localeCompare(b as string),
              )}
              label={translate('employees.modal.role')}
              onChange={(val: Selectable) =>
                setRole(val ? EmployeeRole.byKey[val.value] : undefined)
              }
              value={role ? Enum.toSelectable([role])[0] : undefined}
              required
            />

            <Select
              className="grid-span-6"
              data-cy="employees-modal-permission"
              disabled={disabledFields.includes('dashPermissionId')}
              error={submitted && !permission}
              getItemLabel={({ name }: DashPermission) =>
                `${name.charAt(0).toLocaleUpperCase()}${name
                  .substring(1)
                  .toLocaleLowerCase()}`.replace('_', ' ')
              }
              items={permissions}
              label={translate('employees.modal.permission')}
              onChange={setPermission}
              value={permission}
              required
            />

            <Select
              className="grid-span-12"
              data-cy="employees-modal-group"
              disabled={disabledFields.includes('employeeGroupIds')}
              getItemLabel={({ name }: EmployeeGroup) => name}
              items={groups}
              multiple
              label={translate('employees.modal.groups')}
              onChange={setGroup}
              value={group}
            />

            <Select
              className="grid-span-12"
              data-cy="employees-modal-state"
              disabled={disabledFields.includes('licenceStates')}
              getItemDisabled={(state: Selectable) =>
                nlc &&
                nlcStates
                  .map(({ value }) => value)
                  .includes(state.value as string)
              }
              items={States.toSelectable(States.asArray)}
              multiple
              label={translate('employees.modal.state')}
              onChange={(val: Selectable[]) =>
                setStates(val?.map(({ value }) => States.byKey[value]) || [])
              }
              value={Enum.toSelectable(
                states.filter((state) => !nlc || !nlcStates.includes(state)),
              )}
            />
            <SwitchGroup
              className="grid-span-12"
              items={[
                {
                  label: translate('employees.modal.nlc'),
                  checked: nlc,
                  disabled: disabledFields.includes('nlc'),
                },
              ]}
              onChange={(items) => {
                setNLC(!!items[0].checked);
              }}
            />
            <Select
              className="grid-span-6"
              data-cy="employees-modal-language"
              disabled={disabledFields.includes('languages')}
              grouped={({ preferred }) => (preferred ? 'Preferred' : 'Other')}
              items={Language.toSelectable(supportedLanguages).filter(
                ({ disabled }) => !disabled,
              )}
              label={translate('employees.modal.language')}
              limitTags={1}
              multiple
              onChange={(val: Selectable[]) =>
                setLanguages(
                  val?.map(({ value }) => Language.byKey[value]) || [],
                )
              }
              value={Enum.toSelectable(languages)}
            />
            <Select
              className="grid-span-6"
              data-cy="employees-modal-lead"
              disabled={disabledFields.includes('employeeLeadId')}
              error={submitted && incidents && !lead && !employeeLead}
              getItemLabel={({ fullName }: Employee) => fullName}
              items={leads || []}
              label={translate('employees.modal.employeeLead')}
              onChange={setEmployeeLead}
              value={employeeLead}
              required={incidents && !lead}
            />
            <TextInput
              className="grid-span-6"
              data-cy="employees-modal-ecwId"
              disabled={disabledFields.includes('ecwId')}
              label={translate('employees.modal.ecwId')}
              onChange={setEcwId}
              value={ecwId}
            />
            <TextInput
              className="grid-span-6"
              disabled={disabledFields.includes('sfId')}
              type="number"
              data-cy="employees-modal-sfId"
              label={translate('employees.modal.sfId')}
              onChange={(newValue?: string) => {
                newValue && setSfId(parseInt(newValue, 10));
              }}
              value={sfId}
            />
            <SwitchGroup
              className="grid-span-12"
              items={
                [
                  {
                    label: translate('employees.modal.phi'),
                    checked: phi,
                    disabled: disabledFields.includes('phiAccess'),
                  },
                  {
                    label: translate('employees.modal.incidents'),
                    checked: incidents,
                    disabled: disabledFields.includes('incidentReviewRequired'),
                  },
                  {
                    label: translate('employees.modal.lead'),
                    checked: lead,
                    disabled: disabledFields.includes('isLead'),
                  },
                  employee?.id
                    ? {
                        label: translate('employees.modal.enabled'),
                        checked: enabled,
                        disabled: disabledFields.includes('enabled'),
                      }
                    : undefined,
                ].filter(Boolean) as SwitchItem[]
              }
              onChange={(items) => {
                setPhi(!!items[0].checked);
                setIncidents(!!items[1].checked);
                setLead(!!items[2].checked);
                if (employee?.id) {
                  setEnabled(!!items[3].checked);
                }
              }}
            />
          </div>
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" data-cy="edit-group-close" onClick={onClose}>
            {translate('global.close')}
          </Button>
          <Button
            color="secondary"
            data-cy="edit-group-submit"
            loading={loadingButton}
            onClick={checkEmployeeLead}
          >
            {employee?.id ? translate('global.save') : translate('global.add')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <Modal
        color="warning"
        title={translate('global.warning')}
        open={!!updateLeadWarningCount}
        onClose={() => setUpdateLeadWarningCount(0)}
        onSubmit={replaceEmployeeLead}
        submitDisabled={!employeeLeadReplacement}
      >
        <p>
          {translate('employees.modal.updateLeadWarning', {
            employee: `${firstName} ${lastName}`,
            count: updateLeadWarningCount,
          })}
        </p>
        <Select
          data-cy="employees-modal-lead-warning"
          getItemLabel={({ fullName }: Employee) => fullName}
          items={leads || []}
          label={translate('employees.modal.employeeLead')}
          onChange={setEmployeeLeadReplacement}
          value={employeeLeadReplacement}
          required
        />
      </Modal>
    </>
  );
};

export default EmployeeEditModal;
