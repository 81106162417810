import React, { useState } from 'react';

import { Card, CardContent } from '@mui/material';
import { PieValueType } from '@mui/x-charts';

import { Button, Panel, PieChart } from 'styleguide-v2';

const getNumber = () => Math.round(Math.random() * 30);

const generateSerie = () => {
  const items = Math.round(Math.random() * 4.25 + 1.75);
  const serie = [];
  for (let i = 1; i <= items; i += 1) {
    serie.push({ id: `serie-${i}`, value: getNumber(), label: `Serie ${i}` });
  }
  return serie.filter(({ value }) => value !== 0);
};

const StyleguideCharts = () => {
  const [refresh, setRefresh] = useState(1);
  return (
    <Panel>
      <Panel.Heading title="Charts">
        <Panel.Actions>
          <Button
            className="grid-span-4"
            color="secondary"
            type="outlined"
            onClick={() => setRefresh(refresh + 1)}
          >
            Randomize
          </Button>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body className="grid-wrapper">
        <Card
          className="grid-span-12"
          sx={{ padding: '2rem', height: 'max-content' }}
        >
          <CardContent className="grid-wrapper">
            <h4 className="grid-span-12">Pie Chart</h4>
            <h6 className="grid-span-12">Sizes</h6>
            <div className="flex grid-span-12 spaced wrap">
              <PieChart size="xs" data={generateSerie()} />
              <PieChart size="s" data={generateSerie()} />
              <PieChart data={generateSerie()} />
              <PieChart size="l" data={generateSerie()} />
              <PieChart size="xl" data={generateSerie()} />
            </div>
            <div className="flex grid-span-12 spaced wrap">
              <div>
                <h6 className="grid-span-12">Percentage</h6>
                <PieChart
                  data={generateSerie()}
                  getTooltipValue={(
                    value: Partial<PieValueType>,
                    _: number,
                    percentage: number,
                  ) => `${value.value} (${percentage}%)`}
                  showPercentage
                />
              </div>
              <div>
                <h6 className="grid-span-12">Highlight (Hover it)</h6>
                <PieChart data={generateSerie()} showHighlight />
              </div>
              <div>
                <h6 className="grid-span-12">Type Outlined</h6>
                <PieChart data={generateSerie()} type="outlined" />
              </div>
              <div>
                <h6 className="grid-span-12">Color Status</h6>
                <PieChart color="status" data={generateSerie()} />
              </div>
              <div>
                <h6 className="grid-span-12">Legend</h6>
                <PieChart
                  data={[
                    { id: 's-a', value: getNumber(), label: 'Serie A' },
                    { id: 's-b', value: getNumber(), label: 'Serie B' },
                    {
                      id: 's-c',
                      value: getNumber(),
                      label: 'Serie C Suuuper Long 1',
                    },
                    {
                      id: 's-d',
                      value: getNumber(),
                      label: 'Serie C Suuuper Long 2',
                    },
                    {
                      id: 's-e',
                      value: getNumber(),
                      label: 'Serie C Suuuper Long 3',
                    },
                  ]}
                  showLegend
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </Panel.Body>
    </Panel>
  );
};

export default StyleguideCharts;
