// @ts-ignore
import qs from 'qs';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { BaseEnum } from '@vestahealthcare/common/models';
import {
  LoadFilterOption as LoadFilterOptionProxy,
  getFilterItem as getFilterItemProxy,
  getFilterValue as getFilterValueProxy,
  getFiltersEnabled as getFiltersEnabledProxy,
  getServerFilters as getServerFiltersProxy,
  loadFiltersFromStorage as loadNewFiltersFromStorageProxy,
  loadFilters as loadNewFiltersProxy,
  saveFilters as saveNewFiltersProxy,
  saveFiltersToStorage as saveNewFiltersToStorageProxy,
} from '@vestahealthcare/common/utils/filter';
import { isSelectable } from '@vestahealthcare/common/utils/type';

import { FilterItem, FilterSelectable } from 'styleguide-v2';

export const getStoredFilters = (key: string) => {
  const filtersString = localStorage.getItem(key) || '';
  return qs.parse(filtersString) || {};
};

export const getAsQuery = (filters: any) =>
  qs.stringify(filters, { arrayFormat: 'repeat' });

export const saveStoredFilters = (key: string, filters: any) => {
  localStorage.setItem(key, qs.stringify(filters));
};

export const getDateRange = (
  query: URLSearchParams,
  filters: any,
  field: string,
) => {
  const fieldString = query.get(field) || filters[field];
  return isNaN(Number(fieldString)) ? fieldString : Number(fieldString);
};

export const getBoolean = (
  query: URLSearchParams,
  filters: any,
  field: string,
) => {
  const fieldString = query.get(field) || filters[field] || null;
  return fieldString !== null ? fieldString === 'true' : undefined;
};

export const getCustom = (
  query?: string[],
  storage?: string[],
  mapper?: (value: string) => any,
) => {
  let result: string[] = [];
  if (query instanceof Array && query.length) {
    result = query;
  } else if (storage instanceof Array && storage.length) {
    result = storage;
  }
  return result.map(mapper || ((x) => x));
};

export const getSafeValues = (
  array: (Selectable | { id: number | string })[],
  ids?: number[] | string[],
) =>
  ids
    ?.map((filterId: string | number) =>
      array.find((item) =>
        isSelectable(item) ? item?.value === filterId : item?.id === filterId,
      ),
    )
    .filter(Boolean) || [];

export const getFilterCount = (
  filters?: { [x: string]: FilterItem },
  keys?: string[],
) =>
  Object.entries(filters || {}).reduce((acc, [key, value]) => {
    let current = 0;
    if (!keys?.length || keys.includes(key)) {
      if (value?.value instanceof Array) {
        current = value?.value?.length ? 1 : 0;
      } else if (typeof value?.value === 'boolean') {
        current = value?.value !== undefined ? 1 : 0;
      } else {
        current = value?.value ? 1 : 0;
      }
    }
    return acc + current;
  }, 0);

export const getBaseEnumFromFilter = (
  item?: FilterSelectable | FilterSelectable[],
) => {
  if (!item) return undefined;
  if (item instanceof Array) {
    return item.map(
      ({ value, label }) => new BaseEnum({ id: value, description: label }),
    );
  }

  return new BaseEnum({ id: item.value, description: item.label });
};

export const getFilterItem = getFilterItemProxy;
export const getFiltersEnabled = getFiltersEnabledProxy;
export const getFilterValue = getFilterValueProxy;
export const getServerFilters = getServerFiltersProxy;
export const saveNewFilters = saveNewFiltersProxy;
export const loadNewFilters = loadNewFiltersProxy;
export const loadNewFiltersFromStorage = loadNewFiltersFromStorageProxy;
export const saveNewFiltersToStorage = saveNewFiltersToStorageProxy;
export type LoadFilterOption = LoadFilterOptionProxy;
