import { Moment } from 'moment';

import { Employee } from './Employee';
import { make, makeMoment } from './Model';

export class MemberDashboardBiannualScheduleInfo {
  dueForBiannual = false;

  visitScheduled = false;

  visitScheduledEducationProvided = '';

  visitScheduledVideoVisitHost = '';

  visitScheduledConnectionTested = '';

  visitNotScheduledReason = '';

  date: Moment;

  employee: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.date = makeMoment(obj.date) as Moment;
    this.employee = make(obj.employee, Employee);
  }
}

export default MemberDashboardBiannualScheduleInfo;
