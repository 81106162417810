import React, { useContext, useState } from 'react';
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';

import { VestaChatContext } from 'dash/src/contexts/VestaChatContext';

import ChannelHeader from './ChannelHeader';
import ChannelListHeader from './ChannelListHeader';
import './main.less';
import './stream-style.less';
import { ChannelFilters } from 'stream-chat';

export interface ChatFilters {
  type: string;
  name?: ChannelFilters;
  memberId?: number;
  rnOwnerId?: number;
  closed?: ChannelFilters;
}

interface Props {
  memberId?: string | null;
  rnOwnerId?: string | null;
  name?: string | null;
  closed?: string | null;
}

const VestaChat = (props: Props) => {
  const { chatClient, channels } = useContext(VestaChatContext);
  const [refresh, setRefresh] = useState<number>(0);
  const filters = {
    type: 'messaging',
    memberId: props.memberId ? +props.memberId : undefined,
    rnOwnerId: props.rnOwnerId ? +props.rnOwnerId : undefined,
    closed: props.closed ? { $eq: true } : { $ne: true },
    name: props.name ? { $autocomplete: props.name } : undefined,
  };

  if (!channels || !chatClient) {
    return <p>Loading...</p>;
  }

  const refreshChannels = () => setTimeout(() => setRefresh(refresh + 1), 100);

  return (
    <Chat client={chatClient} theme="vesta">
      <ChannelListHeader filters={filters} refresh={refresh} />
      <Channel
        doSendMessageRequest={(channelId, message, options) => {
          const channel = chatClient?.activeChannels[channelId];
          if (channel && channel.data?.closed) {
            channel.updatePartial({ unset: ['closed'] }).then(refreshChannels);
          }
          channel?.sendMessage(message, options);
        }}
      >
        <Window>
          <ChannelHeader
            memberFilter={!!filters.memberId}
            onCloseConversation={(channel) =>
              channel
                .updatePartial({ set: { closed: true } })
                .then(refreshChannels)
            }
          />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default VestaChat;
