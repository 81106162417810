// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-select {
  display: flex;
  flex-direction: column;
}
.image-select .image-options {
  display: flex;
  flex-direction: row;
}
.image-select .image-options .image-option {
  display: inline-flex;
  flex: 0 0 120px;
}
.image-select .image-options .image-option > img {
  width: 60px;
  height: 60px;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/ImageSelect/main.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAHA;EAKI,aAAA;EACA,mBAAA;AACJ;AAPA;EASM,oBAAA;EACA,eAAA;AACN;AAXA;EAaQ,WAAA;EACA,YAAA;AACR","sourcesContent":[".image-select {\n  display: flex;\n  flex-direction: column;\n\n  .image-options {\n    display: flex;\n    flex-direction: row;\n\n    .image-option {\n      display: inline-flex;\n      flex: 0 0 120px;\n\n      > img {\n        width: 60px;\n        height: 60px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
