import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { CollapseIcon } from '../CollapseIcon';
import { Spinner } from '../Spinner';

type Props = {
  className?: string;
  count?: number;
  label?: string;
  loading?: boolean;
  onClick: () => void;
  open: boolean;
};

const useStyles = makeStyles({
  seeMore: {
    color: Colors.textGreen,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
  },
});

export const SeeMore = ({
  className,
  count = 0,
  label,
  loading,
  onClick,
  open,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={classNames(className, 'flex spaced')}>
      <a
        aria-hidden="true"
        className={classNames('flex middle min-gap', styles.seeMore)}
        href="/"
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          onClick();
        }}
      >
        <CollapseIcon open={open} htmlColor={Colors.textGreen} />
        {loading ? (
          <Spinner text="Loading" />
        ) : (
          label ||
          translate(`components.SeeMore.${open ? 'hide' : 'see'}More`, {
            count,
          })
        )}
      </a>
    </div>
  );
};
