import { Enum } from './Enum';

export class ProgramExtensionStatusReason extends Enum {
  translationKey = 'programExtensionStatusReason';

  static asArray: ProgramExtensionStatusReason[] = [];

  static byKey: { [name: string]: ProgramExtensionStatusReason } = {};

  static readonly DISENROLL_REQUEST = new ProgramExtensionStatusReason(
    'DISENROLL_REQUEST',
  );

  static readonly INPATIENT = new ProgramExtensionStatusReason('INPATIENT');

  static readonly TRAVELING = new ProgramExtensionStatusReason('TRAVELING');

  static readonly OTHER = new ProgramExtensionStatusReason('OTHER');

  constructor(public readonly value: string) {
    super();
    ProgramExtensionStatusReason.asArray.push(this);
    ProgramExtensionStatusReason.byKey[value] = this;
  }
}

export default ProgramExtensionStatusReason;
