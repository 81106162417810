import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import {
  CollectionMethod,
  Enum,
  Language,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import TaskStatus from '@vestahealthcare/common/enums/TaskStatus';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  Organization,
  PaginationType,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';
import {
  LS_DASHBOARD_TASK_FILTERS,
  LS_DASHBOARD_TASK_FILTERS_V2,
} from '@vestahealthcare/common/utils/constants';

import {
  FilterItem,
  Panel,
  Spinner,
  Tabs,
  ToggleDateRange,
  ToggleDateRangeHandle,
} from 'styleguide-v2';

import AddTaskButton from 'dash/src/pages/Tasks/AddTaskButton';
import TaskCompleteDetails from 'dash/src/pages/Tasks/TaskCompleteDetails';
import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import Session from 'dash/src/services/SessionServices';
import {
  TaskFetchParams,
  fetchAllTasks,
  fetchTask,
} from 'dash/src/services/TaskServices';
import {
  transformDateToDaysRange,
  transformDaysRangeToDates,
} from 'dash/src/utils/dateUtils';
import {
  getAsQuery,
  getBoolean,
  getDateRange,
  getFiltersEnabled,
  getServerFilters,
  getStoredFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { EVENTS_PAGE_TAB_OPEN } from '../Events/constants';
import ClinicalTaskTable, { TaskTable } from './ClinicalTaskTable';
import { TaskFiltersBar } from './TaskFiltersBar';
import { TaskFiltersModal } from './TaskFiltersModal';

const INITIAL_PAGE_SIZE = 50;
const DEFAULT_SORTING = 'dueDate asc';

const UNASSIGNED = new Employee({ fullName: translate('global.unassigned') });

const TASKS_PAGE_TAB_ASSIGNED_ME = 'tab-assigned';
const TASKS_PAGE_TAB_OPEN = 'tab-open';
const TASKS_PAGE_TAB_CLOSED = 'tab-closed';
const TASKS_PAGE_TAB_ALL = 'tab-all';

const TASK_PAGE_TABS = [
  {
    label: translate('clinicalDashboard.tasks.tabs.all'),
    value: TASKS_PAGE_TAB_ALL,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.open'),
    value: TASKS_PAGE_TAB_OPEN,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.closed'),
    value: TASKS_PAGE_TAB_CLOSED,
  },
  {
    label: translate('clinicalDashboard.tasks.tabs.assigned'),
    value: TASKS_PAGE_TAB_ASSIGNED_ME,
  },
];

const PROGRAM_EXTENSION_UNASSIGNED = {
  label: translate('global.unassigned'),
  value: -1,
};

const useStyles = makeStyles({
  check: {
    alignSelf: 'center',
  },
  dateRange: {
    marginTop: '-2.5rem',
  },
  filterButton: {
    marginTop: '.25rem',
  },
  filters: {
    alignItems: 'flex-start',
  },
  search: {
    alignSelf: 'center',
    minWidth: '45rem',
  },
  staticFilters: {
    alignItems: 'flex-start',
    gap: '1rem',
    marginBottom: '1rem',
    marginLeft: '10.55rem',
  },
});

type TaskFilters = {
  assignee?: number[];
  brand?: string[];
  completedAtFrom?: number;
  completedAtTo?: number;
  createdAtFrom?: number;
  createdAtTo?: number;
  createdBy?: number[];
  dueDateFrom?: number;
  dueDateTo?: number;
  group?: number[];
  hasProgramExtensions?: boolean;
  language?: Language[];
  organization?: number[];
  programExtensionId?: number[];
  programExtensionStatus?: string[];
  queryString?: string;
  selectedTab?: string;
  status?: TaskStatus[];
  subTasksOnly: boolean;
  topLevelOnly: boolean;
  type?: number[];
  vitalCollectionMethod?: CollectionMethod[];
};

type KeyGetTaskParams = keyof TaskFetchParams;

const getFilters = (query: URLSearchParams) => {
  const filters = getStoredFilters(LS_DASHBOARD_TASK_FILTERS);
  const defaultCompletedDateRange =
    getDateRange(query, filters, 'completedDateRange') || -1;

  const defaultCreatedDateRange =
    getDateRange(query, filters, 'createdDateRange') || -1;
  const defaultDueDateRange =
    getDateRange(query, filters, 'dueDateRange') || -1;
  const subTasksOnly = getBoolean(query, filters, 'subTasksOnly') || false;
  const topLevelOnly = getBoolean(query, filters, 'topLevelOnly') || true;

  const completedAt = transformDaysRangeToDates(defaultCompletedDateRange);
  const createdAt = transformDaysRangeToDates(defaultCreatedDateRange);
  const dueDate = transformDaysRangeToDates(defaultDueDateRange);

  return {
    completedAtFrom: completedAt[0]?.getTime(),
    completedAtTo: completedAt[1]?.getTime(),
    createdAtFrom: createdAt[0]?.getTime(),
    createdAtTo: createdAt[1]?.getTime(),
    dueDateFrom: dueDate[0]?.getTime(),
    dueDateTo: dueDate[1]?.getTime(),
    defaultCompletedDateRange,
    defaultCreatedDateRange,
    defaultDueDateRange,
    queryString: query.get('queryString') || filters.queryString,
    selectedTab:
      query.get('selectedTab') || filters.selectedTab || TASKS_PAGE_TAB_ALL,
    sort: query.get('sort') || filters.sort || DEFAULT_SORTING,
    subTasksOnly,
    topLevelOnly,
  };
};

type Props = {
  taskId?: string;
};

export const ClinicalTasks = ({
  match: {
    params: { taskId },
  },
}: RouteComponentProps<Props>) => {
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [isOpenFilters, setOpenFilters] = useState<boolean>(false);

  const refCreated = useRef<ToggleDateRangeHandle>(null);
  const refCompleted = useRef<ToggleDateRangeHandle>(null);
  const refDue = useRef<ToggleDateRangeHandle>(null);

  const [types, setTypes] = useState<TaskDefinition[]>([]);
  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [supportedLanguages, setLanguages] = useState<Language[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    Selectable[]
  >([]);

  const {
    defaultCreatedDateRange,
    defaultCompletedDateRange,
    defaultDueDateRange,
    selectedTab: st,
    sort: defaultSort,
    ...rest
  } = getFilters(query);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [pagination, setPagination] = useState<PaginationType>();
  const [sort, setSort] = useState<string>(defaultSort as string);

  const [selectedTab, setSelectedTab] = useState<string>(st as string);
  const [filters, setFilters] = useState<TaskFilters>(rest as TaskFilters);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetTaskParams]?: FilterItem;
    }
  >();
  const [disabledFilters, setDisabledFilters] = useState<KeyGetTaskParams[]>(
    [],
  );

  const [tasks, setTasks] = useState<TaskTable[]>([]);

  const getTabFilters = (tab: string) => {
    const dateFilters = {
      completedAtFrom: filters?.completedAtFrom
        ? moment(filters?.completedAtFrom).startOf('day').format()
        : undefined,
      completedAtTo: filters?.completedAtTo
        ? moment(filters?.completedAtTo).endOf('day').format()
        : undefined,
      createdAtFrom: filters?.createdAtFrom
        ? moment(filters?.createdAtFrom).startOf('day').format()
        : undefined,
      createdAtTo: filters?.createdAtTo
        ? moment(filters?.createdAtTo).endOf('day').format()
        : undefined,
      dueDateFrom: filters?.dueDateFrom
        ? moment(filters?.dueDateFrom).startOf('day').format()
        : undefined,
      dueDateTo: filters?.dueDateTo
        ? moment(filters?.dueDateTo).endOf('day').format()
        : undefined,
    };
    const commonFilters: TaskFetchParams = {};
    if (tab === TASKS_PAGE_TAB_OPEN) {
      const {
        completedAtFrom,
        completedAtTo,
        ...restDateFilters
      } = dateFilters;
      return {
        ...commonFilters,
        ...restDateFilters,
        status: TaskStatus.getNotClosedStatuses(),
      };
    }
    if (tab === TASKS_PAGE_TAB_CLOSED) {
      return {
        ...commonFilters,
        ...dateFilters,
        status: TaskStatus.getClosedStatuses(),
      };
    }
    if (tab === TASKS_PAGE_TAB_ASSIGNED_ME) {
      return {
        ...commonFilters,
        ...dateFilters,
        status: TaskStatus.getNotClosedStatuses(),
        assigneeId: [Session.actingUser.id],
      };
    }
    return {
      ...commonFilters,
      ...dateFilters,
    };
  };

  const getInitialData = async () => {
    setLoadingFilters(true);
    const [
      types,
      groups,
      employees,
      organizations,
      languages,
      brands,
      programExtensions,
      programExtensionStatuses,
    ] = await Promise.all([
      CacheServices.getTaskDefinitions(),
      CacheServices.getEmployeeGroupsAssignee(),
      CacheServices.getEmployees(),
      CacheServices.getOrganizations(),
      fetchSupportedLanguages(),
      CacheServices.getOrganizationBrands(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
    ]);
    setTypes(types || []);
    setGroups(groups || []);
    setEmployees(employees || []);
    setOrganizations(
      organizations.filter(({ hasValidDate }) => hasValidDate) || [],
    );
    setLanguages(languages);
    setBrands(
      brands.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setProgramExtensions([
      PROGRAM_EXTENSION_UNASSIGNED as Selectable,
      ...ProgramExtension.toSelectable(programExtensions),
    ]);
    setProgramExtensionStatuses(
      ProgramExtensionStatus.toSelectable(programExtensionStatuses),
    );
    setLoadingFilters(false);
  };

  const getTasks = async () => {
    setLoading(true);

    if (!newFilters) return;
    const { queryString, ...otherFilters } = getServerFilters(newFilters);
    const currentFilters = {
      ...otherFilters,
      ...getTabFilters(selectedTab),
      queryString,
    };

    if (currentFilters.programExtensionId?.length) {
      const filteredPEs = currentFilters.programExtensionId?.filter(
        (id) => id !== -1,
      );
      currentFilters.hasProgramExtensions =
        filteredPEs.length === currentFilters.programExtensionId.length;
      currentFilters.programExtensionId = filteredPEs;
    }
    currentFilters.subTasksOnly =
      filters.topLevelOnly && filters.subTasksOnly
        ? false
        : filters.subTasksOnly;
    currentFilters.topLevelOnly =
      filters.topLevelOnly && filters.subTasksOnly
        ? false
        : filters.topLevelOnly;

    const { items, pagination } = await fetchAllTasks({
      ...currentFilters,
      offset: page * pageSize,
      limit: pageSize,
      sort,
    });
    if (page === 0) {
      setTasks(items);
    } else {
      setTasks([...tasks, ...items]);
    }
    setPagination(pagination);
    setLoading(false);
  };

  const getTaskDetails = async (task: TaskTable, index: number) => {
    const taskItem = tasks[index] || {};
    if (taskItem.open) {
      delete taskItem.detail;
      taskItem.open = false;
    } else {
      taskItem.open = true;
      taskItem.detail = (
        <div className="flex center">
          <Spinner />
        </div>
      );
      setTasks([...tasks]);
      const taskDetail = await fetchTask(task.id);
      tasks[index] = {
        ...taskDetail,
        open: true,
        detail: (
          <TaskCompleteDetails
            task={taskDetail}
            onUpdate={(newInfo) => {
              tasks[index] = { ...tasks[index], ...newInfo } as TaskTable;
              setTasks([...tasks]);
            }}
            showSubtask={!!taskId}
          />
        ),
      } as TaskTable;
    }
    setTasks([...tasks]);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (taskId) {
      getTaskDetails({ id: Number(taskId) } as TaskTable, 0);
    } else {
      getTasks();
    }
  }, [newFilters, filters, page, pageSize, selectedTab, sort]);

  useEffect(() => {
    const {
      completedAtFrom,
      completedAtTo,
      createdAtFrom,
      createdAtTo,
      dueDateFrom,
      dueDateTo,
      language,
      programExtensionStatus,
      status,
      vitalCollectionMethod,
      ...storedFilters
    } = filters;

    const flatFilters = {
      createdDateRange:
        createdAtFrom && createdAtTo
          ? transformDateToDaysRange(
              new Date(createdAtFrom),
              new Date(createdAtTo),
              [7, 30],
            )
          : -1,
      completedDateRange:
        completedAtFrom && completedAtTo
          ? transformDateToDaysRange(
              new Date(completedAtFrom),
              new Date(completedAtTo),
              [7, 30],
            )
          : -1,
      dueDateRange:
        dueDateFrom && dueDateTo
          ? transformDateToDaysRange(
              new Date(dueDateFrom),
              new Date(dueDateTo),
              [7, 30],
            )
          : -1,
      language: language?.map(({ value }) => value),
      status: status?.map(({ value }) => value),
      selectedTab,
      sort: sort !== DEFAULT_SORTING ? sort : undefined,
      vitalCollectionMethod: vitalCollectionMethod?.map(({ value }) => value),
      ...storedFilters,
    };

    const query = getAsQuery(flatFilters);
    history.replace(`?${query}`);
    saveStoredFilters(LS_DASHBOARD_TASK_FILTERS, flatFilters);
  }, [selectedTab, sort, filters]);

  const filtersData = {
    brands,
    employees: [
      ...Employee.toSelectable([UNASSIGNED]),
      ...Employee.toSelectable(employees),
    ],
    employeeGroups: [...EmployeeGroup.toSelectable(groups)],
    languages: Language.toSelectable(supportedLanguages),
    organizations: Organization.toSelectable(organizations),
    programExtensions,
    programExtensionStatuses,
    status: TaskStatus.toSelectable(TaskStatus.asArray),
    taskDefinitionId: TaskDefinition.toSelectable(types),
  };

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(LS_DASHBOARD_TASK_FILTERS_V2, newFilters);
    }
  }, [newFilters]);

  useEffect(() => {
    if (
      brands?.length &&
      employees?.length &&
      groups?.length &&
      organizations?.length &&
      programExtensions?.length &&
      programExtensionStatuses?.length &&
      supportedLanguages?.length &&
      types?.length
    ) {
      const storedFilters = loadNewFiltersFromStorage(
        LS_DASHBOARD_TASK_FILTERS_V2,
        {
          assigneeId: {
            data: filtersData.employees,
            label: translate('clinicalDashboard.tasks.filters.assignee'),
            multiple: true,
          },
          brandId: {
            data: filtersData.brands,
            label: translate('clinicalDashboard.tasks.filters.brand'),
            multiple: true,
          },
          createdById: {
            data: filtersData.employees,
            label: translate('clinicalDashboard.tasks.filters.createdBy'),
            multiple: true,
          },
          employeeGroupId: {
            data: filtersData.employeeGroups,
            label: translate('clinicalDashboard.tasks.filters.assignee'),
            multiple: true,
          },
          language: {
            data: filtersData.languages,
            label: translate('clinicalDashboard.tasks.filters.language'),
            multiple: true,
          },
          programExtensionId: {
            data: filtersData.programExtensions,
            label: translate(
              'clinicalDashboard.tasks.filters.programExtensions',
            ),
            multiple: true,
          },
          programExtensionStatus: {
            data: filtersData.programExtensionStatuses,
            label: translate(
              'clinicalDashboard.tasks.filters.programExtensionStatus',
            ),
            multiple: true,
          },
          queryString: {
            label: translate('tasks.searchTasks'),
          },
          referralSourceId: {
            data: filtersData.organizations,
            label: translate('clinicalDashboard.tasks.filters.organization'),
            multiple: true,
          },
          subTasksOnly: {
            label: translate('tasks.showAllSubTasks'),
          },
          status: {
            data: filtersData.status,
            label: translate('clinicalDashboard.tasks.filters.status'),
            multiple: true,
          },
          taskDefinitionId: {
            data: filtersData.taskDefinitionId,
            label: translate('clinicalDashboard.tasks.filters.type'),
            multiple: true,
          },
          topLevelOnly: {
            default: true,
            label: translate('tasks.showAllParents'),
          },
          vitalCollectionMethod: {
            data: Enum.toSelectable(CollectionMethod.asArray),
            label: translate(
              'clinicalDashboard.tasks.filters.vitalCollectionMethod',
            ),
            multiple: true,
          },
        } as { [x in KeyGetTaskParams]: any },
      );
      setNewFilters(storedFilters);
    }
  }, [
    brands,
    employees,
    groups,
    organizations,
    programExtensions,
    programExtensionStatuses,
    supportedLanguages,
    types,
  ]);

  useEffect(() => {
    const result = [] as KeyGetTaskParams[];
    if ([TASKS_PAGE_TAB_ASSIGNED_ME].includes(selectedTab)) {
      result.push('assigneeId');
      result.push('employeeGroupId');
    }
    if ([TASKS_PAGE_TAB_OPEN, EVENTS_PAGE_TAB_OPEN].includes(selectedTab)) {
      result.push('status');
    }
    setDisabledFilters(result);
  }, [selectedTab]);

  return (
    <Panel>
      <Panel.Heading
        title={translate(
          `clinicalDashboard.tasks.${taskId ? 'detail' : 'title'}`,
        )}
        filtersV2
      >
        {!taskId && (
          <>
            <Panel.Filters
              className={classNames('grid-span-12 flex gap', styles.filters)}
            >
              <ToggleDateRange
                className={styles.dateRange}
                customExpand="vertical"
                defaultValue={defaultCreatedDateRange}
                items={[-1, 7, 30, 'custom']}
                label={translate('clinicalDashboard.tasks.filters.createdAt')}
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== filters.createdAtFrom ||
                    to?.getTime() !== filters.createdAtTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        createdAtFrom: from.getTime(),
                        createdAtTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        createdAtFrom: undefined,
                        createdAtTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                ref={refCreated}
              />
              <ToggleDateRange
                className={styles.dateRange}
                customExpand="vertical"
                defaultValue={defaultDueDateRange}
                items={[-1, 7, 30, 'custom']}
                label={translate('clinicalDashboard.tasks.filters.dueDate')}
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== filters.dueDateFrom ||
                    to?.getTime() !== filters.dueDateTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        dueDateFrom: from.getTime(),
                        dueDateTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        dueDateFrom: undefined,
                        dueDateTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                ref={refDue}
              />
              <ToggleDateRange
                className={styles.dateRange}
                customExpand="vertical"
                defaultValue={defaultCompletedDateRange}
                disabled={
                  [TASKS_PAGE_TAB_OPEN, TASKS_PAGE_TAB_ASSIGNED_ME].indexOf(
                    selectedTab,
                  ) !== -1
                }
                items={[-1, 7, 30, 'custom']}
                label={translate(
                  'clinicalDashboard.tasks.filters.completedDate',
                )}
                onChange={(from, to) => {
                  if (
                    from?.getTime() !== filters.completedAtFrom ||
                    to?.getTime() !== filters.completedAtTo
                  ) {
                    if (from && to) {
                      setFilters({
                        ...filters,
                        completedAtFrom: from.getTime(),
                        completedAtTo: to.getTime(),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        completedAtFrom: undefined,
                        completedAtTo: undefined,
                      });
                    }
                    setPage(0);
                  }
                }}
                ref={refCompleted}
              />
            </Panel.Filters>
            <Panel.Actions>
              <AddTaskButton
                onSubmit={(memberId, taskId) =>
                  history.push({
                    pathname: memberId
                      ? `/patients/${memberId}/tasks`
                      : `/dashboard/tasks/${taskId}`,
                    state: { taskId },
                  })
                }
                v2
              />
            </Panel.Actions>
            <Panel.FilterBar
              onClearFilters={() => {
                setPage(0);
                setNewFilters({});
              }}
              onDeleteFilter={(key: string) => {
                setPage(0);
                setNewFilters({
                  ...newFilters,
                  [key]: undefined,
                });
              }}
              onOpenFilters={() => setOpenFilters(!isOpenFilters)}
              chips={getFiltersEnabled(newFilters, filtersData, [
                ...disabledFilters,
                'dueDate',
                'queryString',
                'subTasksOnly',
                'topLevelOnly',
              ] as KeyGetTaskParams[])}
              inputs={
                <TaskFiltersBar
                  filters={newFilters}
                  onChange={(filters) => {
                    setNewFilters(filters);
                    setPage(0);
                  }}
                />
              }
            />
            <Panel.Tabs>
              <Tabs
                items={TASK_PAGE_TABS}
                disabled={loading}
                onChange={(tab) => {
                  setSelectedTab(tab);
                  setTasks([]);
                  setPage(0);
                }}
                value={selectedTab}
              />
            </Panel.Tabs>
          </>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <TaskFiltersModal
          data={filtersData}
          disabledFilters={disabledFilters}
          filters={newFilters}
          loadingFilters={loadingFilters}
          open={isOpenFilters}
          onChange={(filters) => {
            setPage(0);
            setNewFilters(filters);
          }}
          onClose={() => setOpenFilters(false)}
        />
        <ClinicalTaskTable
          defaultSort={
            defaultSort !== DEFAULT_SORTING
              ? (defaultSort as string)
              : undefined
          }
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onClickRow={getTaskDetails}
          onDefaultSort={() => setSort(DEFAULT_SORTING)}
          onSort={(field, direction) => setSort(`${field} ${direction}`)}
          pagination={pagination}
          tasks={tasks}
        />
      </Panel.Body>
    </Panel>
  );
};

export default ClinicalTasks;
