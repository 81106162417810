import React from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import NoteIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { TimeUnit } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PodRule } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Chip, Colors, IconButton, Table, Tooltip } from 'styleguide-v2';

interface Props {
  rules: PodRule[];
  onAdd: () => void;
  onEdit: (group: PodRule) => void;
}

const renderBoolean = (active: boolean) =>
  active ? (
    <CheckIcon color="primary" sx={{ marginLeft: '1rem' }} />
  ) : (
    <CancelIcon color="error" sx={{ marginLeft: '1rem' }} />
  );

const POD_RULES_COLUMNS = ({ onEdit }: Props) => [
  {
    headerName: translate('pods.rules.common.name'),
    component: ({ name, description }: PodRule) => (
      <p className="no-margin flex gap wrap">
        <span style={{ marginTop: '0.125rem' }}>{name}</span>
        {description && (
          <Tooltip text={description}>
            <NoteIcon htmlColor={Colors.iconGreen} />
          </Tooltip>
        )}
      </p>
    ),
  },
  {
    headerName: translate('pods.rules.common.programExtension'),
    component: ({ programExtension }: PodRule) =>
      programExtension?.abbr || EMPTY,
  },
  {
    headerName: translate('pods.rules.common.roles'),
    component: ({ roles }: PodRule) =>
      roles?.length ? roles?.map((r) => r.toString())?.join(', ') : EMPTY,
  },
  {
    headerName: translate('pods.rules.common.triggers'),
    component: ({ id, triggers }: PodRule) => (
      <div
        className="flex gap wrap"
        key={`pod-rule-${id}-triggers`}
        style={{ alignItems: 'flex-start' }}
      >
        {!triggers?.length && EMPTY}
        {triggers?.map((trigger) => (
          <Chip
            color="secondary"
            key={`pod-rule-${id}-trigger-${trigger.id}`}
            size="medium"
            type="outlined"
          >
            <div className="flex min-gap">
              <span style={{ marginTop: '0.125rem' }}>
                {trigger.toString()}
              </span>
              {trigger.triggerSuccess !== undefined &&
                trigger.triggerSuccess !== null && (
                  <Tooltip
                    text={translate(
                      `common.${
                        trigger.triggerSuccess ? 'completed' : 'incompleted'
                      }`,
                    )}
                  >
                    {trigger.triggerSuccess === true ? (
                      <CheckIcon htmlColor={Colors.iconGreen} />
                    ) : (
                      <CancelIcon htmlColor={Colors.iconGreen} />
                    )}
                  </Tooltip>
                )}
              {!!trigger.delayValue && trigger.delayType && (
                <Tooltip
                  text={`Delayed ${translate(
                    `enums.delayTypeWithValue.${trigger.delayType.value}`,
                    {
                      count: trigger.delayValue,
                    },
                  )}`}
                >
                  {trigger.delayType === TimeUnit.DAYS ? (
                    <ScheduleIcon htmlColor={Colors.iconGreen} />
                  ) : (
                    <CalendarMonthIcon htmlColor={Colors.iconGreen} />
                  )}
                </Tooltip>
              )}
            </div>
          </Chip>
        ))}
      </div>
    ),
  },
  {
    headerName: translate('pods.rules.common.overriddenBy'),
    component: ({ overriddenBy }: PodRule) =>
      overriddenBy?.length
        ? overriddenBy?.map((r) => r.name)?.join(', ')
        : EMPTY,
  },

  {
    headerName: translate('pods.rules.common.active'),
    component: ({ active }: PodRule) => renderBoolean(active),
  },
  {
    headerName: '',
    component: (config: PodRule) => (
      <IconButton size="small" onClick={() => onEdit(config)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const PodRulesTable = (props: Props) => (
  <Table
    config={{
      columns: POD_RULES_COLUMNS(props),
      compact: true,
      data: props.rules,
      paginationOptions: [-1],
    }}
    empty={translate('pods.rules.table.noResults')}
    emptyLink={translate('pods.rules.table.add')}
    fontSize="small"
    onClickEmptyLink={props.onAdd}
  />
);

export default PodRulesTable;
