import { TimeUnit } from '../enums';
import { DBEnum } from './DBEnum';

export class PodRuleTrigger extends DBEnum {
  delayValue = 0;

  delayType: TimeUnit;

  triggerSuccess: boolean | null = null;

  constructor(obj: any) {
    super(obj);
    this.delayValue = obj.delayValue;
    this.delayType = TimeUnit.byKey[obj.delayType];
    this.triggerSuccess = obj.triggerSuccess;
  }

  toString() {
    if (this.description?.match(/[A-Z_]/)) {
      return `${this.description[0]}${this.description
        .substring(1)
        .toLocaleLowerCase()
        .replaceAll('_', ' ')}`;
    }
    return this.description;
  }
}

export default PodRuleTrigger;
