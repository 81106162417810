// @ts-ignore
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
} from '@vestahealthcare/common/enums';
import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CCMTask,
  Employee,
  PaginationType,
} from '@vestahealthcare/common/models';

import { FilterItem, LastUpdated, Panel, Select } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  GetCCMTaskParams,
  completeCCMTask,
  fetchCCMTasks,
} from 'dash/src/services/PodServices';
import Session from 'dash/src/services/SessionServices';
import { getFilterItem, getServerFilters } from 'dash/src/utils/filterUtils';

import { AddEncounterModal } from '../../MemberProfile/Encounters/AddEncounterModal';
import { CCMTaskCompleteModal } from './CCMTaskCompleteModal';
import { CCMTasksTable } from './CCMTaskTable';

type KeyCCMTaskParams = keyof GetCCMTaskParams;

export const CCMTaskDashboard = () => {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [filters, setFilters] = useState<
    Partial<{ [x in KeyCCMTaskParams]: FilterItem }>
  >({});

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Moment>();
  const [tasks, setTasks] = useState<CCMTask[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [emplooyees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);

  const [openCompleteModal, setOpenCompleteModal] = useState<boolean>();
  const [openLogCallModal, setOpenLogCallModal] = useState<boolean>();
  const [modalData, setModalData] = useState<number>();
  const [modalDataTask, setModalDataTask] = useState<number>();

  const showEmployeesFilter = Session.actingUser.isExternal;
  const navigateMembersPage = !Session.actingUser.isExternal;

  const getInitialData = async () => {
    setLoadingData(true);
    const e = await CacheServices.getEmployees();
    setEmployees(Employee.toSelectable(e));
    setLoadingData(false);
  };

  const getTasks = async () => {
    setLoading(true);
    const { items, pagination, lastUpdatedAt } = await fetchCCMTasks({
      ...getServerFilters(filters),
      offset: page * pageSize,
      limit: pageSize,
    });
    setLastUpdatedAt(lastUpdatedAt);
    setPagination(pagination);
    if (page !== 0) {
      setTasks([...tasks, ...items]);
    } else {
      setTasks(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showEmployeesFilter) {
      getInitialData();
    }
  }, []);

  useEffect(() => {
    getTasks();
  }, [filters, page, pageSize]);

  return (
    <Panel id="referrals-section">
      <Panel.Heading title={translate('ccmTasks.title')} filtersV2>
        <Panel.Actions>
          {lastUpdatedAt && <LastUpdated lastUpdatedAt={lastUpdatedAt} />}
        </Panel.Actions>
        {showEmployeesFilter && (
          <Panel.FilterBar
            inputs={
              <Select
                data-cy="ccm-taskl-filter-bar-assignee"
                items={emplooyees}
                loading={loadingData}
                multiple
                onChange={(assignees: SelectableInfo<Employee>[]) =>
                  setFilters({
                    ...filters,
                    assigneeId: getFilterItem(
                      translate('ccmTasks.filters.assignee'),
                      assignees,
                    ),
                  })
                }
                placeholder={translate('ccmTasks.filters.assignee')}
                placeholderV2
                value={filters?.assigneeId?.value}
                size="xs"
              />
            }
          />
        )}
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        <CCMTasksTable
          tasks={tasks}
          pagination={pagination}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onClickRow={
            navigateMembersPage
              ? (task: CCMTask) => history.push(`patients/${task.member?.id}`)
              : undefined
          }
          onComplete={async (task) => {
            setModalData(task.taskId);
            setOpenCompleteModal(true);
          }}
          onLogCall={(task) => {
            setModalDataTask(task.taskId);
            setModalData(task.member.id);
            setOpenLogCallModal(true);
          }}
        />
        <AddEncounterModal
          autofillTime
          avoidRequiredMinutes
          channel={EncounterChannel.CCM_VISIT}
          direction={EncounterDirection.OUTBOUND}
          method={EncounterMethod.CALL}
          type={EncounterType.CCM}
          onClose={() => setOpenLogCallModal(false)}
          onSubmit={async () => {
            await getTasks();
            setOpenLogCallModal(false);
          }}
          open={!!openLogCallModal}
          patient={modalData}
          programTaskId={modalDataTask}
        />
        <CCMTaskCompleteModal
          onClose={() => setOpenCompleteModal(false)}
          onSubmit={async (id, params) => {
            await completeCCMTask(id, params);
            await getTasks();
            setOpenCompleteModal(false);
          }}
          open={!!openCompleteModal}
          taskId={modalData}
        />
      </Panel.Body>
    </Panel>
  );
};

export default CCMTaskDashboard;
