import { Selectable } from '../enums/Enum';
import { makeArray } from './Model';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class ProgramExtension {
  id = 0;

  name = '';

  abbr = '';

  program = false;

  statuses: ProgramExtensionStatus[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.statuses = makeArray(obj.statuses, ProgramExtensionStatus);
  }

  static CCM = 2;

  static RPM = 3;

  static VESTA_CORE = 5;

  static VESTA_LITE = 9;

  static TCM = 42;

  static MEDICAID = 43;

  static BHI = 110;

  static toSelectable(o: ProgramExtension[]): Selectable[] {
    return o.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
}

export default ProgramExtension;
