import { MemberDashboardAppointment } from './MemberDashboardAppointment';
import { MemberDashboardAppointmentAcute } from './MemberDashboardAppointmentAcute';
import { make, makeArray } from './Model';

export class MemberDashboardAppointmentDetails {
  lastAcuteAppointment?: MemberDashboardAppointmentAcute;

  nextAppointments: MemberDashboardAppointment[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.lastAcuteAppointment = make(
      obj.lastAcuteAppointment,
      MemberDashboardAppointmentAcute,
    );
    this.nextAppointments = makeArray(
      obj.nextAppointments,
      MemberDashboardAppointment,
    );
  }
}

export default MemberDashboardAppointmentDetails;
