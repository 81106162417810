import React, { useEffect, useState } from 'react';

import { MessageUserType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';

import { Button, Modal, PanelInfo, TextArea } from 'styleguide-v2';

import { sendChat, sendSMSBatch } from 'dash/src/services/MessageServices';
import { searchPatients } from 'dash/src/services/PatientServices';

interface Props {
  ids: number[];
  isChatMessage: boolean;
  recipientType: MessageUserType;
  onHide: () => void;
}

export const SMSModal = (props: Props) => {
  const { ids, isChatMessage, recipientType, onHide } = props;

  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [optedOutIds, setOptedOutIds] = useState<number[]>();

  const sendMessage = async () => {
    if (message.length < 1) {
      setShowError(true);
      return;
    }
    setShowError(false);
    if (isChatMessage) {
      await sendChat(ids, recipientType, message);
    } else {
      await sendSMSBatch(ids, recipientType, message);
    }
    onHide();
  };

  const insertFirstName = () => {
    setMessage(`${message} <FIRST_NAME> `);
  };

  const insertLastName = () => {
    setMessage(`${message} <LAST_NAME> `);
  };

  useEffect(() => {
    if (isChatMessage) {
      setOptedOutIds([]);
      return;
    }

    setOptedOutIds(undefined);

    if (recipientType === MessageUserType.MEMBER) {
      searchPatients({ id: ids, limit: -1 }).then(({ items }) => {
        const optedOut = items
          .filter((p) => p.primaryPhone?.phone.textReminderOptOut)
          .map((p) => p.id);

        setOptedOutIds(optedOut);
      });
    }
  }, [ids, recipientType, isChatMessage]);

  return (
    <Modal
      onClose={onHide}
      open
      title={translate(
        isChatMessage ? 'messaging.sendBulkChat' : 'messaging.sendBulkSms',
      )}
      onSubmit={sendMessage}
      submitText="Send"
      submitDisabled={!optedOutIds}
    >
      <p>
        {translate('messaging.sendToContacts', { numContacts: ids.length })}
      </p>
      <div className="pad-bottom-20">
        <Button
          color="secondary"
          type="outlined"
          size="s"
          onClick={insertFirstName}
        >
          First Name
        </Button>
        <span className="pad-left-20">
          <Button
            color="secondary"
            type="outlined"
            size="s"
            onClick={insertLastName}
          >
            Last Name
          </Button>
        </span>
      </div>
      <TextArea
        error={showError}
        maxLength={280}
        rows={5}
        value={message}
        onChange={(message) => setMessage(message || '')}
        required={showError}
        className="pad-bottom-20"
      />
      {!!optedOutIds?.length && (
        <PanelInfo type="warning">
          {translate('messaging.optedOutRecipientsWarning')}:{' '}
          {optedOutIds.join(', ')}
        </PanelInfo>
      )}
    </Modal>
  );
};

export default SMSModal;
