// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ht-pagination {
  padding: 20px 0;
  justify-content: flex-end;
}
.ht-pagination .ht-pagination-nav {
  display: flex;
  align-content: center;
  flex: 0 0 100px;
  justify-content: space-between;
  margin-right: 40px;
}
.ht-pagination .ht-pagination-nav button {
  font-size: 12px;
}
.ht-pagination .ht-pagination-nav button[disabled] {
  opacity: 0.5;
}
.ht-pagination .ht-pagination-size {
  flex: 0 0 190px;
  justify-content: space-between;
}
.ht-pagination .ht-pagination-size .ht-dropdown {
  margin-bottom: 0;
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/Pagination/main.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,yBAAA;AACF;AAHA;EAKI,aAAA;EACA,qBAAA;EACA,eAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AAVA;EAYM,eAAA;AACN;AACM;EACE,YAAA;AACR;AAhBA;EAqBI,eAAA;EACA,8BAAA;AAFJ;AApBA;EAyBM,gBAAA;EACA,YAAA;AAFN","sourcesContent":[".ht-pagination {\n  padding: 20px 0;\n  justify-content: flex-end;\n\n  .ht-pagination-nav {\n    display: flex;\n    align-content: center;\n    flex: 0 0 100px;\n    justify-content: space-between;\n    margin-right: 40px;\n\n    button {\n      font-size: 12px;\n\n      &[disabled] {\n        opacity: 0.5;\n      }\n    }\n  }\n\n  .ht-pagination-size {\n    flex: 0 0 190px;\n    justify-content: space-between;\n\n    .ht-dropdown {\n      margin-bottom: 0;\n      width: 120px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
