// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ratio-input {
  display: flex;
  max-width: 160px;
  justify-content: center;
  align-items: baseline;
}
.ratio-input.wide {
  max-width: 220px;
}
.ratio-input-divider {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
}
.left-margin {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/MinMaxInput/main.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAGA;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;AADF;AAIA;EACE,iBAAA;AAFF","sourcesContent":[".ratio-input {\n  display: flex;\n  max-width: 160px;\n  justify-content: center;\n  align-items: baseline;\n\n  &.wide {\n    max-width: 220px;\n  }\n}\n\n.ratio-input-divider {\n  margin-left: 10px;\n  margin-right: 10px;\n  font-size: 20px;\n}\n\n.left-margin {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
