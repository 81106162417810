// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#personal-details-section {
  color: #444444;
}
#personal-details-section td:not(:first-child) {
  vertical-align: middle;
}
#personal-details-section article .grid-wrapper {
  grid-gap: 30px 20px;
}
#personal-details-section article .grid-wrapper:not(:last-child) {
  border-right: 2px solid #f4f7f6;
  padding-right: 7px;
}
#personal-details-section article .grid-wrapper h4,
#personal-details-section article .grid-wrapper p,
#personal-details-section article .grid-wrapper a {
  margin: 0;
}
#personal-details-section article .grid-wrapper h4 {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
#personal-details-section article .personal-info-column {
  align-self: flex-start;
}
#personal-details-section article .program-info-column {
  grid-template-rows: repeat(3, minmax(3ch, max-content));
}
#personal-details-section .contacts ul {
  list-style-type: inherit;
}
#personal-details-section .contacts ul li {
  margin: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MemberProfile/MemberDetails/main.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAAA;EAII,sBAAA;AADJ;AAHA;EASM,mBAAA;AAHN;AAKM;EACE,+BAAA;EACA,kBAAA;AAHR;AAVA;;;EAmBQ,SAAA;AAJR;AAfA;EAuBQ,yBAAA;EACA,uBAAA;EACA,2BAAA;AALR;AApBA;EA8BM,sBAAA;AAPN;AAvBA;EAkCM,uDAAA;AARN;AA1BA;EAwCM,wBAAA;AAXN;AA7BA;EA2CQ,cAAA;AAXR","sourcesContent":["@import (reference) '~styleguide/src/styles/variables.less';\n\n#personal-details-section {\n  color: @textBlack;\n\n  td:not(:first-child) {\n    vertical-align: middle;\n  }\n\n  article {\n    .grid-wrapper {\n      grid-gap: 30px 20px;\n\n      &:not(:last-child) {\n        border-right: 2px solid @bkgdBlue;\n        padding-right: 7px;\n      }\n\n      h4,\n      p,\n      a {\n        margin: 0;\n      }\n\n      h4 {\n        align-content: flex-start;\n        align-items: flex-start;\n        justify-content: flex-start;\n      }\n    }\n\n    .personal-info-column {\n      align-self: flex-start;\n    }\n\n    .program-info-column {\n      grid-template-rows: repeat(3, minmax(3ch, max-content));\n    }\n  }\n\n  .contacts {\n    ul {\n      list-style-type: inherit;\n\n      li {\n        margin: 0 20px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
