import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Sidebar, SidebarLink } from 'styleguide/src/components/Sidebar';

import { kebabCase } from 'lodash';

const PAGES = [
  'Buttons',
  'Colors',
  'Inputs',
  'Tables',
  'Panels',
  'Charts',
  'Chat',
  'Drawer',
  'Misc',
];

const isActive = (key: string, pathname: string) => {
  const parts = pathname.split('/');
  return parts[parts.length - 1] === key;
};

const StyleGuideSidebar = (props: RouteComponentProps) => (
  <Sidebar>
    <SidebarLink
      href="/dev/dashboard"
      dataCy="sidebar-nav-styleguide-dev-dashboard"
      iconLeft="chevron-left"
      text="Back to Dev Dashboard"
    />
    {PAGES.map((pageName) => {
      const kebabName = kebabCase(pageName);
      return (
        <SidebarLink
          key={pageName}
          active={isActive(kebabName, props.location.pathname)}
          dataCy={`sidebar-nav-styleguide-${kebabName}`}
          href={`/dev/styleguide-v2/${kebabName}`}
          text={pageName}
        />
      );
    })}
  </Sidebar>
);

export default withRouter(StyleGuideSidebar);
