import { ProgramExtensionStatusReason } from '../enums';
import { Employee } from './Employee';
import { make } from './Model';
import { ProgramExtension } from './ProgramExtension';
import { ProgramExtensionStatus } from './ProgramExtensionStatus';

export class MemberProgramExtension {
  id = 0;

  programExtension: ProgramExtension;

  status?: ProgramExtensionStatus;

  statusReason?: ProgramExtensionStatusReason;

  statusReasonOther = '';

  createdAt = 0;

  createdBy: Employee;

  updatedAt = 0;

  updatedBy: Employee;

  releaseDate = '';

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdBy = make(obj.createdBy, Employee);
    this.programExtension = make(obj.programExtension, ProgramExtension);
    this.status = make(obj.status, ProgramExtensionStatus);
    this.statusReason = obj.statusReason
      ? ProgramExtensionStatusReason.byKey[obj.statusReason]
      : undefined;
    this.updatedBy = make(obj.updatedBy, Employee);
  }
}

export default MemberProgramExtension;
