// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-list .full-width {
  margin-top: 20px;
}
.phone-list .icon.delete-icon {
  height: 45px;
}
.phone-list .icon-wrapper {
  grid-column-start: auto;
}
`, "",{"version":3,"sources":["webpack://./../styleguide/src/components/PhoneList/main.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAMI,YAAA;AADJ;AALA;EAUI,uBAAA;AAFJ","sourcesContent":[".phone-list {\n  .full-width {\n    margin-top: 20px;\n  }\n\n  .icon.delete-icon {\n    height: 45px;\n  }\n\n  .icon-wrapper {\n    grid-column-start: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
