import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { AutocompleteRenderGroupParams, Box, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';

import { Fonts } from '../../styles/Variables';
import { ColorOption, TypeOption } from '../../utils/colors';
import { CheckboxWithLabel } from '../CheckboxWithLabel';
import { SomeCheckedIcon } from '../CheckboxWithLabel/CustomCheckbox';
import { Chip } from '../Chip';
import { CollapseIcon } from '../CollapseIcon';
import { IconButton } from '../IconButton';
import { SelectComponent } from '../Select/Select';

export type SelectProgramExtensionData = {
  programExtension: ProgramExtension;
  programExtensionStatus: ProgramExtensionStatus[];
};

type InternalData = {
  programExtension: ProgramExtension;
  programExtensionStatus: ProgramExtensionStatus;
};

export type Props = {
  'data-cy'?: string;
  className?: string;
  color?: ColorOption;
  disabled?: boolean;
  error?: string | boolean;
  items: ProgramExtension[];
  label?: String;
  limitTags?: number;
  loading?: boolean;
  onChange: (items: SelectProgramExtensionData[]) => void;
  placeholder?: string;
  placeholderV2?: boolean;
  required?: boolean;
  size?: 'xs' | 'small' | 'medium';
  type?: TypeOption;
  value?: SelectProgramExtensionData[];
};

const CollapseGroup = ({
  params,
  onCollapse,
  onSelect,
  open,
  selected,
  someChecked,
}: {
  params: AutocompleteRenderGroupParams;
  onCollapse: () => void;
  onSelect: () => void;
  open: boolean;
  selected: boolean;
  someChecked: boolean;
}) => (
  <Box key={`pe-collapse-${params.key}`}>
    <div
      className="flex spaced clickable"
      role="presentation"
      onClick={(evt) => {
        evt.preventDefault();
        onCollapse();
      }}
    >
      <CheckboxWithLabel
        checked={selected}
        icon={someChecked ? <SomeCheckedIcon /> : undefined}
        label={params.group}
        onChange={() => onSelect()}
      />
      <IconButton
        onClick={(evt) => {
          evt.preventDefault();
          onCollapse();
        }}
        size="small"
      >
        <CollapseIcon open={open} />
      </IconButton>
    </div>
    <Collapse in={open}>{params.children}</Collapse>
  </Box>
);

const useStyles = makeStyles({
  chipMargin: {
    margin: '0.25rem',
  },
  internalLabelCommon: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: '20px',
    marginTop: 1,
    maxWidth: 'calc(100% - 30px)',

    '& .placeholder-chip-counter.disabled': {
      opacity: 0.6,
    },
  },
  internalSublabel: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
  internalLabel: {
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
  },
  internalLabelSmall: {
    fontSize: Fonts.fontSize,
  },
  internalLabelExtraSmall: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
  },
});

export const SelectProgramExtension = ({
  disabled,
  items,
  onChange,
  placeholder,
  size = 'medium',
  value,
  ...rest
}: Props) => {
  const styles = useStyles();

  const [collapsed, setCollapsed] = useState<{ [x: string]: boolean }>({});
  const [search, setSearch] = useState<string>('');

  const [convertedValue, setConvertedValue] = useState<InternalData[]>([]);
  const convertedItems = useMemo(
    () =>
      items.reduce(
        (acc, item) => [
          ...acc,
          ...item.statuses.map((programExtensionStatus) => ({
            programExtension: item,
            programExtensionStatus,
          })),
        ],
        [] as InternalData[],
      ),
    [items],
  );

  const transformToData = (
    values: InternalData[],
  ): SelectProgramExtensionData[] =>
    values.reduce((acc, item) => {
      const data = acc.find(
        ({ programExtension }) =>
          programExtension.id === item.programExtension.id,
      );
      if (data) {
        data.programExtensionStatus = [
          ...data.programExtensionStatus,
          item.programExtensionStatus,
        ];
        return acc;
      }
      return [
        ...acc,
        {
          programExtension: item.programExtension,
          programExtensionStatus: [item.programExtensionStatus],
        },
      ];
    }, [] as SelectProgramExtensionData[]);

  const filterItemBySearch = (query: string) => ({
    programExtension,
    programExtensionStatus,
  }: InternalData) =>
    !query ||
    programExtension.abbr
      .toLocaleLowerCase()
      .includes(query?.toLocaleLowerCase()) ||
    programExtension.name
      .toLocaleLowerCase()
      .includes(query?.toLocaleLowerCase()) ||
    programExtensionStatus.description
      .toLocaleLowerCase()
      .includes(query?.toLocaleLowerCase());

  const handleChange = (data: InternalData[]) => {
    setConvertedValue(data);
    onChange(transformToData(data));
  };

  useEffect(
    () =>
      setConvertedValue(
        (value || []).reduce(
          (acc, { programExtension, programExtensionStatus }) => [
            ...acc,
            ...programExtensionStatus.map(
              (peStatus) =>
                ({
                  programExtension,
                  programExtensionStatus: peStatus,
                } as InternalData),
            ),
          ],
          [] as InternalData[],
        ),
      ),
    [value],
  );

  return (
    <SelectComponent
      disabled={disabled}
      filterOptions={(data: InternalData[], options) =>
        data.filter(filterItemBySearch(options.inputValue))
      }
      grouped={(item: InternalData) => item.programExtension.abbr}
      isOptionEqualToValue={(a: InternalData, b: InternalData) =>
        a.programExtension.id === b.programExtension.id &&
        a.programExtensionStatus.id === b.programExtensionStatus.id
      }
      items={convertedItems}
      multiple
      onChange={handleChange}
      onInputChange={(inputValue, reason) => {
        if (reason !== 'reset') {
          setSearch(inputValue);
        }
        if (inputValue?.length) {
          setCollapsed(
            items.reduce((acc, pe) => {
              acc[pe.abbr] = true;
              return acc;
            }, {} as { [x: string]: boolean }),
          );
        }
        if (reason === 'blur') {
          setCollapsed({});
        }
      }}
      placeholder={placeholder}
      renderGroup={(paramsGroup: AutocompleteRenderGroupParams) => {
        const selectedItems = convertedValue?.filter(
          ({ programExtension }) => programExtension.abbr === paramsGroup.group,
        );
        const isSelected = !!selectedItems?.length;
        let someChecked = false;
        if (selectedItems?.length) {
          const pe = items.find(
            ({ id }) => id === selectedItems[0].programExtension.id,
          );
          if (pe) {
            someChecked = pe.statuses.length > selectedItems.length;
          }
        }
        return (
          <CollapseGroup
            key={`pe-collapse-group-${paramsGroup.group}-${search}`}
            params={paramsGroup}
            open={!!collapsed[paramsGroup.group]}
            onCollapse={() =>
              setCollapsed({
                ...collapsed,
                [paramsGroup.group]: !collapsed[paramsGroup.group],
              })
            }
            onSelect={() => {
              if (isSelected) {
                handleChange(
                  convertedValue?.filter(
                    (item) =>
                      item.programExtension.abbr !== paramsGroup.group ||
                      !filterItemBySearch(search)(item),
                  ),
                );
              } else {
                handleChange([
                  ...convertedValue,
                  ...convertedItems?.filter(
                    (item) =>
                      item.programExtension.abbr === paramsGroup.group &&
                      filterItemBySearch(search)(item),
                  ),
                ]);
              }
            }}
            selected={isSelected}
            someChecked={someChecked}
          />
        );
      }}
      renderOption={(option: InternalData) => (
        <CheckboxWithLabel
          checked={
            convertedValue?.findIndex(
              (item) =>
                item.programExtension.id === option.programExtension.id &&
                item.programExtensionStatus.id ===
                  option.programExtensionStatus.id,
            ) !== -1
          }
          key={`pe-collapse-item-${option.programExtension.abbr}-${option.programExtensionStatus.id}`}
          label={option.programExtensionStatus.description}
          onChange={(checked) => {
            if (checked) {
              handleChange([...convertedValue, option]);
            } else {
              handleChange(
                convertedValue?.filter(
                  (item) =>
                    item.programExtension.id !== option.programExtension.id ||
                    item.programExtensionStatus.id !==
                      option.programExtensionStatus.id,
                ),
              );
            }
          }}
        />
      )}
      renderTags={(tagValue) => {
        if (!tagValue?.length) {
          return <></>;
        }

        const pes = transformToData((tagValue || []) as InternalData[]);

        if (placeholder) {
          return (
            <span
              className={classNames(
                styles.internalLabelCommon,
                size === 'medium' && styles.internalLabel,
                size === 'small' && styles.internalLabelSmall,
                size === 'xs' && styles.internalLabelExtraSmall,
                'semi-bold',
              )}
            >
              <span className={styles.internalSublabel}>{placeholder}</span>
              &nbsp;
              {!disabled && (
                <Chip
                  className={classNames(
                    'semi-bold placeholder-chip-counter',
                    disabled && 'disabled',
                  )}
                  color="secondary"
                  type="contained-2"
                  size={size}
                >
                  {pes.length}
                </Chip>
              )}
            </span>
          );
        }
        return pes.map((item) => (
          <Chip
            className={classNames(
              styles.chipMargin,
              'semi-bold placeholder-chip-counter',
              disabled && 'disabled',
            )}
            color="secondary"
            key={`pe-tag-${item.programExtension.abbr}`}
            type="contained-2"
            size={size}
          >
            {item.programExtension.abbr}
            {item.programExtensionStatus?.length > 1
              ? `: ${item.programExtensionStatus?.length} ${translate(
                  'global.statusesCount',
                  {
                    count: item.programExtensionStatus?.length,
                  },
                )}`
              : ` [${item.programExtensionStatus
                  ?.map(({ description }) => description)
                  .join(', ')}]`}
          </Chip>
        ));
      }}
      size={size}
      value={convertedValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};
