import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from 'styleguide-v2/src/components/ThemeProvider';
import Buttons from 'styleguide-v2/src/pages/Styleguide/Buttons';
import Charts from 'styleguide-v2/src/pages/Styleguide/Charts';
import Chat from 'styleguide-v2/src/pages/Styleguide/Chat';
import Colors from 'styleguide-v2/src/pages/Styleguide/Colors';
import Drawer from 'styleguide-v2/src/pages/Styleguide/Drawer';
import Inputs from 'styleguide-v2/src/pages/Styleguide/Inputs';
import Misc from 'styleguide-v2/src/pages/Styleguide/Misc';
import Tables from 'styleguide-v2/src/pages/Styleguide/Tables';

import Panels from './Panel';
import StyleguideSidebar from './StyleguideSidebar';

const StyleguideV2 = () => (
  <ThemeProvider>
    <div id="page-wrapper">
      <StyleguideSidebar />
      <Switch>
        <Route path="/dev/styleguide-v2/buttons" component={Buttons} />
        <Route path="/dev/styleguide-v2/colors" component={Colors} />
        <Route path="/dev/styleguide-v2/tables" component={Tables} />
        <Route path="/dev/styleguide-v2/panels" component={Panels} />
        <Route path="/dev/styleguide-v2/inputs" component={Inputs} />
        <Route path="/dev/styleguide-v2/chat" component={Chat} />
        <Route path="/dev/styleguide-v2/charts" component={Charts} />
        <Route path="/dev/styleguide-v2/drawer" component={Drawer} />
        <Route path="/dev/styleguide-v2/misc" component={Misc} />
        <Redirect from="/dev/styleguide-v2**" to="/dev/styleguide-v2/buttons" />
      </Switch>
    </div>
  </ThemeProvider>
);

export default StyleguideV2;
