import { Language, States } from '../enums';
import {
  DaysOfServiceHelper,
  getDaysOfService,
} from './Helpers/DaysOfServiceHelper';
import { HouseHoldMember } from './HouseHoldMember';
import { MemberContactInfoTimes } from './MemberContactInfoTimes';
import { makeArray } from './Model';
import { PatientBase } from './PatientBase';

export class CCMTaskMember extends PatientBase implements DaysOfServiceHelper {
  householdMembers?: HouseHoldMember[];

  language?: Language;

  memberContactTimes?: MemberContactInfoTimes[];

  state: States;

  unreachable = false;

  constructor(obj: any) {
    super(obj);
    this.language = obj.language ? Language.byKey[obj.language] : undefined;
    this.householdMembers = makeArray(obj.householdMembers, HouseHoldMember);
    if (obj.firstName && obj.lastName && !this.fullName) {
      this.fullName = `${obj.firstName} ${obj.lastName}`;
    }
    this.memberContactTimes = makeArray(
      obj.memberContactTimes,
      MemberContactInfoTimes,
    );
    this.state = States.byKey[obj.state];
    this.unreachable = obj.unreachable;
  }

  getDaysOfService() {
    return getDaysOfService(this.memberContactTimes);
  }
}

export default CCMTaskMember;
